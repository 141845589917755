import { _IOType, IOType, RangedKanaal } from "../../props/PropTypes";
import { FastLinkBusManager } from "./FastLinkBusManager";
import { BusType, HdwRackType, isCombiKaart, isSimpleKaart, KaartType, MainboardType } from "./hdw-konfig-types";
import { SCX, SCXAIKart, SCXRack } from "./hdw-meta";
import { SCXBusManager } from "./SCXBusManager";


export interface IOProvider {
    getIOs(): RangedKanaal[]
}

export interface BusManager {
    assignOffsets(): void,
    addRack(rackType: HdwRackType): number,
    addKaart(kaart: KaartType, number: number, rackId: number): number,
    deleteKaart(id: number): void
}

function getBusManager(busType: BusType, system: HwdSysteem) {
    switch (busType) {
        case "fastlink":
            return new FastLinkBusManager(system);
        case "scx":
            return new SCXBusManager(system);
    }
}

export type HwdSerializable = {
    mainboard: MainboardType,
    buses: Partial<Record<BusType, Rack[]>>,
    maxRackId: number,
    maxKaartId: number
}

export class HwdSysteem implements IOProvider {
    constructor(mainboard?: MainboardType, serializable?: HwdSerializable) {
        if (mainboard != undefined) {
            this.mainboard = mainboard;
            for (let b of this.mainboard.busTypes) {
                this.buses[b] = [];
                this.busManagers[b] = getBusManager(b, this);
            }
            for (let r of this.mainboard.racks) {
                this.addRack(r);
            }

            for (let k of this.mainboard.karten) {
                let rack = this.getRacks(k.bus)[0];
                this.addKaart(k, this.getNextKaartId(), rack.id);
            }
        } else if (serializable != undefined) {
            this.fromSerializable(serializable);
        }

    }

    private fromSerializable(serializable: HwdSerializable) {
        this.mainboard = serializable.mainboard;
        this.maxKaartId = serializable.maxKaartId;
        this.maxRackId = serializable.maxRackId;
        console.log(serializable);
        for (let b of this.mainboard.busTypes) {
            let newRacks: Rack[] = [];
            let _racks = serializable.buses[b];
            if (_racks != undefined) {
                for (let r of _racks) {
                    newRacks.push(new Rack(r.type, r.id, r));
                }
            }
            this.buses[b] = newRacks;
            this.busManagers[b] = getBusManager(b, this);
        }
        console.log(this);
    }
    getIOs(): RangedKanaal[] {
        let ret: RangedKanaal[] = [];
        for (let k of this.mainboard.karten) {
            if (isSimpleKaart(k)) {
                ret.push({
                    ioType: new IOType(k.ioType),
                    start: 0,
                    range: k.available
                });
            }
        }
        return ret;
    }

    private maxRackId = 0;
    private maxKaartId = 0;

    public getNextRackId() {
        this.maxRackId++;
        return this.maxRackId;
    }

    public getNextKaartId() {
        this.maxKaartId++;
        return this.maxKaartId;
    }
    public mainboard: MainboardType = SCX;
    private buses: Partial<Record<BusType, Rack[]>> = {};
    private busManagers: Partial<Record<BusType, BusManager>> = {};

    public getBusTypes(): BusType[] {
        return this.mainboard.busTypes;
    }


    public addRack(rackType: HdwRackType) {
        let busType = rackType.bus;
        let manager = this.busManagers[busType];
        if (manager != undefined) {
            return manager.addRack(rackType);
        }
        return -1;
    }


    public getRacks(bus: BusType): Rack[] {
        return this.buses[bus] || [];
    }

    public getRack(bus: BusType, rackId: number) {
        let racks = this.getRacks(bus);
        let ret = racks.find(r => r.id == rackId);
        return ret;
    }

    public addKaart(kaart: KaartType, number: number, rackId: number) {
        let busType = kaart.bus;
        let manager = this.busManagers[busType];
        if (manager != undefined) {
            let id = manager.addKaart(kaart, number, rackId);
            return id;
        }
        return -1;
    }

    public deleteKaart(id: number, busType: BusType) {
        let manager = this.busManagers[busType];
        if (manager != undefined) {
            manager.deleteKaart(id);
        }
    }

    public getKaart(id: number): Kaart | undefined {
        for (let b of this.mainboard.busTypes) {
            for (let r of this.getRacks(b)) {
                for (let k of r.kaarten) {
                    if (k.id == id) {
                        return k;
                    }
                }
            }
        }
    }

    public toSerializable() {
        return {
            mainboard: this.mainboard,
            buses: this.buses,
            maxKaartId: this.maxKaartId,
            maxRackId: this.maxRackId
        };
    }
}

export class Rack {
    constructor(rack: HdwRackType, id: number, clone?: Rack) {
        if (clone != undefined) {
            this.fromClone(clone);
        } else {
            this.type = rack;
            this.id = id;
        }
    }

    private fromClone(_rack: Rack) {
        this.type = _rack.type;
        this.id = _rack.id;
        for (let k of _rack.kaarten) {
            this.kaarten.push(new Kaart(k.type, k.number, k.id, k.dipSwitch, k));
        }
    }

    public type: HdwRackType = SCXRack;
    public id: number = 0;
    public kaarten: Kaart[] = [];

    public getNextNumber() {
        let number = 0;
        if (this.isFull()) {
            return -1;
        }
        for (let k of this.kaarten) {
            if ((k.type.takesRackSpace) && (k.number > number)) {
                number = k.number;
            }
        }
        return number + 1;

    }

    public addKaart(kaart: Kaart) {
        this.kaarten.push(kaart);
    }

    public isFull(): boolean {
        return this.kaarten.filter(k => k.type.takesRackSpace).length >= this.type.slots;
    }
}

export class Kaart implements IOProvider {
    constructor(kaart: KaartType, number: number, id: number, dipSwitch?: number, clone?: Kaart) {
        if (clone != undefined) {
            this.fromClone(clone);
        } else {
            this.type = kaart;
            this.number = number;
            this.id = id;
            if (dipSwitch != undefined) {
                this.dipSwitch = dipSwitch;
            } else {
                this.dipSwitch = number;
            }
        }
    }


    fromClone(_kaart: Kaart) {
        this.type = _kaart.type;
        this.number = _kaart.number;
        this.id = _kaart.id;
        this.dipSwitch = _kaart.dipSwitch;
        this.offset = _kaart.offset;
    }

    getIOs(): RangedKanaal[] {
        if (isSimpleKaart(this.type)) {
            let offset = this.getOffset(new IOType(this.type.ioType));
            if (offset != undefined) {
                return [{
                    ioType: new IOType(this.type.ioType),
                    start: this.getOffset(new IOType(this.type.ioType)),
                    range: this.type.available
                }];
            }
        } else if (isCombiKaart(this.type)) {
            let ret: RangedKanaal[] = [];
            for (let k of this.type.karten) {
                let offset = this.getOffset(new IOType(k.ioType));
                if (offset != undefined) {
                    ret.push({
                        ioType: new IOType(k.ioType),
                        start: offset,
                        range: k.available
                    });
                }
            }
            return ret;
        }
        return [];
    }

    public id: number = 0;
    public type: KaartType = SCXAIKart;
    public number: number = 0;
    public dipSwitch: number = 0;

    private offset: Record<string, number> = {};

    public getReservedIOS(type: IOType): number {
        if (isSimpleKaart(this.type)) {
            if (new IOType(this.type.ioType).equals(type)) {
                return this.type.reserved;
            } else {
                return 0;
            }
        } else if (isCombiKaart(this.type)) {
            for (let k of this.type.karten) {
                if (new IOType(k.ioType).equals(type)) {
                    return k.reserved;
                }
            }
        }
        return 0;
    }

    public getOffset(type: IOType) {
        return this.offset[type.toString()];
    }


    public setOffset(offset: number, type: IOType) {
        this.offset[type.toString()] = offset;
    }

}
