import { Component, computed, input } from '@angular/core';
import { wasmVectorToArray } from '../../utils/wasmVector';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { KopModulesService } from '../../modules/kop-modules.service';

@Component({
  selector: 'alarm-texts',
  templateUrl: './alarm-texts.component.html',
  styles: ``
})
export class AlarmTextsComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService,
    public kopModulesService: KopModulesService) { }

  public alarmTexts = computed(() => {
    console.log("alarmTexts");
    let tmATs = this.kopConfigService.selectedAfd()?.afdeling().alarmTexts;
    let ret: Record<number, string> = [];
    if (tmATs != undefined) {
      let keys = wasmVectorToArray(tmATs.keys());
      keys.forEach(key => {
        let text = tmATs.get(key);
        if (text != undefined) {
          ret[key] = text?.toString();
        }
      });
    }
    return ret;
  });

  public isEditing = false;
  public editVal: Record<number, string> = {};
  public range: Array<number> = [0, 1, 2, 3];

  public presentation = input<"classic" | "new">("new");

  public edit() {
    for (let i of this.range) {
      this.editVal[i] = this.alarmTexts()[i] ?? "";
    }
    this.isEditing = true;
  }

  public onSave() {
    this.isEditing = false;
    this.kopConfigService.selectedAfd.update(afd => {
      let wAfd = afd?.afdeling();
      if (wAfd != undefined) {
        wAfd.alarmTexts.clear();
        for (let [key, val] of Object.entries(this.editVal)) {
          if (val != "") {
            wAfd.alarmTexts.set(parseInt(key), val);
          }
        }
      }
      return afd;
    });
  }

}
