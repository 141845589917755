import { Component, computed, effect, Inject, input, signal } from '@angular/core';

import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs';
import { ServerAPI } from '../../serverapi';

@Component({
  selector: 'asset-icon',
  template: '<img [style]="{width: \'100%\', height: \'100%\'}" src="{{data()}}">',
  styles: ``
})
export class AssetIconComponent {

  constructor(@Inject('ServerApi') public electronApi: ServerAPI) {
  }

  public imgPath = input<string | undefined>("icons/module.png");
  public defaultPath = input<string>("icons/slot.png");

  public data = toSignal(toObservable(this.imgPath).pipe(
    switchMap((img) => {

      return this.getData(img!);
    })
  ));

  async bufferToBase64(buffer: any) {
    // use a FileReader to generate a base64 data URI:
    const base64url: any = await new Promise(r => {
      const reader = new FileReader()
      reader.onload = () => r(reader.result)
      reader.readAsDataURL(new Blob([buffer]))
    });
    // remove the `data:...;base64,` part from the start
    return base64url.slice(base64url.indexOf(',') + 1);
  }

  private async getData(img: string) {
    if (img == undefined) {
      img = this.defaultPath();
    }
    let data = await this.electronApi.getAsset(img);
    if (data != undefined) {
      let b64encoded = await this.bufferToBase64(data.buffer);
      let ext = img.substring(img.lastIndexOf('.') + 1);
      return `data:image/${ext};base64,` +b64encoded
    }
    return "";
  }
}
