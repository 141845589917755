<button kendoButton size="large" class="button" (click)="this.isEditing = true">Analoog</button>
<kendo-dialog *ngIf="isEditing" title="Analoog" width="550px" (close)="this.isEditing = false">
    <div class="wrapper">
        @for (ai of propsService.ais(); track ai.index) {
            <aieditor [initialValue]="ai" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
        }
        <div class="splitter"></div>
        @for (ai of this.availableAIs(); track ai.index) {
            <aieditor [initialValue]="ai" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over"  [onlyDesc]="true"/>
        }
    </div>

</kendo-dialog>