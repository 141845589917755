<ul class="v-wrapper">
@for (ld of this.validation()?.requiredProps?.losdef; track ld) {
    @if (this.validationService.isOf(ld)) {
        <li>Missing one of properties</li>
        <ul>
        @for (idx of ld; track idx) {
            <li>   {{this.validationService.metaLD(idx)?.omschr}}</li>            
        }
        </ul>
    } @else {
        <li>Missing Prop:{{this.validationService.metaLD(ld)?.omschr}}</li>
    }
}
@for (md of this.validation()?.requiredModules?.modules; track md) {
    @if (this.validationService.isOfM(md)) {
        <li>Missing one of Modules</li>
        <ul>
        @for (idx of md; track idx) {
            <li>   {{this.metaModule(idx)?.name}}</li>            
        }
        </ul>
    } @else {
        <li>Missing Module:{{this.metaModule(md)?.name}}</li>
    }
}
</ul>
