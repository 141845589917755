import { Component, computed } from '@angular/core';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { wasmMapToRecord, wasmVectorToArray } from '../../utils/wasmVector';
import { KopModulesService } from '../../modules/kop-modules.service';
import { cKC_Analog, cKC_BijzPrg, cKC_LosDef, cKC_LosDefs, cKC_Servo, cKC_Uren, Losdef } from '@wasm/KopWeb';
import { DigitalIn, LosDefType, ModuleAI, ModuleUren } from '../PropTypes';
import { KanaalService } from '../../kanaal/kanaal.service';
import { PanelType, PropsServiceService } from '../props-service.service';



@Component({
  selector: 'afd-base-props',
  templateUrl: './afd-base-props.component.html',
  styleUrl: './afd-base-props.component.scss'
})
export class AfdBasePropsComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService,
    public kopModulesService: KopModulesService, public kanaalService: KanaalService, public propsService: PropsServiceService) { }

  openPanel?: PanelType = "AI";






  test(e: any) {
    console.log(e);
    this.kopConfigService.selectedAfd.update(afd => {
      let tmAlarmsW = afd?.afdeling().alarms;
      if (tmAlarmsW != undefined) {
        let alarms = wasmVectorToArray(tmAlarmsW);
        let idx = alarms.findIndex(al => al.index == e.index);
        let tmAlarm = tmAlarmsW.at(idx);
        if (tmAlarm != undefined) {
          tmAlarm.kanaal = e.kanaal + 1;
        }
      }
      return afd;
    });
  }



  onPanelClick(panel: PanelType) {
    if (this.openPanel == panel) {
      this.openPanel = undefined;
    } else {
      this.openPanel = panel;
    }
  }


  onTitleChange($event: string) {
    this.kopConfigService.selectedAfd.update(v => {
      let afd = v?.afdeling();
      if (afd != undefined) {
        afd.titel = $event;
      }
      return v;
    });
  }

}
