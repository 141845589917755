import { afterRender, Component, effect, ElementRef, input, output, Renderer2, signal, ViewChild } from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';

type MetaBase = {
  index: number;
}

@Component({
  selector: 'meta-select-button',
  templateUrl: './meta-select-button.component.html',
  styleUrl: './meta-select-button.component.scss'
})
export class MetaSelectButtonComponent {

  constructor(public renderer: Renderer2) {
  }
  public isSelectMeta = false;
  public availableMetas = input<MetaBase[]>([]);
  public alreadySelected = input<(idx: number) => boolean>((idx: number) => false);
  public labelCb = input((meta: MetaBase) => meta.index.toString());

  public onSelect = output<MetaBase>();

 
  public hoveredMeta = signal<MetaBase | undefined>(undefined);
  // onMetaHover(ldm: MetaBase) {
  //   let isAlreadySelected = this.alreadySelected()(ldm.index);
  //   if (isAlreadySelected) {
  //     return;
  //   }
  //   this.hoveredMeta.set(ldm);
  // }

  onMetaSelected(ldm: MetaBase) {
    let isAlreadySelected = this.alreadySelected()(ldm.index);
    if (!isAlreadySelected) {
      this.toggleMetaEdit();
      this.onSelect.emit(ldm);  
    }
  }

  public onNew() {
    this.toggleMetaEdit();
  }


  @ViewChild("newAnchor", { read: ElementRef }) public metaAnchor?: ElementRef = undefined;
  @ViewChild("metaPopup", { read: ElementRef }) public metaPopup?: ElementRef = undefined;
  private unlistener?: () => void; 

  public popUpAlign: Align = { horizontal: 'right', vertical: 'center' };
  public popUpMargin = {horizontal: 20, vertical: -200 };

  private isAnchorBottom() {
    let el = this.metaAnchor?.nativeElement;
    let elBound = el.getBoundingClientRect();
    return  window.innerHeight - elBound.bottom < 300;
  }

  public toggleMetaEdit() {
    this.isSelectMeta = !this.isSelectMeta;
    if (this.isSelectMeta) {
      let isSmall = this.availableMetas().length < 20;      
      this.popUpMargin = {horizontal: 20, vertical: isSmall ||this.isAnchorBottom() ? 0 : -200};
      this.popUpAlign.vertical = this.isAnchorBottom() ? 'top' : 'center';
      
      setTimeout(() => {
        this.unlistener = this.renderer.listen("document", "click", (event) => {
          if (!this.metacontains(event.target)) {
            this.toggleMetaEdit();
          }
        });
      }, 100)
    } else if (this.unlistener != undefined) {
      this.unlistener();
    }

  }
 
  private metacontains(target: EventTarget): boolean {
    return (
      
      (this.metaPopup ? this.metaPopup.nativeElement.contains(target) : false)
    );
  }
  
}
