@if (isNew()) {
    @if (newOptions().length != 0) {
        <div class="row normal-row">
            <meta-select-button 
                [availableMetas]="this.newOptions()"
                [alreadySelected]="this.alreadySelectedFN()"
                (onSelect)="onNew($event)"
                [labelCb]="this.labelFn()" />
        </div>                
    }
} @else {
    <div class="row normal-row parent-bck clickable" (click)="onEdit()">
        <div>{{this.displayText()}}</div>
    </div>        
}
<kendo-dialog *ngIf="this.isEditing" title="Uren Tellers" width="300px" (close)="this.isEditing = false">
    <div class="row">
        <kendo-label text="No" [style]="{'width':'100%'}">
            <kendo-dropdownlist [data]="this.tMenu()"
             
                [valuePrimitive]="true" [(value)]="this.editValue.no" [valueField]="'index'"
                 [textField]="'label'"></kendo-dropdownlist>
        </kendo-label>
    </div>
    <div>
        <kendo-label text="Digital Input">
            <kendo-numerictextbox [(value)]="editValue.kanaal" format="n0" [min]="0"
                [style]="{ width: '70px' }"></kendo-numerictextbox>
        </kendo-label>
        <div>
            <kendo-checkbox #invertedChk [(checkedState)]="editValue.inverted"></kendo-checkbox>
            <kendo-label [for]="invertedChk" text="Inverted">
            </kendo-label>
        </div>

    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.isEditing = false">Cancel</button>
        <button kendoButton (click)="onDelete()" themeColor="primary" *ngIf="!isNew()">
            Delete
        </button>
        <button kendoButton (click)="onSave()" themeColor="primary">
            Save
        </button>
    </kendo-dialog-actions>

</kendo-dialog>
