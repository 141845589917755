<div class="kaart">
    @let k = this.kaart();
    @if (k != undefined) {
    <div>{{k.type.label}}({{k.number}}) <button kendoButton (click)="this.onDelete()" [svgIcon]="icon" [size]="'small'"></button>
    </div>
    @let dip = this.dipSwitch();
    @if (dip != undefined) {
    <div>dip: {{dip}}</div>
    }

    <ioview [ios]="k.getIOs()"></ioview>

    }
</div>