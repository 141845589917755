import { Component, computed, effect, signal } from '@angular/core';
import { JSBridgeService } from '../../core/jsbridge.service';
import { KopConfigService } from '../../core/kop-config.service';
import { ConfigProps } from '@wasm/KopWeb';

type ConfigPropsD= {
  titel: string,
  extras: number,
  config: number
};

@Component({
  selector: 'config-editor',
  templateUrl: './config-editor.component.html',
  styles: `
  .radio-row {
    display: flex;
    flex-direction: column;
  }
  `
})
export class ConfigEditorComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService) {
    effect(() => {
    });
  }

  public editValue = signal<ConfigPropsD>({
    titel: '',
    config: 0,
    extras: 0
  });

  public extraConfigs = computed(() => {
    let afd = this.kopConfigService.selectedAfd();
    let groups: Record<number, {configs: Array<ConfigProps>, selected?: number}> = {};
    if (afd != undefined) {
      let extraConfig = this.editValue();
      let extraOptions = this.kopConfigService.currentMeta().extraConfigs;
      for (let eo of extraOptions) {
        let group = groups[eo.mask];
        if (group == undefined) {
          group = {
            configs: []
          }
          groups[eo.mask] = group;
        }
        group.configs.push(eo);

        if ((eo.mask & extraConfig.extras) == eo.value) {
          group.selected = eo.waarde;
        }
      }
      
    }
    return groups;
  });

  public extraConfigsKW = computed(() => {
    return Object.entries(this.extraConfigs());
  });

  

  public extraConfigsRO = computed(() => {
    let ret: Array<ConfigProps> = [];
    let afd = this.kopConfigService.selectedAfd();
    if (afd != undefined) {
      let extraConfig = afd.config();
      let extraOptions = this.kopConfigService.currentMeta().extraConfigs;
      for (let eo of extraOptions) {
        if ((eo.mask & extraConfig.extras) == eo.value) {
          ret.push(eo);
        }
      }
    }
    return ret;
  });

  public isEditing = false;

  public onEdit() {
    let afd = this.kopConfigService.selectedAfd();
    if (afd != undefined) {
      this.editValue.set({
        titel: afd.afdeling().titel.toString(),
        extras: afd.config().extras,
        config: afd.config().config
      });
    }      

    this.isEditing = true;
  }

  onRadioChange(e: any, group: any) {
    let mask = group;

    let g = this.extraConfigs()[mask];
    let c = g.configs.find(c => c.waarde == e.target.value);
    if (c != undefined) {
      this.editValue.update(d => {
        let ret = {...d};
        ret.extras = (d.extras & ~mask) | c.value;
        return ret;
      });
    }
  }
  onCheckboxChange(e: boolean | string, group: any) {
    let mask = group;

    this.editValue.update(d => {
      let ret = {...d};
      ret.extras = (d.extras & ~mask) | ((e == true) ? mask : 0);
      return ret;
      
    });

  }

  onTitleChange(e: string) {
    this.editValue.update(d => {
      let ret = {...d};
      ret.titel = e;
      return ret;
    })
  }

  onSave() {

    this.kopConfigService.selectedAfd.update((afd) => {
      if (afd != undefined) {
        let w = 0;
        let extraOptions = this.kopConfigService.currentMeta().extraConfigs;
        for (let eo of extraOptions) {
          if ((eo.mask & this.editValue().extras) == eo.value) {
            w |= eo.waarde;
          }
        }  
        let c = afd.config();
        c.config = w;
        c.extras = this.editValue().extras;
        afd.afdeling().titel = this.editValue().titel;
      }
      return afd;
    });
    this.isEditing = false;
  }
  
}
