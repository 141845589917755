import { Component, computed } from '@angular/core';
import { PropsServiceService } from '../../props-service.service';
import { KopConfigService } from '../../../core/kop-config.service';
import { wasmVectorToArray } from '../../../utils/wasmVector';
import { JSBridgeService } from '../../../core/jsbridge.service';

@Component({
  selector: 'klassiek-alarms',
  templateUrl: './klassiek-alarms.component.html',
  styles: `
  .wrapper {
    overflow-y: auto;
    max-height: 600px;
  }
  .splitter {
    border-bottom: 1px solid black;
    margin: 3px 0px 3px 0px;
    width: 100%;
  }
  `
})
export class KlassiekAlarmsComponent {

  constructor(public propsService: PropsServiceService, public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService) { }


  public isEditing: boolean = false;

  public availableAlarms = computed(() => {
    let currentAlarms = this.propsService.alarms();
    let jsBridge = this.jsBridgeService.getBridgeSync();
    currentAlarms = currentAlarms != undefined ? currentAlarms : [];
    let metas = this.kopConfigService.getAlarmsMeta();
    let ret = metas.filter(m => !currentAlarms.find(a => a.index == m.index));
    let alarmTypes = wasmVectorToArray(jsBridge.getAlarmTypes());
    let ret2 = ret.map(m => {
      let type = alarmTypes.filter(at => at.groep == m.groep)[0];   
      return {
        index: m.index,
        kanaal: 0,
        typeIndex: type.index,
        inverted: false  
      }
    });
   
    return ret2;
  })

}
