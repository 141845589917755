import { Component, computed, input } from '@angular/core';
import { Kaart } from '../../hdw-config/HdwSysteem';
import { isSysteemKaart } from '../../hdw-config/hdw-konfig-types';
import { HwdConfigService } from '../../hwd-config.service';
import { minusOutlineIcon, trashIcon } from "@progress/kendo-svg-icons";

@Component({
  selector: 'kart-view',
  templateUrl: './kart-view.component.html',
  styleUrl: './kart-view.component.scss'
})
export class KartViewComponent {
  constructor(public hdwConfigService: HwdConfigService) { }

  public icon = trashIcon;

  public kaartId = input.required<number>();
  public kaart = computed(() => {
    let id = this.kaartId();
    return this.hdwConfigService.system()?.getKaart(id);
  })

  public dipSwitch = computed(() => {
    let kaart = this.kaart();
    if ((kaart != undefined)) { 
      if (kaart.dipSwitch != kaart.number) {
        return kaart.dipSwitch;
      }
    }
    return undefined;
  });

  onDelete() {
    let busType = this.kaart()?.type.bus;
    if (busType == undefined) {
      return;
    }
    this.hdwConfigService.system.update(system => {
      system?.deleteKaart(this.kaartId(), busType);
      return system;
    });
  }
}
