import { Component, computed } from '@angular/core';
import { PropsServiceService } from '../../props-service.service';
import { KopConfigService } from '../../../core/kop-config.service';

@Component({
  selector: 'klassiek-servo',
  templateUrl: './klassiek-servo.component.html',
  styles: `
  .wrapper {
    overflow-y: auto;
    max-height: 600px;
  }
  .splitter {
    border-bottom: 1px solid black;
    margin: 3px 0px 3px 0px;
    width: 100%;
  }
  `
})
export class KlassiekServoComponent {

  constructor(public propsService: PropsServiceService, public kopConfigService: KopConfigService) { }

  public isEditing: boolean = false;

  public availableServos = computed(() => {
    let currentServos =  this.propsService.servos();
    let servosMetas = this.kopConfigService.currentMeta().servos;
    let metas =  servosMetas.filter(servo => !currentServos.find(s => s.index == servo.index));
    let ret = metas.map(m => {
      return this.kopConfigService.servoKCtoModule(m.index, undefined);
    });
    return ret;

  });

}
