import { EmbindModule, eUrenTellerType } from "@wasm/KopWeb";

export type ModuleAI = {
    index: number;
    kanaal: number;
    range: number;
    typeIndex: number;
}

export type LosDefType = "losdef" | "droogwand" | "koeling" | "verwarming";


export type ModuleLosdef = {
    index: number;
//    displayName: string;
    type: number;
    waarde?: number;
    inverted: boolean;
    range?: number
}

export type ModuleAlarm = {
    index: number;
    kanaal: number;
    inverted: boolean;
    typeIndex: number;
}

export type ModuleParam =
    {
        type: number;        // Code parameter bij special programma
        waarde: number;
        // menunr: number;
        // omschr: string;  // Tekst van parameter
        range: number;
        inverted: boolean;
    };


export type ModuleUren = {

    key: number;

    no: number;
    kanaal: number;
    blank: boolean;
    type?: eUrenTellerType;
    inverted: boolean;
  
}

export type GlobalConfig = {
    baudrate: number;
    visuGrafieken: boolean;
    ctdoos: boolean;
    pralarm: boolean;
    nkb: boolean;
    kristal: number;
    srtPrn: number;
    overzPCLCD: boolean;
    weermast: boolean;
    nieuwVideo: boolean;
    compress: boolean;

    station: number;
    cyclPeak: number;
    luchtdruk: number;
    updatenr: number;

    peakShaveInZones: boolean;
    noVerhoogKetelSp: boolean;
    windVaanRefSpan7V: boolean;
    sc9x0_FastLink: boolean;
    gasMeting2Apart: boolean;
    digWindsnelhOpDI1: boolean;
    branderOpMinTemp: boolean;
    dauwpuntBegrenzing: boolean;

    noBedieningen: number;
    comTxt: string;
    gekoppeldKlantno: string;

    compType: number;
    totener: number;

    nszone: Array<number>;

}

export type GlobalConfigExtra = {
    visuSercoVisie: boolean;
    visuGrafieken: boolean;
    visuExportModule: boolean;
    visuSercoView: boolean;
    visuSMSModule: boolean;
    visuPeriodeOverzicht: boolean;
    visuSnelleGrafieken: boolean;
    visuSercoDec: boolean;

    klimMeerDaagseTempInt: boolean;
    klimMTIWeerServer: boolean;
    klimGasPeakShaven: boolean;
    klimNuonC2: boolean;
    klimSercoLink: boolean;
    klimUitgebrCO2Prg: boolean;
    klimGewasTempRegelPrg: boolean;
    klimSerCoolVKassen: boolean;
    klimUitgebrSchermPrg: boolean;
    klimKoppelingSDF: boolean;
    klimRecepten: boolean;
    klimFlexRecepten: boolean;

    geoBreedte: number;
    geoLengte: number;
    geoTijdzone: number;

    rackxtender: number;
    fastlinkxtender: number;
    sercoLinkStationId: number;

    
    noEuroSummerTime: boolean;
    printInstChangeAbroad: boolean;
}

export type ModuleServo = {
    index: number;
    uitgang: number;
    inverted: boolean;
    looptijd: string;
    modbusNetworkId: number;
    stationnr: number;
    soort: number;
    params: Record<number, ModuleParam>;
}

export type ModBusNetwork = {
    idNr: number;
    name: string;
    ip: string;
    port: number;
}

export type ModuleBijzPrg = {
    index: number;
    params: Record<number, ModuleParam>;
}


export enum IOBaseType {
    Analog = "Analog",
    Digital = "Digital",
    ModBus = 'ModBus',
    Temperature = "Temperature",
    RH = "RH"
}

const ShortBaseType: Record<IOBaseType, string> = {
    [IOBaseType.Analog]: "A",
    [IOBaseType.Digital]: "D",
    [IOBaseType.ModBus]: "MB",
    [IOBaseType.Temperature]: "T",
    [IOBaseType.RH]: "R"
}

export enum IODirection {
    IN = "IN",
    OUT = "OUT"
}

const ShortIODirection: Record<IODirection, string> = {
    [IODirection.IN]: "I",
    [IODirection.OUT]: "O"
};

export type _IOType = {
    type: IOBaseType,
    direction: IODirection
}

export class IOType implements _IOType {
    type: IOBaseType;
    direction: IODirection;

    constructor(t: _IOType) {
        this.type = t.type;
        this.direction = t.direction;
    }

    equals(b: IOType | undefined) {
        if (b == undefined) return false;
        return (this.type == b.type) && (this.direction == b.direction);
    }

    toString() {
        return `${ShortBaseType[this.type]}${ShortIODirection[this.direction]}`;
    }
    
    isSameBaseType(b: IOType) {
        return (this.type == b.type);
    }

    isOpposed(b: IOType) {
        return (this.type == b.type) && (this.direction != b.direction);
    }
}


export var AnalogIn = new IOType({type: IOBaseType.Analog, direction: IODirection.IN});
export var AnalogOut = new IOType({type: IOBaseType.Analog, direction: IODirection.OUT});
export var DigitalOut = new IOType({type: IOBaseType.Digital, direction: IODirection.OUT});
export var DigitalIn = new IOType({type: IOBaseType.Digital, direction: IODirection.IN});
export var ModBusOut = new IOType({type: IOBaseType.ModBus, direction: IODirection.OUT});

export type RangedKanaal = {
    ioType: IOType;
    start: number;
    range: number;
}

export type RangedKanaalInput = RangedKanaal &{
    inverted?: boolean;
}

export type RangedKanaalAssign = {
    rangedKnaal: RangedKanaal;
    assignement: {
        type: 'ai' | 'servo' | 'losdef' | "bijzPrg" | "alarm",
        index: number,
        omschrijving: string,
        afdeling: string,
        afdRefNr: number
    }
}

export type tServoKeys = keyof EmbindModule["eServoSoort"];

export const SERVO_TYPE_NAME: Record<tServoKeys, string> = {
    eSS_GEEN: "No Type",
    eSS_ANA0T10: "Analog",
    eSS_ANA2T10: "Analog",
    eSS_LLBUS: "MODBus",
    eSS_OPDI: "On/Off",
    eSS_PULS: "Pulse",
    eSS_SERVO: "Servo",
    eSS_SLBUS: "SLBus"
}
