<button kendoButton size="large" style="width: 100%;" (click)="this.isEditing = true">Uren tellers</button>
<kendo-dialog *ngIf="isEditing" title="Uren tellers" width="550px" (close)="this.isEditing = false">
    <div class="wrapper">
        @for (uk of this.propsService.urenKeys(); track uk) {
            <uren-editor [initialValue]="this.propsService.urenModule()![uk]" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over"/>
        }
        <div class="splitter"></div>
        @for (uk of this.availableUrenKeys(); track uk) {
            <uren-editor [initialValue]="uk" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" [onlyDesc]="true" />
        }
    </div>
</kendo-dialog>