type WasmVector<T> =  {
    push_back(_0: T): void;
    resize(_0: number, _1: T): void;
    size(): number;
    get(_0: number): T | undefined;
    set(_0: number, _1: T): boolean;
    delete(): void;  
}

type WasmVectorWithClear<T> = WasmVector<T> & {
    clear(): void;  
}

interface WasmMap {
    size(): number;
    get(_0: number): any | undefined;
    set(_0: number, _1: any): void;
    keys(): WasmVector<number>;
    delete(): void;
  }


export function eraseFromWasmVector<T extends {index: number}>(t: WasmVectorWithClear<T>, idx: number) {
    let old = wasmVectorToArray(t);
    t.clear();
    for (let ai of old) {
      if (ai.index != idx) {
        t.push_back(ai);
      }
    }
}

export function wasmVectorToArray<T>(t: WasmVector<T>):Array<T> {
    var ret:Array<T> = [];
    for (var i = 0; i < t.size(); i++) {
        ret.push(t.get(i)!);
    }
    return ret;
}

export function fillWasmVector<T>(dest: WasmVector<T>, source:Array<T>): WasmVector<T> {
    for (var idx of source) {
        dest.push_back(idx);    
    }
    return dest;
}

export function wasmMapToRecord<TV>(t: WasmMap | undefined, lambda: (e:any) => TV):Record<number,TV> {
    var ret:Record<number,TV> = {};
    if (t == undefined) {
        return ret;
    }
    var keys = wasmVectorToArray(t.keys());
    keys.forEach(key => {
        ret[key] = t.get(key)!;
    });
    return ret;
}