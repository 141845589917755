import { Component, computed, input, output, signal } from "@angular/core";
import { AnalogIn, AnalogOut, DigitalIn, DigitalOut, IOType, RangedKanaal, RangedKanaalAssign } from "../../props/PropTypes";
import { rangedKanaalLabel } from "../kanaal.utils";

export const MAX_KANALEN_DIGITAAL = 2048;
export const MAX_KANALEN_ANALOOG_IN = 255;
export const MAX_KANALEN_ANALOOG_IN_REAL = 510;
export const MAX_KANALEN_ANALOOG_UIT = 1024;

export type KanaalSelection = {
    kanaal?: RangedKanaal,
    assignement?: Array<RangedKanaalAssign>
  };

  
@Component({
    selector: 'kanaal-selector-base',
    template: ''
})
export class KanaalSelectorBase {
    public kanaalType = input<IOType>();
    public range = input<number>(1);
    public assign = input.required<RangedKanaalAssign[]>();
    public selection = signal<KanaalSelection>({});
    public onKanaalSelected = output<KanaalSelection>();

    public initialSelection = input<KanaalSelection>({});


    public rowSize() {
        return 64;
    }

    public kanaalMap = computed(() => {

        let rSize = this.rowSize();
        let ret: Array<Array<Array<RangedKanaalAssign>>> = this.initMap(this.maxKanaal(), rSize);
        let outOfMax: Array<{ kanaal: number, assign: RangedKanaalAssign }> = [];
        let kType = this.kanaalType();
        let assignement = this.assign();
        for (let a of assignement) {
            if (a.rangedKnaal.ioType.equals(kType)) {
                for (let k = 0; k < a.rangedKnaal.range; k++) {
                    if (a.rangedKnaal.start > 0) {
                        let kanaal = a.rangedKnaal.start + k - 1;
                        if (kanaal >= this.maxKanaal()) {
                            outOfMax.push({
                                assign: a,
                                kanaal: kanaal
                            });
                        } else {
                            let y = kanaal % rSize;
                            let x = Math.floor(kanaal / rSize);
                            let cell = ret[x][y];
                            cell.push(a);
                        }
                    }
                }
            }
        }

        return ret;
    });

    protected initMap(max: number, rowSize: number): Array<Array<Array<RangedKanaalAssign>>> {
        let ret: Array<Array<Array<RangedKanaalAssign>>> = [];
        for (let i = 0; i < max; i++) {
            let x = Math.floor(i / rowSize);
            if (x >= ret.length) {
                ret.push([]);
            }
            let row = ret[x];
            row.push([]);
        }
        return ret;
    }

    protected isValidSelection(kanaal: number) {
        let kType = this.kanaalType();
        if (kType?.equals(AnalogIn) && this.range() == 2) {
            return kanaal % 2 == 0;
        }
        return true;
    }


    public maxKanaal = computed(() => {
        // return 64;
        let kType = this.kanaalType();
        if (DigitalIn.equals(kType)) {
            return MAX_KANALEN_DIGITAAL;
        } else if (AnalogIn.equals(kType)) {
            return MAX_KANALEN_ANALOOG_IN_REAL;
        } else if (AnalogOut.equals(kType)) {
            return MAX_KANALEN_ANALOOG_UIT;
        } else if (DigitalOut.equals(kType)) {
            return MAX_KANALEN_DIGITAAL;
        }
        return 0;
    });

    public isSelected(x: number, y: number) {
        let kanaal = x * this.rowSize() + y + 1;
        let selection = this.selection();
        let sKanaal = selection.kanaal?.start;
        if (sKanaal == undefined) {
            sKanaal = this.initialSelection().kanaal?.start;
        }
        let range = this.range();
        if (sKanaal == undefined || range == undefined) {
            return false;
        }
        return sKanaal <= kanaal && kanaal < sKanaal + range;
    }

    public kanaalLabel(kanaal: number): string {
        let ioType = this.kanaalType();
        if (ioType == undefined) {
          return "";
        }
        return rangedKanaalLabel({
          ioType: ioType,
          range: 1,
          start: kanaal
        }).split(": ")[1];
      }
    
      public rangedKanaalLabel(rangedKanaal: RangedKanaal): string {
        return rangedKanaalLabel(rangedKanaal);
      }
    
}