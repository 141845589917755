import { Component, computed, ElementRef, input, Renderer2, ViewChild } from '@angular/core';
import { ModulesRepoService } from '../modules-repo.service';
import { AfdModuleMeta, AfdSlotMeta } from '@etron/typings';
import { KopConfigService } from '../../core/kop-config.service';
import { KopModulesService } from '../kop-modules.service';

@Component({
  selector: 'slot-group',
  templateUrl: './slot-group.component.html',
  styleUrl: './slot-group.component.scss'
})
export class SlotGroupComponent {

  constructor(public moduleRepo: ModulesRepoService,public renderer: Renderer2, private modulesService: KopModulesService) {

  }

  public slot = input<AfdSlotMeta | undefined>(undefined);

  public modules = computed(() => {
    return this.moduleRepo.getModulesByKey(this.slot()?.modules || []);
  });

  public isUsed = computed(() => {
    let usedModules = this.modulesService.afdModules();
    let slotModules = this.slot()?.modules || [];
    let idx =usedModules.findIndex(m=> slotModules.indexOf(m.meta.key)  >= 0);
    console.log(this.slot()?.name,idx >= 0);
    return idx >= 0;
  });

  public isSlotOpen: boolean = false;
  @ViewChild("newAnchor", { read: ElementRef }) public metaAnchor?: ElementRef = undefined;
  @ViewChild("metaPopup", { read: ElementRef }) public metaPopup?: ElementRef = undefined;
  private unlistener?: () => void; 

  checkListeners() {
    if (this.isSlotOpen) {
      
      setTimeout(() => {
        this.unlistener = this.renderer.listen("document", "click", (event) => {
          if (!this.metacontains(event.target)) {
            this.setSlotOpen(false);
          }
        });
      }, 100)
    } else if (this.unlistener != undefined) {
      this.unlistener();
    }
  }

  private metacontains(target: EventTarget): boolean {
    return (
      
      (this.metaPopup ? this.metaPopup.nativeElement.contains(target) : false)
    );
  }

  onDragStart($event: DragEvent, module: AfdModuleMeta) {
    if (this.isUsed()) return false;
    console.log($event);
    if ($event.dataTransfer != null) {
      $event.dataTransfer.setData('module', module.key);
    }
  }

  setSlotOpen(v: boolean) {
    this.isSlotOpen = v;
    this.checkListeners();
  }
  onClick() { 
    this.setSlotOpen(!this.isSlotOpen);
  }

  onDragEnd($event: DragEvent) {
    this.setSlotOpen(false);
  }


}
