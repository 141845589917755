import { Component, computed } from '@angular/core';
import { PropsServiceService } from '../../props-service.service';
import { KopConfigService } from '../../../core/kop-config.service';

@Component({
  selector: 'klassiek-bijz-prgms',
  templateUrl: './klassiek-bijz-prgms.component.html',
  styles: `
  .wrapper {
    overflow-y: auto;
    max-height: 600px;
  }
  .splitter {
    border-bottom: 1px solid black;
    margin: 3px 0px 3px 0px;
    width: 100%;
  }
  `
})
export class KlassiekBijzPrgmsComponent {

  constructor(public propsService: PropsServiceService, public kopConfigService: KopConfigService) { }


  public isEditing: boolean = false;

  public availableBijzPrgms = computed(() => {
    let currentBPrgms =  this.propsService.bijzPrgs();
    let bprgmsMetas = this.kopConfigService.currentMeta().bijzPrgs;
    let metas =  bprgmsMetas.filter(bpm => !currentBPrgms.find(bp => bp.index == bpm.index));
    let ret = metas.map(m => {
      return this.kopConfigService.bijzPrgmKCtoModule(m.index, undefined);
    });
    return ret;
  });

}
