@if (this.presentation() == "new") {
    @let alarmT = this.uurenTexts();
    @for (key of this.range; track key) {
        <div class="row condensed ">{{key+1}}:{{alarmT[key]}}</div>
    }
    <button kendoButton size="none" [themeColor]="'tertiary'" (click)="edit()">Edit</button>
} @else {
    <button  kendoButton size="large" (click)="edit()"  style=" width: 100%;">Uren teller teksten</button>

}
<kendo-dialog *ngIf="isEditing" title="Uuren Teller Teksten" width="300px" (close)="this.isEditing = false">
    <div>
        @for (key of this.range; track key) {
            <kendo-label text="{{key+1}}">
                <kendo-textbox [(value)]="this.editVal[key]" ></kendo-textbox>
            </kendo-label>
        }        
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.isEditing = false">Cancel</button>
        <button kendoButton (click)="onSave()" themeColor="primary">
            Save
        </button>
    </kendo-dialog-actions>
</kendo-dialog>


