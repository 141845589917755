<kendo-appbar position="top">
    <kendo-appbar-spacer>

    </kendo-appbar-spacer>
    <kendo-appbar-section>
        <auth-badge></auth-badge>
    </kendo-appbar-section>
</kendo-appbar>
<kendo-grid [kendoGridBinding]="this.klanten" class="kendo-grid-custom" [filterable]="true" [sortable]="true" >
    <kendo-grid-column field="KLANTNO" title="Klant">
        <ng-template kendoGridCellTemplate let-dataItem>
            <a class="kop-link" routerLink="/SXB/{{dataItem.KLANTNO}}"
                routerLinkActive="active">{{dataItem.KLANTNO}}</a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="FULLNAAM" title="Naam">
    </kendo-grid-column>
    <kendo-grid-column field="NAAM" title="Dealer"></kendo-grid-column>

</kendo-grid>