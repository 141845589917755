import { Component, computed, inject, input, Input, output, ViewEncapsulation } from '@angular/core';
import { JSBridgeService } from '../../core/jsbridge.service';
import { cAfdelingWM } from '@wasm/KopWeb';
import { KopConfigService } from '../../core/kop-config.service';
import { ModulesRepoService } from '../../modules/modules-repo.service';
import { ValidationService } from '../../modules/validation.service';
import {  trashIcon } from "@progress/kendo-svg-icons";

@Component({
  selector: 'afd-card',
  templateUrl: './afd-card.component.html',
  styleUrl: './afd-card.component.scss',
})
export class AfdCardComponent {

  public trashIcon = trashIcon;

  public jsBridge: JSBridgeService = inject(JSBridgeService);
  public kopConfigService: KopConfigService = inject(KopConfigService);

  private repoService = inject(ModulesRepoService);
  private validationService = inject(ValidationService);

  public afd = input<cAfdelingWM>();
  public selected = input<boolean>(false);


  public removeDialogOpen = false;
  onCardClicked = output<cAfdelingWM>();

  ngOnInit() {
  }

  typeKey(v: number | undefined) {
    if (v == undefined) {
      return "";
    }
    let typeMap = this.repoService.typeMap();
    return typeMap[v];
  }

  onClick() {
    let t = this.afd();
    if (t != undefined) {
      this.onCardClicked.emit(t);
    }
  }

  onRemove() {
    let t = this.afd();
    if (t != undefined) {
      this.kopConfigService.removeAfd(t);
    }
    this.removeDialogOpen = false
  }

  public validation = computed(() => {
    
    let afd = this.afd();
    let repoLoaded = this.repoService.loaded();
    if ((afd != undefined) && (repoLoaded)) {
      let validation = this.repoService.getValidation(afd.afdeling()?.type);
      if (validation != undefined) {
        let ret = this.validationService.validateRequiredProps(afd, validation.validation);
        return ret;
      }
    }
    return;
  });

  public isValid = computed(() => {
    let validation = this.validation();
    if (validation != undefined) {
      return validation.requiredModules == undefined || validation.requiredModules.modules.length == 0;
    }
    return true;
  });

}
