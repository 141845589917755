import { Component, computed, input } from '@angular/core';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { KopModulesService } from '../../modules/kop-modules.service';
import { wasmVectorToArray } from '../../utils/wasmVector';

@Component({
  selector: 'uuren-teksten',
  templateUrl: './uuren-teksten.component.html',
  styles: ``
})
export class UurenTekstenComponent {
  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService,
    public kopModulesService: KopModulesService) { }

  public presentation = input<"classic" | "new">("new");

  public uurenTexts = computed(() => {
    console.log("alarmTexts");
    let tmUTs = this.kopConfigService.selectedAfd()?.afdeling().urenTellerTexts;
    let ret: Record<number, string> = [];
    if (tmUTs != undefined) {
      let keys = wasmVectorToArray(tmUTs.keys());
      keys.forEach(key => {
        let text = tmUTs.get(key);
        if (text != undefined) {
          ret[key] = text?.toString();
        }
      });
    }
    return ret;
  });

  public isEditing = false;
  public editVal: Record<number, string> = {};
  public range: Array<number> = [0, 1, 2, 3];

  public edit() {
    for (let i of this.range) {
      this.editVal[i] = this.uurenTexts()[i] ?? "";
    }
    this.isEditing = true;
  }

  public onSave() {
    this.isEditing = false;
    this.kopConfigService.selectedAfd.update(afd => {
      let wAfd = afd?.afdeling();
      if (wAfd != undefined) {
        wAfd.urenTellerTexts.clear();
        for (let [key, val] of Object.entries(this.editVal)) {
          if (val != "") {
            wAfd.urenTellerTexts.set(parseInt(key), val);
          }
        }
      }
      return afd;
    });
  }

}
