import { Component, ElementRef, input, Renderer2, ViewChild } from '@angular/core';
import { infoSolidIcon } from "@progress/kendo-svg-icons";

@Component({
  selector: 'info',
  templateUrl: './info.component.html',
  styles: ``
})
export class InfoComponent {


  constructor(public renderer: Renderer2) {
  }

  public description = input<string>("");
  public icons = { infoSolid: infoSolidIcon };

  @ViewChild("anchor", { read: ElementRef }) public anchor?: ElementRef = undefined;
  @ViewChild("popup", { read: ElementRef }) public popup?: ElementRef = undefined;
  private unlistener?: () => void;

  public showHelp = false;

  public toggleHelp() {
    this.showHelp = !this.showHelp;
    if (this.showHelp) {
      this.unlistener = this.renderer.listen("document", "click", (event) => {
        if (!this.contains(event.target)) {
          this.toggleHelp();
        }
      })
    } else if (this.unlistener != undefined) {
      this.unlistener();
    }

  }

  private contains(target: EventTarget): boolean {
    return (
      (this.anchor && this.anchor.nativeElement.contains(target)) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }


}
