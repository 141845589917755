import { Component, effect, input, output } from '@angular/core';
import { AnalogIn, AnalogOut, DigitalIn, DigitalOut, RangedKanaalAssign } from '../../props/PropTypes';

@Component({
  selector: 'kanaal-overview',
  templateUrl: './kanaal-overview.component.html',
  styles: ``
})
export class KanaalOverviewComponent {

  constructor() { 
  }


  public ai = AnalogIn;
  public ao = AnalogOut;
  public di = DigitalIn;
  public do = DigitalOut;
  
  public assign =  input.required<RangedKanaalAssign[]>();

}
