@if (this.editValue != undefined) {
    <kendo-dialog *ngIf="isEditing()" title="Global Configuration" width="800px" (close)="this.onDone.emit(false)">
        <div class="config-cols">
            <div class="one-col">
                <kendo-label text="Baudrate">
                    <kendo-dropdownlist [data]="this.baudRateOptions" [value]="this.editValue.baudrate"
                        (valueChange)="this.editValue.baudrate = $event"></kendo-dropdownlist>
                </kendo-label>
                <div>
                    <kendo-checkbox #visuGrafieken [(checkedState)]="this.editValue.visuGrafieken"></kendo-checkbox>
                    <kendo-label text="Grafiek Optie" [for]="visuGrafieken">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #ctdoos [(checkedState)]="this.editValue.ctdoos"></kendo-checkbox>
                    <kendo-label text="Contactdozen meting" [for]="ctdoos">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #pralarm [(checkedState)]="this.editValue.pralarm"></kendo-checkbox>
                    <kendo-label text="Alarmen op printer" [for]="pralarm">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #nkb [(checkedState)]="this.editValue.nkb"></kendo-checkbox>
                    <kendo-label text="Nieuw keyboard" [for]="nkb">
                    </kendo-label>
                </div>
                <kendo-label text="Kristal">
                    <kendo-dropdownlist [data]="this.kristalOptions" [value]="this.editValue.kristal"
                        [valuePrimitive]="true" [valueField]="'index'" [textField]="'label'"
                        (valueChange)="this.editValue.kristal = $event"></kendo-dropdownlist>
                </kendo-label>
                <kendo-label text="Printer">
                    <kendo-dropdownlist [data]="this.printerOptions" [value]="this.editValue.srtPrn"
                        [valuePrimitive]="true" [valueField]="'index'" [textField]="'label'"
                        (valueChange)="this.editValue.srtPrn = $event"></kendo-dropdownlist>
                </kendo-label>
                <div>
                    <kendo-checkbox #overzPCLCD [(checkedState)]="this.editValue.overzPCLCD"></kendo-checkbox>
                    <kendo-label text="Oberzichten via PC/LCD" [for]="overzPCLCD">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #weermast [(checkedState)]="this.editValue.weermast"></kendo-checkbox>
                    <kendo-label text="Weermast aanwezig" [for]="weermast">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #nieuwVideo [(checkedState)]="this.editValue.nieuwVideo"></kendo-checkbox>
                    <kendo-label text="Nieuwe video print" [for]="nieuwVideo">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #compress [(checkedState)]="this.editValue.compress"></kendo-checkbox>
                    <kendo-label text="Compress kop" [for]="compress">
                    </kendo-label>
                </div>
                <kendo-label text="Computer Type">
                    <kendo-dropdownlist [data]="this.computerTypeOptions" [value]="this.editValue.compType"
                        [style]="{ width: '150px' }"
                        [valuePrimitive]="true" [valueField]="'index'" [textField]="'label'"
                        (valueChange)="this.editValue.compType = $event"></kendo-dropdownlist>
                </kendo-label>
                <kendo-label text="T.E. Instalatie:">
                    <kendo-dropdownlist [data]="this.totenerOptions" [value]="this.editValue.totener"
                        [style]="{ width: '150px' }"
                        [valuePrimitive]="true" [valueField]="'index'" [textField]="'label'"
                        (valueChange)="this.editValue.totener = $event"></kendo-dropdownlist>
                </kendo-label>
                <div>
                    <kendo-label text="PC Bedieningen">
                        <kendo-numerictextbox [(value)]="this.editValue.noBedieningen" [min]="0" format="n0"
                            [style]="{ width: '100px' }"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div>
                    <kendo-label text="Gekoppeld klant no">
                        <kendo-textbox [(value)]="this.editValue.gekoppeldKlantno" />
                    </kendo-label>
                </div>
                <div>
                    <kendo-label text="Printer tekst">
                        <kendo-textbox [(value)]="this.editValue.comTxt" />
                    </kendo-label>
                </div>
            </div>
            <div class="one-col">
                <div>
                    <kendo-label text="Station">
                        <kendo-numerictextbox [(value)]="this.editValue.station" [min]="0" format="n0"
                            [style]="{ width: '100px' }"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div>
                    <kendo-label text="Cyclische Peak Shaving">
                        <kendo-numerictextbox [(value)]="this.editValue.cyclPeak" [min]="0" format="n0"
                            [style]="{ width: '100px' }"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div>
                    <kendo-label text="Tussenstop zone 1 witlof">
                        <kendo-numerictextbox [(value)]="this.editValue.nszone[0]" [min]="0" format="n0"
                            [style]="{ width: '100px' }"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div>
                    <kendo-label text="Tussenstop zone 2 witlof">
                        <kendo-numerictextbox [(value)]="this.editValue.nszone[1]" [min]="0" format="n0"
                            [style]="{ width: '100px' }"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div>
                    <kendo-label text="Tussenstop zone 3 witlof">
                        <kendo-numerictextbox [(value)]="this.editValue.nszone[2]" [min]="0" format="n0"
                            [style]="{ width: '100px' }"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div>
                    <kendo-label text="Tussenstop zone 4 witlof">
                        <kendo-numerictextbox [(value)]="this.editValue.nszone[3]" [min]="0" format="n0"
                            [style]="{ width: '100px' }"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div>
                    <kendo-label text="Luchtdruk storing">
                        <kendo-numerictextbox [(value)]="this.editValue.luchtdruk" [min]="0" format="n0"
                            [style]="{ width: '100px' }"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div>
                    <kendo-label text="Update nr door SERCOM">
                        <kendo-numerictextbox [(value)]="this.editValue.updatenr" [min]="0" format="n0" [readonly]="true"
                            [style]="{ width: '100px' }"></kendo-numerictextbox>
                    </kendo-label>
                </div>
    
                <div>
                    <kendo-checkbox #peakShaveInZones [(checkedState)]="this.editValue.peakShaveInZones"></kendo-checkbox>
                    <kendo-label text="Peak Shave in zones" [for]="peakShaveInZones">
                    </kendo-label>
                </div>
    
                <div>
                    <kendo-checkbox #noVerhoogKetelSp [(checkedState)]="this.editValue.noVerhoogKetelSp"></kendo-checkbox>
                    <kendo-label text="Geen verhoging ketelsetpoint" [for]="noVerhoogKetelSp">
                    </kendo-label>
                </div>
    
                <div>
                    <kendo-checkbox #windVaanRefSpan7V [(checkedState)]="this.editValue.windVaanRefSpan7V"></kendo-checkbox>
                    <kendo-label text="Windvaan ref. spanning 7.2V" [for]="windVaanRefSpan7V">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #sc9x0_FastLink [(checkedState)]="this.editValue.sc9x0_FastLink"></kendo-checkbox>
                    <kendo-label text="SC9x0 / FastLink" [for]="sc9x0_FastLink">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #gasMeting2Apart [(checkedState)]="this.editValue.gasMeting2Apart"></kendo-checkbox>
                    <kendo-label text="Gasmeting 2 apart" [for]="gasMeting2Apart">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #digWindsnelhOpDI1 [(checkedState)]="this.editValue.digWindsnelhOpDI1"></kendo-checkbox>
                    <kendo-label text="Digitale windsnelheidmeting op DI 1" [for]="digWindsnelhOpDI1">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #branderOpMinTemp [(checkedState)]="this.editValue.branderOpMinTemp"></kendo-checkbox>
                    <kendo-label text="Brander op minimum temperatuur houden" [for]="branderOpMinTemp">
                    </kendo-label>
                </div>
                <div>
                    <kendo-checkbox #dauwpuntBegrenzing [(checkedState)]="this.editValue.dauwpuntBegrenzing"></kendo-checkbox>
                    <kendo-label text="Dauwpunt begrenzing" [for]="dauwpuntBegrenzing">
                    </kendo-label>
                </div>
    
            </div>
        </div>
        <kendo-dialog-actions>
            <button kendoButton (click)="this.onDone.emit(false)">Cancel</button>
            <button kendoButton (click)="onSave()" themeColor="primary">
                Save
            </button>
        </kendo-dialog-actions>
    </kendo-dialog>
}
