<div class="system-wrapper">
@let system = this.hdwConfigService.system();
@if (system != undefined) {
    @let mbIos = system.getIOs();
    <div>
        <div class="mainboard">
            <div>Mainboard: {{system.mainboard.label}}</div>
            <hdw-io-selector [ios]="mbIos" [initialSelection]="this.initialSelection()" 
                [presentation]="'horizontal'" [kanaalMap]="this.kanaalMap()" (onKanaalSelected)="this.onSelectionChange($event)"></hdw-io-selector>
        </div>    
    </div>
    @let buses = system.getBusTypes();
    @for (bus of buses; track bus) {
        <div class="rack-wrapper">
            <div>{{bus}}</div>
            @let racks = system.getRacks(bus);
            @for (rack of racks; track rack) {
                @let karten = rack.kaarten;
                <div class="rack">
                    <div class="rack-label">{{rack.type.label}} {{rack.id}}</div>
                    <div class="rack-content">
                        @for (kart of karten; track kart) {
                            @if (kart.type.takesRackSpace) {
                                <div class="kaart">
                                    @let kIos = kart.getIOs();
                                    <div class="kaart-label">{{kart.type.label}}({{kart.number}})</div>
                                    <hdw-io-selector [ios]="kIos" [initialSelection]="this.initialSelection()"
                                    (onKanaalSelected)="this.onSelectionChange($event)" [kanaalMap]="this.kanaalMap()"></hdw-io-selector>
                                </div>
    
                            }
                        }
                    </div>
                </div>
            }
        </div>
    }
}

</div>