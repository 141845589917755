<div class="modules-container">
    @for (slot of slots(); track slot.key) {
        <slot-group [slot]="slot"></slot-group>
    }
    <!-- @for (module of modules(); track module.key) {
    <div class="module" draggable="true" (dragstart)="onDragStart($event, module)" kendoPopoverAnchor
        [popover]="firstPopover" showOn="hover">
        <div [style]="{width: '64px', height: '64px'}">
            <asset-icon [imgPath]="module.icon"></asset-icon>
        </div>
        <span class="module_name">{{module.name}}</span>
    </div>
    <kendo-popover #firstPopover [title]="module.name" [width]="400" position="right">
        <ng-template kendoPopoverBodyTemplate>
            <div markdown [data]="this.moduleRepo.getDescription(module)"></div>
        </ng-template>

    </kendo-popover>
    } -->
</div>