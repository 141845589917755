<kendo-dialog *ngIf="isEditing()" title="Global Configuration" width="800px" (close)="this.onDone.emit(false)">
    <div class="mb-grid">
        <div>Id</div>
        <div>Naam</div>
        <div>IP nummer</div>
        <div>Port</div>
        <div></div>
        @for (mb of this.modBusNetworks(); track mb.idNr) {
        <div>{{mb.idNr}}</div>
        <div>{{mb.name}}</div>
        <div>{{mb.ip}}</div>
        <div>{{mb.port}}</div>
        <div>
            <button kendoButton (click)="this.onEdit(mb)">Edit</button>
            <button kendoButton (click)="this.onDelete(mb)">Del</button>
        </div>
        }

        <button kendoButton (click)="this.onAdd()">Toegevoegen</button>

    </div>
</kendo-dialog>
<kendo-dialog *ngIf="this.editValue != undefined" title="Global Configuration" width="800px"
    (close)="this.editValue = undefined">
    <div>
        <div>
            <kendo-label text="Naam">
                <kendo-textbox [(value)]="this.editValue.name" [style]="{ width: '100px' }"></kendo-textbox>
            </kendo-label>

        </div>
        <div>
            <kendo-label text="IP nunmmer">
                <kendo-textbox [(value)]="this.editValue.ip" [style]="{ width: '200px' }"></kendo-textbox>
            </kendo-label>

        </div>
        <kendo-label text="Poort">
            <kendo-numerictextbox [(value)]="this.editValue.port" [min]="0" format="n0"
                [style]="{ width: '100px' }"></kendo-numerictextbox>
        </kendo-label>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.editValue = undefined">Cancel</button>
        <button kendoButton (click)="onSave()" themeColor="primary">
            Save
        </button>
    </kendo-dialog-actions>
</kendo-dialog>