import { inject, Pipe, PipeTransform } from '@angular/core';
import { cAfdelingInfo, eAfdelingType, EmbindModule } from '@wasm/KopWeb';
import { JSBridgeService } from './jsbridge.service';
import { wasmVectorToArray } from '../utils/wasmVector';


@Pipe({
  name: 'afdtype'
})
export class AfdtypePipe implements PipeTransform {

  constructor() { }

  private jsBridgeService = inject(JSBridgeService);
  private map: Record<number, cAfdelingInfo> = {};
  private initialized: boolean = false;

  checkMap() {
    if (!this.initialized) {
      let wasm = this.jsBridgeService.wasmModule;
      let bridge = this.jsBridgeService.getBridgeSync();
      let infos = wasmVectorToArray(bridge.getAfdelingsInfo());
      if (wasm != undefined) {
        Object.entries(wasm.eAfdelingType).forEach(([key, value]) => {
          let info = infos.find(info => info.type == value.value);
          if (info != undefined) {
            this.map[value.value] = info;
          }
        });
      }
      this.initialized = true;
    }
  }
  transform(value: eAfdelingType | undefined, ...args: unknown[]): unknown {
    this.checkMap();
    if (value != undefined && this.map[value.value] != undefined) {
      return this.map[value.value].naam.toString();
    }
    return `${value?.value}`;
  }

}
