import { Component, computed, effect, input } from '@angular/core';
import { cKC_Uren } from '@wasm/KopWeb';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { KopModulesService } from '../../modules/kop-modules.service';
import { wasmMapToRecord } from '../../utils/wasmVector';
import { DigitalIn, ModuleUren } from '../PropTypes';
import { KanaalService } from '../../kanaal/kanaal.service';

@Component({
  selector: 'uren-editor',
  templateUrl: './uren-editor.component.html',
  styles: ``
})
export class UrenEditorComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService,
    public kopModulesService: KopModulesService, public kanaalService: KanaalService) {
    effect(() => {
      let ai = this.initialValue();
      if (ai != undefined) {
        this.editValue = ai;
      }
    });
  }

  // public key = input<number>();
  // public value = input<cKC_Uren>();

  public initialValue = input<ModuleUren>();
  public editValue: ModuleUren = {
    blank: false,
    inverted: false,
    kanaal: 0,
    key: 0,
    no: 0
  }
  public isEditing = false;
  public isNew = input<boolean>(false);

  public onlyDesc = input<boolean>(false);

  public newOptions = computed(() => {
    let ret: Array<{ index: number, label: string }> = [];
    let tmUrenW = this.kopConfigService.selectedAfd()?.afdeling().urens;
    if (tmUrenW != undefined) {
      for (let i = 0; i < 4; i++) {
        ret.push({
          index: i,
          label: `No: (${i + 1})`
        })
      }
    }

    return ret;
  });

  alreadySelectedFN() {
    let tmUrenW = this.kopConfigService.selectedAfd()?.afdeling().urens;
    if (tmUrenW != undefined) {
      let currentUren = wasmMapToRecord(tmUrenW, e => e as cKC_Uren);
      let ret = (idx: number) => {
        return currentUren[idx] != undefined;
      }
      return ret;
    }
    return (idx: number) => false;
  }


  labelFn() {
    let ret = (meta: any) => (meta as any).label;
    return ret;
  }


  public urenTexts = computed<Record<number, string>>(() => {
    let konf = this.jsBridgeService.kopConfig();
    let afdTextsW = this.kopConfigService.selectedAfd()?.afdeling().urenTellerTexts;

    if (konf != undefined) {
      let texts = wasmMapToRecord(konf.gUrenTellerTeksten, e => e.toString() as string);
      if (afdTextsW != undefined) {
        let afdTexts = wasmMapToRecord(afdTextsW, e => e.toString());
        Object.entries(afdTexts).forEach(([key, val]) => {
          texts[parseInt(key) + 20] = val;
        });
      }

      return texts;
    }
    return {};
  });

  public text = computed(() => {
    let texts = this.urenTexts();

    let no = this.initialValue()?.no;
    if ((no != undefined) && (texts[no] != undefined)) {
      return texts[no];
    }

    return "";
  });

  public tMenu = computed(() => {
    let utexts = this.urenTexts();
    let ret: Array<{ index: number, label: string }> = [];

    for (let i = 0; i < 24; i++) {
      let val = utexts[i] ?? "";
      ret.push({ index: i, label: `(T${i + 1}) - ${val}` });
    }
    return ret;

  });

  public displayText = computed(() => {
    let iv = this.initialValue();
    if (iv != undefined) {
      if (this.onlyDesc()) {
        return `${iv.key + 1}`;
      }
      return `${iv.key + 1} : (T${iv.no + 1}) - ${this.text()} - DI ${iv.kanaal}${iv.inverted ? "!" : ""}`;
    }
    return "";
  });


  onNew(e: { index: number }) {
    console.log(e);
    let wasm = this.jsBridgeService.wasmModule!;
    this.editValue = {
      blank: false,
      inverted: false,
      kanaal: 0,
      key: e.index,
      no: 0,
      type: wasm.eUrenTellerType.eUT_Tekst
    }
    this.isEditing = true;
  }

  onEdit() {
    this.isEditing = true;
  }

  onSave() {
    console.log(this.editValue);
    this.isEditing = false;
    this.kopConfigService.selectedAfd.update(afd => {
      this.kanaalService.setInvTab(this.editValue.kanaal, DigitalIn, this.editValue.inverted);
      let tmUrenW = this.kopConfigService.selectedAfd()?.afdeling().urens;
      let wasm = this.jsBridgeService.wasmModule!;

      if (tmUrenW != undefined) {
        if (tmUrenW.get(this.editValue.key) == undefined) {
          tmUrenW.set(this.editValue.key, new wasm.cKC_Uren());
        }
        let tmUren = tmUrenW.at(this.editValue.key);
        tmUren.kanaal = this.editValue.kanaal;
        tmUren.no = this.editValue.no;
        tmUren.blank = this.editValue.blank;
        tmUren.type = this.editValue.type!;
      }
      return afd;
    });
  }

  onDelete() {
    let ue = this.initialValue();
    this.kopConfigService.selectedAfd.update(afd => {
      if ((ue != undefined) && (afd != undefined)) {
        afd.afdeling().urens.erase(ue.key);
      }
      return afd;
    });

  }
}
