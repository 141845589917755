import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { KanaalWrapperComponent, KopwebCoreModule, KopWrapperComponent } from 'kopweb-core';
import { provideRouter, RouterModule, Routes } from '@angular/router';
import { provideMarkdown } from 'ngx-markdown';
import { AuthModule, provideAuth0 } from '@auth0/auth0-angular';


import config from '../../auth_config.json';
import { BadgeComponent } from './auth/badge/badge.component';
import { AuthContentComponent } from './auth/auth-content/auth-content.component';
import { KlantListComponent } from './koppen/klant-list/klant-list.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserKopWrapperComponent } from './koppen/browser-kop-wrapper/browser-kop-wrapper.component';

const { domain, clientId, audience, apiUri, errorPath } = config as {
  domain: string;
  clientId: string;
  audience?: string;
  apiUri: string;
  errorPath: string;
};

export const environment = {
  production: false,
  auth: {
    domain,
    clientId,
    authorizationParams: {
      audience,
      redirect_uri: window.location.origin,
    },
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${apiUri}/*`],
  },
};



const routes: Routes = [
  { path: 'KNL/:klantNo', component: KanaalWrapperComponent },
  { path: 'SXB/:klantNo', component: BrowserKopWrapperComponent },
  { path: '', component: KlantListComponent}
]
@NgModule({
  declarations: [
    AppComponent,
    BadgeComponent,
    AuthContentComponent,
    KlantListComponent,
    BrowserKopWrapperComponent
  ],
  imports: [
    BrowserModule,
    KopwebCoreModule, RouterModule, GridModule,
    AuthModule.forRoot(environment.auth),
  ],
  providers: [provideMarkdown(), provideRouter(routes)],
  bootstrap: [AppComponent]
})
export class AppModule { }
