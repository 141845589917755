import { Component, computed, effect, input, output } from '@angular/core';
import { KopConfigService } from '../../core/kop-config.service';
import { KopModulesService } from '../../modules/kop-modules.service';
import { ModulesRepoService } from '../../modules/modules-repo.service';
import { LosDefType, ModuleAI, ModuleLosdef, ModuleServo } from '../PropTypes';
import { analogeMeting, Losdef, Servo } from '@wasm/KopWeb';
import { wasmVectorToArray } from '../../utils/wasmVector';
import { AfdModule, Validation } from '@etron/typings';
import { ElementType, ValidationService } from '../../modules/validation.service';

type ServoCompound = {
  servo: ModuleServo;
  meta: Servo;
  index: number;
};


type AICompound = {
  ai: ModuleAI;
  meta: analogeMeting;
  index: number;
}

type LosDefCompound = {
  losDef: ModuleLosdef;
  ldType: LosDefType;
  meta: Losdef;
  index: number;
}

export type CompoundValueType = {
  losDefs: Array<LosDefCompound>,
  droogWands: Array<LosDefCompound>,
  koelings: Array<LosDefCompound>,
  verwarmings: Array<LosDefCompound>,
  ais: Array<AICompound>,
  servos: Array<ServoCompound>
}

@Component({
  selector: 'module-props',
  templateUrl: './module-props.component.html',
  styleUrl: './module-props.component.scss'
})
export class ModulePropsComponent {

  constructor(public kopConfigService: KopConfigService, public kopModulesService: KopModulesService,
    public moduleRepo: ModulesRepoService, public validationService: ValidationService) { 

    effect(() => {
      let losDefs: Array<LosDefCompound> = this.losDefsMetas().map(ld => {
        return {
          index: ld.index,
          ldType: 'losdef',
          meta: ld,
          losDef: this.kopConfigService.findOrBuildLosDef(ld, 'losdef')
        }
      });

      let drogWands: Array<LosDefCompound> = this.drogWandsMetas().map(ld => {
        return {
          index: ld.index,
          ldType: 'droogwand',
          meta: ld,
          losDef: this.kopConfigService.findOrBuildLosDef(ld, 'droogwand')
        }
      });

      let koelings: Array<LosDefCompound> = this.koelingMetas().map(ld => {
        return {
          index: ld.index,
          ldType: 'koeling',
          meta: ld,
          losDef: this.kopConfigService.findOrBuildLosDef(ld, 'koeling')
        }
      });
      let verwarmings: Array<LosDefCompound> = this.verwarmingMetas().map(ld => {
        return {
          index: ld.index,
          ldType: 'verwarming',
          meta: ld,
          losDef: this.kopConfigService.findOrBuildLosDef(ld, 'verwarming')
        }
      });


      this.compoundValue = {
        ais: this.ais(),
        losDefs: losDefs,
        droogWands: drogWands,
        koelings: koelings,
        verwarmings: verwarmings,
        servos: this.servos()
      }
    });
  }

  public isEditing = input<boolean>(false);
  public moduleKey = input<string | undefined>(undefined);
  public moduleMeta = computed(() => {
    let moduleKey = this.moduleKey();
    if (moduleKey != undefined) {
      let module = this.moduleRepo.getModule(moduleKey);
      if (module != undefined) {
        return module;
      }
    }
    let selectedModule = this.kopModulesService.selectedAfdModule();
    if (selectedModule != undefined) {
      return selectedModule.meta;
    }

  });

  public compoundValue: CompoundValueType = {
    losDefs: [],
    droogWands: [],
    ais: [],
    koelings: [],
    verwarmings: [],
    servos: []
  };

  public ais = computed(() => {
    let meta = this.moduleMeta();
    let ret = this.kopConfigService.currentMeta().ais
      .filter(ld => meta?.allAnalogIngagen != undefined && meta.allAnalogIngagen.indexOf(ld.index) >= 0)
      .map(ai => {
        let aim = this.findAI(ai);
        return {
          ai: this.kopConfigService.aiKCtoModule(ai.index, aim),
          meta: ai,
          index: ai.index
        }
      });
    return ret;

  });

  public servos = computed(() => {
    let meta = this.moduleMeta();
    let ret = this.kopConfigService.currentMeta().servos
      .filter(servo => meta?.allServos != undefined && meta.allServos.indexOf(servo.index) >= 0)
      .map(servo => {
        let sm = this.findServo(servo);
        return {
          servo: this.kopConfigService.servoKCtoModule(servo.index, sm),
          meta: servo,
          index: servo.index
        }
      });
    return ret;
  });

  private findAI(meta: analogeMeting) {
    let aisWasm = this.kopConfigService.selectedAfd()?.afdeling().analogs;
    if (aisWasm != undefined) {
      let ais = wasmVectorToArray(aisWasm);
      return ais.find(ai => ai.index == meta.index);
    }
  }

  private findServo(meta: Servo) {
    let servosWasm = this.kopConfigService.selectedAfd()?.afdeling().servos;
    if (servosWasm != undefined) {
      let servos = wasmVectorToArray(servosWasm);
      return servos.find(s => s.index == meta.index);
    }
  }

  private filterModuleLosDefs(losdefs: Losdef[]) {
    let meta = this.moduleMeta();
    let ret = losdefs
      .filter(ld => meta?.allLosDefsIndexes != undefined && meta.allLosDefsIndexes.indexOf(ld.index) >= 0);
    return ret;

  }

  public losDefsMetas = computed(() => {
    return this.filterModuleLosDefs(this.kopConfigService.currentMeta().losDefs);
  });

  public drogWandsMetas = computed(() => {
    return this.filterModuleLosDefs(this.kopConfigService.currentMeta().drogWands);
  });

  public koelingMetas = computed(() => {
    return this.filterModuleLosDefs(this.kopConfigService.currentMeta().koelings);
  });

  public verwarmingMetas = computed(() => {
    return this.filterModuleLosDefs(this.kopConfigService.currentMeta().verwarmings);
  });

  cancel = output<void>();
  apply = output<CompoundValueType>();

  onLDChange(e: ModuleLosdef, ldType: LosDefType) {
    let idx;
    switch (ldType) {
      case 'losdef':
        idx = this.compoundValue.losDefs.findIndex(ld => ld.index == e.index);
        this.compoundValue.losDefs[idx].losDef = e;
        break;
      case 'droogwand':
        idx = this.compoundValue.droogWands.findIndex(ld => ld.index == e.index);
        this.compoundValue.droogWands[idx].losDef = e;
        break;
      case 'koeling':
        idx = this.compoundValue.koelings.findIndex(ld => ld.index == e.index);
        this.compoundValue.koelings[idx].losDef = e;
        break;
      case 'verwarming':
        idx = this.compoundValue.verwarmings.findIndex(ld => ld.index == e.index);
        this.compoundValue.verwarmings[idx].losDef = e;
        break;
      }
    this.validate();
  }

  onAIChange(e: any) {
    let idx = this.compoundValue.ais.findIndex(ai => ai.index == e.index);
    this.compoundValue.ais[idx].ai = e;
    this.validate();
  }

  onServoChange(e: any) {
    let idx = this.compoundValue.servos.findIndex(s => s.index == e.index);
    this.compoundValue.servos[idx].servo = e;
    this.validate();
    //this.compoundValue = {...this.compoundValue};
  }

  public validation: Validation | undefined;

  public isValid: boolean = false;


  private  checkIsValid() {
    if (this.validation == undefined) {
      return true;
    }

    if (this.validation.requiredProps == undefined) {
      return true;
    }

    let hasLDErrors = this.validation.requiredProps.losdef != undefined && this.validation.requiredProps.losdef.length > 0;
    let hasAIErrors = this.validation.requiredProps.analogeIngangen != undefined && this.validation.requiredProps.analogeIngangen.length > 0;
    let hasServoErrors = this.validation.requiredProps.servos != undefined && this.validation.requiredProps.servos.length > 0;

    return !hasAIErrors && !hasLDErrors && !hasServoErrors;
  }

  public validate() {
    
    let meta = this.moduleMeta();
    if (meta == undefined) {
      this.isValid = false;
      return;
    }
    let module: AfdModule = {
      meta: meta,
      titel: ""
    };
    if ((module != undefined) ) {
      if (module.meta.validation != undefined) {
        this.validation = this.validationService.validateRequiredProps2(this.compoundValue, module.meta.validation);
      }
    }
    
    this.isValid = this.checkIsValid();

    return;
  };

  public getErrMessage(idx: number, type: ElementType) {
    return this.validationService.getElementValidation(this.validation, { idx: idx, element: type });
  }
  


}
