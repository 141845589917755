import { Component, input } from '@angular/core';
import { AnyIndex, AnyModule, RequiredModuleProps, Validation } from '@etron/typings';
import { KopConfigService } from '../../core/kop-config.service';
import { ModulesRepoService } from '../modules-repo.service';
import { ValidationService } from '../validation.service';

@Component({
  selector: 'validation-info',
  templateUrl: './validation-info.component.html',
  styles: `
  .v-wrapper {
    color: #f50000;
  }`
})
export class ValidationInfoComponent {

  constructor(public kopConfigService: KopConfigService, private modulesRepo: ModulesRepoService, public validationService: ValidationService) { } 
  public validation = input<Validation>();



  metaModule(key: string) {
    return this.modulesRepo.getModule(key);
  }
}
