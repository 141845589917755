import { Component, effect, input, output } from '@angular/core';
import { GlobalConfigExtra } from '../PropTypes';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';

@Component({
  selector: 'global-config-extra',
  templateUrl: './global-config-extra.component.html',
  styles: `
  .config-cols {
    display: flex;
    flex-direction: row;
  }
  .one-col {
    display: flex;
    flex-direction: column;
  }
  `
})
export class GlobalConfigExtraComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService) {
    effect(() => {
      if (this.isEditing()) {
        let globalConfig = this.jsBridgeService.kopConfig()?.global;
        if (globalConfig != undefined) {
          this.editValue = {
            visuSercoVisie: globalConfig.visuSercoVisie,
            visuGrafieken: globalConfig.visuGrafieken,
            visuExportModule: globalConfig.visuExportModule,
            visuSercoView: globalConfig.visuSercoView,
            visuSMSModule: globalConfig.visuSMSModule,
            visuPeriodeOverzicht: globalConfig.visuPeriodeOverzicht,
            visuSnelleGrafieken: globalConfig.visuSnelleGrafieken,
            visuSercoDec: globalConfig.visuSercoDec,

            klimMeerDaagseTempInt: globalConfig.klimMeerDaagseTempInt,
            klimMTIWeerServer: globalConfig.klimMTIWeerServer,
            klimGasPeakShaven: globalConfig.klimGasPeakShaven,
            klimNuonC2: globalConfig.klimNuonC2,
            klimSercoLink: globalConfig.klimSercoLink,
            klimUitgebrCO2Prg: globalConfig.klimUitgebrCO2Prg,
            klimGewasTempRegelPrg: globalConfig.klimGewasTempRegelPrg,
            klimSerCoolVKassen: globalConfig.klimSerCoolVKassen,
            klimUitgebrSchermPrg: globalConfig.klimUitgebrSchermPrg,
            klimKoppelingSDF: globalConfig.klimKoppelingSDF,
            klimRecepten: globalConfig.klimRecepten,
            klimFlexRecepten: globalConfig.klimFlexRecepten,

            geoBreedte: globalConfig.geoBreedte,
            geoLengte: globalConfig.geoLengte,
            geoTijdzone: globalConfig.geoTijdzone,
            rackxtender: globalConfig.rackXtender,
            fastlinkxtender: globalConfig.fastlinkXtender,
            sercoLinkStationId: globalConfig.sercoLinkStationId,

            noEuroSummerTime: globalConfig.noEuroSummerTime,
            printInstChangeAbroad: globalConfig.printInstChangeAbroad

            
          }
        }
      }
    })

  }
  public isEditing = input<boolean>(false);
  public onDone = output<boolean>();
  public editValue: GlobalConfigExtra | undefined;

  onSave() {
    console.log(this.editValue);
    let wasm = this.jsBridgeService.wasmModule!;
    this.onDone.emit(true);
    this.jsBridgeService.kopConfig.update(kfg => {
      if (kfg != undefined) {
        kfg.global.visuSercoVisie = this.editValue!.visuSercoVisie;
        kfg.global.visuGrafieken = this.editValue!.visuGrafieken;
        kfg.global.visuExportModule = this.editValue!.visuExportModule;
        kfg.global.visuSercoView = this.editValue!.visuSercoView;
        kfg.global.visuSMSModule = this.editValue!.visuSMSModule;
        kfg.global.visuPeriodeOverzicht = this.editValue!.visuPeriodeOverzicht;
        kfg.global.visuSnelleGrafieken = this.editValue!.visuSnelleGrafieken;
        kfg.global.visuSercoDec = this.editValue!.visuSercoDec;

        kfg.global.klimMeerDaagseTempInt = this.editValue!.klimMeerDaagseTempInt;
        kfg.global.klimMTIWeerServer = this.editValue!.klimMTIWeerServer;
        kfg.global.klimGasPeakShaven = this.editValue!.klimGasPeakShaven;
        kfg.global.klimNuonC2 = this.editValue!.klimNuonC2;
        kfg.global.klimSercoLink = this.editValue!.klimSercoLink;
        kfg.global.klimUitgebrCO2Prg = this.editValue!.klimUitgebrCO2Prg;
        kfg.global.klimGewasTempRegelPrg = this.editValue!.klimGewasTempRegelPrg;
        kfg.global.klimSerCoolVKassen = this.editValue!.klimSerCoolVKassen;
        kfg.global.klimUitgebrSchermPrg = this.editValue!.klimUitgebrSchermPrg;
        kfg.global.klimKoppelingSDF = this.editValue!.klimKoppelingSDF;
        kfg.global.klimRecepten = this.editValue!.klimRecepten;
        kfg.global.klimFlexRecepten = this.editValue!.klimFlexRecepten;

        kfg.global.geoBreedte = this.editValue!.geoBreedte;
        kfg.global.geoLengte = this.editValue!.geoLengte;
        kfg.global.geoTijdzone = this.editValue!.geoTijdzone;
        kfg.global.rackXtender = this.editValue!.rackxtender;
        kfg.global.fastlinkXtender = this.editValue!.fastlinkxtender;
        kfg.global.sercoLinkStationId = this.editValue!.sercoLinkStationId;
        kfg.global.noEuroSummerTime = this.editValue!.noEuroSummerTime;
        kfg.global.printInstChangeAbroad = this.editValue!.printInstChangeAbroad;
        
      } return kfg;
    });
  }
}
