<kendo-tabstrip [animate]="false">
  <kendo-tabstrip-tab title="Analog In" [selected]="true">
    <ng-template kendoTabContent>
      <kanaal-selector [kanaalType]="this.ai" [assign]="this.assign()"></kanaal-selector>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Analog Uit">
    <ng-template kendoTabContent>
      <kanaal-selector [kanaalType]="this.ao" [assign]="this.assign()"></kanaal-selector>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Digitale In">
    <ng-template kendoTabContent>
      <kanaal-selector [kanaalType]="this.di" [assign]="this.assign()"></kanaal-selector>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Digitale Uit">
    <ng-template kendoTabContent>
      <kanaal-selector [kanaalType]="this.do" [assign]="this.assign()"></kanaal-selector>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>