import { Component, computed } from '@angular/core';
import { ModulesRepoService } from '../modules-repo.service';
import { KopConfigService } from '../../core/kop-config.service';
import { AfdModuleMeta } from '@etron/typings';

@Component({
  selector: 'module-bar',
  templateUrl: './module-bar.component.html',
  styleUrl: './module-bar.component.scss'
})
export class ModuleBarComponent {

  constructor(public moduleRepo: ModulesRepoService, public kopConfigService: KopConfigService) { }


  public modules = computed(() => {
    let type = this.kopConfigService.selectedAfd()?.afdeling()?.type;
    if (type == undefined) return [];
    return this.moduleRepo.getModules(type);
  });

  public slots = computed(() => {
    let type = this.kopConfigService.selectedAfd()?.afdeling()?.type;
    if (type == undefined) return [];
    return this.moduleRepo.getSlots(type);
  });
  

  onDragStart($event: DragEvent, module: AfdModuleMeta) {
    console.log($event);
    if ($event.dataTransfer != null) {
      $event.dataTransfer.setData('module', module.key);
    }
  }

}
