<!-- <button kendoButton (click)="this.diffXML()">Compare</button> -->
@if (this.isEditing()) {
    <kendo-dialog title="Diff" width="1500px" (close)="this.onDone.emit(true)">
        <div class="monaco">
            <ngx-monaco-diff-editor style="height: 100%" [options]="options" [originalModel]="originalModel" [modifiedModel]="modifiedModel"></ngx-monaco-diff-editor>
        </div>
        
        <kendo-dialog-actions>
            <button kendoButton (click)="this.onDone.emit(true)">Close</button>
        </kendo-dialog-actions>
    </kendo-dialog>
    
    
}