<kendo-dialog *ngIf="isEditing()" title="Module: {{this.moduleMeta()?.name}}" width="750px"
    (close)="this.cancel.emit()">
    <div class="module-props-wrapper">
        <div class="category-header" *ngIf="this.compoundValue.ais.length != 0">Analoge Ingangen</div>
        @for (ai of this.compoundValue.ais; track ai.index) {
            <aieditor [initialValue]="ai.ai" [editOnly]="true" [metaIdx]="ai.ai == undefined ? ai.index : undefined"
            (valueChanged)="onAIChange($event)" />
            @let errMsg = this.getErrMessage(ai.index, 'analog');
            @if (!errMsg.isValid) {
                <pre class="error-message">{{errMsg.errMsg}}</pre>
            }
        }
        <div class="category-header" *ngIf="this.compoundValue.losDefs.length != 0">Losse Definitie</div>
        @for (ld of this.compoundValue.losDefs; track ld.index) {
            <los-def-editor  [editOnly]="true" [ldType]="'losdef'" [initialValue]="ld.losDef"
            (valueChanged)="onLDChange($event, 'losdef')" [class.led-even]="$even" [class.led-odd]="$odd" />
            @let errMsg = this.getErrMessage(ld.index, 'losdef');
            @if (!errMsg.isValid) {
                <pre class="error-message">{{errMsg.errMsg}}</pre>
            }
        }
        <div class="category-header" *ngIf="this.compoundValue.droogWands.length != 0">Droogwanden</div>
        @for (ld of this.compoundValue.droogWands; track ld.index) {
            <los-def-editor  [editOnly]="true" [ldType]="'droogwand'" [initialValue]="ld.losDef"
                (valueChanged)="onLDChange($event, 'droogwand')" [class.led-even]="$even" [class.led-odd]="$odd" />
            @let errMsg = this.getErrMessage(ld.index, 'losdef');
            @if (!errMsg.isValid) {
                <pre class="error-message">{{errMsg.errMsg}}</pre>
            }
    
        }
        <div class="category-header" *ngIf="this.compoundValue.koelings.length != 0">Koeling</div>
        @for (ld of this.compoundValue.koelings; track ld.index) {
            <los-def-editor  [editOnly]="true" [ldType]="'koeling'" [initialValue]="ld.losDef"
            (valueChanged)="onLDChange($event, 'koeling')" [class.led-even]="$even" [class.led-odd]="$odd" />
            @let errMsg = this.getErrMessage(ld.index, 'losdef');
            @if (!errMsg.isValid) {
                <pre class="error-message">{{errMsg.errMsg}}</pre>
            }
        }
        <div class="category-header" *ngIf="this.compoundValue.verwarmings.length != 0">Verwarming</div>
        @for (ld of this.compoundValue.verwarmings; track ld.index) {
            <los-def-editor  [editOnly]="true" [ldType]="'verwarming'" [initialValue]="ld.losDef"
            (valueChanged)="onLDChange($event, 'verwarming')" [class.led-even]="$even" [class.led-odd]="$odd" />
            @let errMsg = this.getErrMessage(ld.index, 'losdef');
            @if (!errMsg.isValid) {
                <pre class="error-message">{{errMsg.errMsg}}</pre>
            }
        }
        <div class="category-header" *ngIf="this.compoundValue.servos.length != 0">Servos</div>
        @for (servo of this.compoundValue.servos; track servo.index) {
            <servo-editor [initialValue]="servo.servo" [metaIdx]="servo.servo == undefined ? servo.index : undefined"
            [class.led-even]="$even" [class.led-odd]="$odd"
            [editOnly]="true" (valueChanged)="onServoChange($event)" />
            @let errMsg = this.getErrMessage(servo.index, 'servo');
            @if (!errMsg.isValid) {
                <pre class="error-message">{{errMsg.errMsg}}</pre>
            }
        }
        <kendo-dialog-actions>
            <button kendoButton (click)="this.cancel.emit()">Cancel</button>
            <button kendoButton [disabled]="!this.isValid" (click)="this.apply.emit(this.compoundValue)" themeColor="primary">
                Save
            </button>
        </kendo-dialog-actions>
    </div>
</kendo-dialog>