import { AnalogIn, AnalogOut, DigitalIn, DigitalOut } from "../../props/PropTypes"
import { CombiKaartType, HdwRackType, KaartType, MainboardType, SimpleKaartType, SysteemKaartType } from "./hdw-konfig-types"

export const SCX: MainboardType = {
    label: "SCX",
    busTypes: ['scx'],
    racks: [],
    karten: []
}

export const SC800: MainboardType = {
    label: "SC800",
    busTypes: ['fastlink'],
    racks: [{
        bus: "fastlink",
        slots: 16,
        label: "Lint",
        karten: [
            {
                label: "XTender",
                bus: "fastlink",
                isExtension: true,
                takesRackSpace: false
            },    
        ]
    }],
    karten: [
        {
            bus: "fastlink",
            ioType: AnalogIn,
            available: 16,
            reserved: 16,
            label: "MB AI",
            takesRackSpace: false
        },
        {
            bus: "fastlink",
            ioType: DigitalOut,
            available: 16,
            reserved: 16,
            label: "MB DO",
            takesRackSpace: false
        },
        {
            bus: "fastlink",
            ioType: DigitalIn,
            available: 8,
            reserved: 8,
            label: "MB DI",
            takesRackSpace: false
        }]
}

export const SCXRack: HdwRackType = {
    bus: 'scx',
    slots: 10,
    label: 'Rack',
    karten: []
}

export const FastLinkRack: HdwRackType = {
    bus: 'fastlink',
    slots: 16,
    label: 'Lint',
    karten: [
        {
            label: "XTender",
            bus: "fastlink",
            isExtension: true,
            takesRackSpace: true
        }
    ]
}

export const SCXAIKart: SimpleKaartType = {
    available: 32,
    bus: "scx",
    ioType: AnalogIn,
    label: "AI",
    reserved: 32,
    takesRackSpace: true
}

export const SCXAOKart: SimpleKaartType = {
    available: 32,
    bus: "scx",
    ioType: AnalogOut,
    label: "AO",
    reserved: 32,
    takesRackSpace: true
}

export const SCXDIKart: SimpleKaartType = {
    available: 32,
    bus: "scx",
    ioType: DigitalIn,
    label: "DI",
    reserved: 32,
    takesRackSpace: true
}

export const SCXDOKart: SimpleKaartType = {
    available: 32,
    bus: "scx",
    ioType: DigitalOut,
    label: "DO",
    reserved: 32,
    takesRackSpace: true
}


export const FastLinkAIKart: SimpleKaartType = {
    available: 16,
    bus: "fastlink",
    ioType: AnalogIn,
    label: "Fastlink AI",
    reserved: 16,
    takesRackSpace: true
}

export const FastlinkAOKart: SimpleKaartType = {
    available: 8,
    bus: "fastlink",
    ioType: AnalogOut,
    label: "Fastlink AO",
    reserved: 8,
    takesRackSpace: true
}

export const FastlinkDIKart: SimpleKaartType = {
    available: 16,
    bus: "fastlink",
    ioType: DigitalIn,
    label: "Fastlink DI",
    reserved: 16,
    takesRackSpace: true
}

export const FastlinkDOKart: SimpleKaartType = {
    available: 16,
    bus: "fastlink",
    ioType: DigitalOut,
    label: "Fastlink DO",
    reserved: 16,
    takesRackSpace: true
}

export const FastLinkCombi: CombiKaartType = {
    bus: "fastlink",
    karten: [{
        ioType: AnalogIn,
        available: 4,
        reserved: 16,
        bus: "fastlink",
        label: "Combi AI",
        takesRackSpace: false
    }, {
        ioType: DigitalIn,
        available: 2,
        bus: "fastlink",
        reserved: 8,
        label: "Combi DI",
        takesRackSpace: false
    }, {
        ioType: DigitalOut,
        available: 8,
        bus: "fastlink",
        label: "Combi DO",
        reserved: 8,
        takesRackSpace: false
    }],
    label: "Fastlink Combi",
    takesRackSpace: true
}

export const AVAkart: SysteemKaartType = {
    label: "AVA",
    bus: "scx",
    isExtension: false,
    takesRackSpace: true
}

export const RackXTender: SysteemKaartType = {
    label: "XTender",
    bus: "scx",
    isExtension: true,
    takesRackSpace: true
}

export const FaskLinkXTender: SysteemKaartType = {
    label: "XTender",
    bus: "fastlink",
    isExtension: true,
    takesRackSpace: true
}

export class HdwMeta {
    public mainBoards = [SCX, SC800];
    public racks = [SCXRack, FastLinkRack];
    public kaarten = [SCXAIKart, SCXAOKart, SCXDIKart, SCXDOKart, FastLinkAIKart,
        FastlinkAOKart, FastlinkDIKart, FastlinkDOKart, AVAkart, RackXTender, FaskLinkXTender, FastLinkCombi];

    public getRackByBus(bus: string): HdwRackType[] {
        return this.racks.filter(r => r.bus == bus);
    }

    public getKaartenByBus(bus: string): KaartType[] {
        return this.kaarten.filter(k => k.bus == bus);
    }
}
