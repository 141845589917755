<button kendoButton size="large" style="width: 100%;" (click)="this.isEditing = true">Servo</button>
<kendo-dialog *ngIf="isEditing" title="Servos" width="550px" (close)="this.isEditing = false">
    <div class="wrapper">
        @for (servo of propsService.servos(); track servo.index) {
            <servo-editor [initialValue]="servo" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
        }
        <div class="splitter"></div>
        @for (servo of availableServos(); track servo.index) {
            <servo-editor [initialValue]="servo" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" [onlyDesc]="true" />
        }
    </div>
</kendo-dialog>