import { Component, computed, input } from '@angular/core';
import { AnalogIn, DigitalIn, IOType, RangedKanaalAssign } from '../../props/PropTypes';
import { KanaalSelectorBase } from '../kanaal-selector/KanaalSelectorBase';
import { CustomSeriesRenderItemReturn, ECharts, EChartsOption } from 'echarts';

@Component({
  selector: 'compact-kanaal-selector',
  templateUrl: './compact-selector.component.html',
  styleUrl: './compact-selector.component.scss'
})
export class CompactSelectorComponent extends KanaalSelectorBase {

  public defaultOptions = {
    tooltip: {},
  }

  public chartHeight = computed(() => {
    let chartHeight = this.maxKanaal() / 64 * 25 + 50;
    console.log(chartHeight);
    return chartHeight;
  });

  public gridLabel(h: number): string {
    let show = (h % 4 == 3) || (h == 0);
    if (show) {
      let kType = this.kanaalType();
      if (AnalogIn.equals(kType)) {
        if (h == 0) {
          return "a";
        }
        return ((h + 1) / 2).toString();
      } else {
        return (h + 1).toString();
      }
    }
    return " ";
  }


  public chartData = computed(() => {
    let data: Array<Array<number>> = [];
    let kMap = this.kanaalMap();
    for (let i = 0; i < kMap.length; i++) {
      let row = kMap[i];
      for (let j = 0; j < row.length; j++) {
        let cell = row[j];
        data.push([j, i, cell.length]);
      }
    }
    return data;
  });

  public eOptions2(selection: number | undefined, range: number) {

    const xAxisData = [];
    const yAxisData = [];

    for (let i = 0; i < 64; i++) {
      xAxisData.push(i);
    }
    for (let j = 0; j < this.maxKanaal(); j = j + 64) {
      yAxisData.push(j);
    }



    let options: EChartsOption = {
      tooltip: {},
      animation: false,
      grid: {
        left: '1%',
        right: '1%',
        bottom: '1%',
        top: '1%',
        containLabel: true
      },
      xAxis: {
        data: xAxisData,
        position: 'top',
        splitArea: {
          show: true
        },

        axisTick: {
          interval: (index: number, value: string) => {
            let show = (index % 4 == 3) || (index == 0);
            return show;
          },
          alignWithLabel: true
        },
        axisLabel: {
          formatter: (value: string, index: number) => {
            return this.gridLabel(index);
          },
          interval(index, value) {
            let show = (index % 4 == 3) || (index == 0);
            return show;
          },

        }

      },
      yAxis: {
        data: yAxisData,
        inverse: true,
        splitArea: {
          show: true
        },
        axisTick: {
          alignWithLabel: true

        },
        axisLabel: {
          formatter: (value: string, index: number) => {
            let kType = this.kanaalType();
            if (AnalogIn.equals(kType)) {
              let v = parseInt(value);
              return (v / 2).toFixed();
            } else {
              return value;
            }
          }
        }
      },
      visualMap: {
        min: 0,
        max: 2,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '15%',
        show: false,
        inRange: {
          color: ['#42f595', '#c91b0e']
        }
      },
      series: [
        {
          name: 'Inputs2',
          type: 'custom',
          itemStyle: {
            borderColor: '#000',
            borderType: 'solid',
            borderWidth: 1,
          },
          tooltip: {
            show: false
          },
          data: this.chartData(),
          renderItem: (params, api) => {
            let y = api.value(0).valueOf() as number;
            let x = api.value(1).valueOf() as number;
            let v = api.value(2).valueOf() as number;
            let start = api.coord([y, x]);
            let sz = api.size!([1, 1]) as number[];

            let selected = selection != undefined && selection <= (x * 64 + y) && (selection + range) > (x * 64 + y);
            let style: any = api.style();
            if (selected) {
              style.fill = '#0000ff';
            }
            let ret: CustomSeriesRenderItemReturn = {
              type: 'rect',
              //transition: ['shape'],
              shape: {
                x: start[0] - 0.5 * sz[0],
                y: start[1] - 0.5 * sz[1],
                width: sz[0],
                height: sz[1]
              },
              style: style,
              textContent: {
                type: 'text',
                style: {
                  text: v > 0 ? v.toString() : '',
                }
              }
            };
            return ret;
          },

        },
      ]
    };

    return options;
  };

  private echart: ECharts | undefined;
  onChartInit(chart: ECharts) {
    this.echart = chart;
    setTimeout(() => {
      let kanaal = this.initialSelection().kanaal;
      let k = undefined;
      if (kanaal != undefined) {
        k = kanaal.start - 1;
      }
      let options = this.eOptions2(k, this.range());
      chart.setOption(options);
      chart.resize();

    }, 1);
  }

  public onChartCellClick(e: any) {
    let data = e.value as Array<number>;
    let x = data[1];
    let y = data[0];
    let asgn = this.kanaalMap()[x][y];

    let kanaal = x * this.rowSize() + y + 1;
    console.log(kanaal, asgn);
    if (this.isValidSelection(kanaal)) {
      this.selection.set({
        kanaal: {
          start: kanaal,
          ioType: this.kanaalType()!,
          range: this.range()

        },
        assignement: asgn
      });
      let newOptions = this.eOptions2(kanaal - 1, this.range());
      this.echart?.clear();
      this.echart?.setOption(newOptions);
      this.onKanaalSelected.emit(this.selection());
  
    }
  }

}
