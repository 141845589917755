import { NgModule } from '@angular/core';
import { KopwebCoreComponent } from './kopweb-core.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IconModule, SVGIconModule } from '@progress/kendo-angular-icons';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { LabelModule } from '@progress/kendo-angular-label';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { AngularSplitModule } from 'angular-split';
import { NgxEchartsModule } from 'ngx-echarts';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PopoverModule, TooltipModule } from '@progress/kendo-angular-tooltip';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { KopConfigComponent } from './core/kop-config/kop-config.component';
import { AfdCardComponent } from './afdeling/afd-card/afd-card.component';
import { AfdelingListComponent } from './afdeling/afdeling-list/afdeling-list.component';
import { AfdtypePipe } from './core/afdtype.pipe';
import { AfdBasePropsComponent } from './props/afd-base-props/afd-base-props.component';
import { AIEditorComponent } from './props/aieditor/aieditor.component';
import { LosDefEditorComponent } from './props/los-def-editor/los-def-editor.component';
import { ServoEditorComponent } from './props/servo-editor/servo-editor.component';
import { ConfigEditorComponent } from './props/config-editor/config-editor.component';
import { ModuleBarComponent } from './modules/module-bar/module-bar.component';
import { ModuleCardComponent } from './modules/module-card/module-card.component';
import { ModulePropsComponent } from './props/module-props/module-props.component';
import { AlarmTextsComponent } from './props/alarm-texts/alarm-texts.component';
import { AlarmEditorComponent } from './props/alarm-editor/alarm-editor.component';
import { ValidationInfoComponent } from './modules/validation-info/validation-info.component';
import { AppBarComponent } from './core/app-bar/app-bar.component';
import { GlobalAlarmTextsComponent } from './props/global-alarm-texts/global-alarm-texts.component';
import { BijzPrgmsEditorComponent } from './props/bijz-prgms-editor/bijz-prgms-editor.component';
import { UurenTekstenComponent } from './props/uuren-teksten/uuren-teksten.component';
import { UrenEditorComponent } from './props/uren-editor/uren-editor.component';
import { GlobalUrenTekstenComponent } from './props/global-uren-teksten/global-uren-teksten.component';
import { GlobalConfigComponent } from './props/global-config/global-config.component';
import { GlobalConfigExtraComponent } from './props/global-config-extra/global-config-extra.component';
import { ModbusNetworkComponent } from './props/modbus-network/modbus-network.component';
import { KanaalSelectorComponent } from './kanaal/kanaal-selector/kanaal-selector.component';
import { KanaalOverviewComponent } from './kanaal/kanaal-overview/kanaal-overview.component';
import { MetaSelectButtonComponent } from './props/meta-select-button/meta-select-button.component';
import { AssetIconComponent } from './core/asset-icon/asset-icon.component';
import { SlotGroupComponent } from './modules/slot-group/slot-group.component';
import { KanaalInputComponent } from './kanaal/kanaal-input/kanaal-input.component';
import { InfoComponent } from './props/info/info.component';
import { KlassiekPropsComponent } from './props/klassiek-props/klassiek-props.component';

import { FormsModule } from '@angular/forms';
import { KlassiekLosDefComponent } from './props/klassiek/klassiek-los-def/klassiek-los-def.component';
import { KlassiekAiComponent } from './props/klassiek/klassiek-ai/klassiek-ai.component';
import { KlassiekAlarmsComponent } from './props/klassiek/klassiek-alarms/klassiek-alarms.component';
import { KlassiekServoComponent } from './props/klassiek/klassiek-servo/klassiek-servo.component';
import { KlassiekBijzPrgmsComponent } from './props/klassiek/klassiek-bijz-prgms/klassiek-bijz-prgms.component';
import { KlassiekUrenComponent } from './props/klassiek/klassiek-uren/klassiek-uren.component';
import { KanaalOverviewDialogComponent } from './core/kanaal-overview-dialog/kanaal-overview-dialog.component';
import { KanaalWrapperComponent, KopWrapperComponent } from '../public-api';
import { KanaalAssignementComponent } from './kanaal/kanaal-assignement/kanaal-assignement.component';
import { XMLCompareComponent } from './debug/xmlcompare/xmlcompare.component';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { SystemDefComponent } from './kanaal/hdw/system-def/system-def.component';
import { RackViewComponent } from './kanaal/hdw/rack-view/rack-view.component';
import { NumberPopupComponent } from './kanaal/hdw/number-popup/number-popup.component';
import { KartViewComponent } from './kanaal/hdw/kart-view/kart-view.component';
import { IOViewComponent } from './kanaal/hdw/ioview/ioview.component';
import { CompactSelectorComponent } from './kanaal/compact-selector/compact-selector.component';
import { DetailedSelectorComponent } from './kanaal/detailed-selector/detailed-selector.component';
import { HdwvSelectorComponent } from './kanaal/hdwv-selector/hdwv-selector.component';
import { HdwIoSelectorComponent } from './kanaal/hdw-io-selector/hdw-io-selector.component';



@NgModule({
  declarations: [
    KopwebCoreComponent, KopConfigComponent,AfdelingListComponent, AfdCardComponent, AfdtypePipe, AfdBasePropsComponent, AIEditorComponent, LosDefEditorComponent, ServoEditorComponent, ConfigEditorComponent, ModuleBarComponent, ModuleCardComponent, ModulePropsComponent, AlarmTextsComponent, AlarmEditorComponent, ValidationInfoComponent, AppBarComponent, GlobalAlarmTextsComponent, BijzPrgmsEditorComponent, UurenTekstenComponent, UrenEditorComponent, GlobalUrenTekstenComponent, GlobalConfigComponent, GlobalConfigExtraComponent, ModbusNetworkComponent, KanaalSelectorComponent, KanaalOverviewComponent, MetaSelectButtonComponent, AssetIconComponent, SlotGroupComponent, KanaalInputComponent, InfoComponent, KlassiekPropsComponent, KlassiekLosDefComponent, KlassiekAiComponent, KlassiekAlarmsComponent, KlassiekServoComponent, KlassiekBijzPrgmsComponent, KlassiekUrenComponent, KanaalOverviewDialogComponent, KopWrapperComponent, KanaalWrapperComponent, KanaalAssignementComponent, XMLCompareComponent, SystemDefComponent, RackViewComponent, NumberPopupComponent, KartViewComponent, IOViewComponent, CompactSelectorComponent, DetailedSelectorComponent, HdwvSelectorComponent, HdwIoSelectorComponent
  ],
  imports: [
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    ButtonsModule,
    BrowserAnimationsModule,
    LayoutModule,
    InputsModule,
    ListViewModule,
    LabelModule,
    PopupModule,
    DialogsModule,
    DropDownsModule,
    IconModule,
    SVGIconModule,
    NavigationModule,
    AngularSplitModule,
    TooltipModule,
    PopoverModule,
    MarkdownModule,
    IndicatorsModule,
    FormsModule,
    MonacoEditorModule.forRoot()
  ],
  providers: [provideMarkdown()],
  exports: [
    KopwebCoreComponent, KopConfigComponent, KanaalOverviewComponent, KopWrapperComponent, KanaalWrapperComponent,
    ButtonsModule,
    BrowserAnimationsModule,
    LayoutModule,
    InputsModule,
    ListViewModule,
    LabelModule,
    PopupModule,
    DialogsModule,
    DropDownsModule,
    IconModule,
    SVGIconModule,
    NavigationModule,
    AngularSplitModule,
    TooltipModule,
    PopoverModule,
    MarkdownModule,
    IndicatorsModule,
    FormsModule,
  ]
})
export class KopwebCoreModule { }
