import { Component, computed, effect, input, output, signal } from '@angular/core';
import { AnalogIn, DigitalIn, DigitalOut, IOType, RangedKanaal, RangedKanaalAssign, RangedKanaalInput } from '../../props/PropTypes';
import { JSBridgeService } from '../../core/jsbridge.service';
import { KanaalService } from '../../kanaal/kanaal.service';
import { KanaalSelection } from '../kanaal-selector/KanaalSelectorBase';

@Component({
  selector: 'kanaal-input',
  templateUrl: './kanaal-input.component.html',
  styleUrl: './kanaal-input.component.scss'
})
export class KanaalInputComponent {

  constructor(public jsBridgeService: JSBridgeService, public kanaalService: KanaalService) {
    effect(() => {
      let initValue = this.initialValue();
      this.ioType = initValue.ioType;
      this.range = initValue.range;
      this.inverted = initValue.inverted == true;
      if (AnalogIn.equals(this.ioType)) {
        this.start = initValue.start >> 1;
        this.isA = (initValue.start & 1) == 1;
      } else {
        this.start = initValue.start;
      }
      this.kanaalSelection.kanaal = {
        start: initValue.start,
        ioType: initValue.ioType,
        range: initValue.range
      };
    });

  }

  public initialValue = input.required<RangedKanaalInput>();

  public assign =  computed(() => {
    let kfg = this.jsBridgeService.getBridgeSync().getModuleConfig();
    let assignement = this.kanaalService.getKonfigKanalen(kfg);
    return assignement;    
  });



  public valueChanged = output<RangedKanaalInput>();
  public isA: boolean = false;
  public start: number = 0;
  public range: number = 1;
  public inverted: boolean = false;
  public ioType: IOType = AnalogIn;

  public showSelector: boolean = false;

  hasAKanaal = computed(() => {
    return (AnalogIn.equals(this.initialValue().ioType)) && (this.initialValue().range == 1);
  });

  hasInverted = computed(() => {
    return DigitalIn.equals(this.initialValue().ioType) || DigitalOut.equals(this.initialValue().ioType);
  });

  onKanaalChange(kanaal: number) {
    this.start = kanaal;
    let ret = this.toOutput();

    if (AnalogIn.equals(this.ioType)) {
      ret.start = this.start * 2 + (this.isA ? 1 : 0);
    } else {
      ret.start = this.start;
    }
    this.kanaalSelection.kanaal = ret;
    this.valueChanged.emit(ret);
  }

  onAKanaalChange(isA: boolean | string) {
    this.isA = isA ? true : false;
    let ret = this.toOutput();
    ret.start = this.start * 2 + (this.isA ? 1 : 0);
    this.kanaalSelection.kanaal = ret;
    this.valueChanged.emit(ret);
  }

  private toOutput(): RangedKanaalInput {
    let ret: RangedKanaalInput = {
      ioType: this.ioType,
      range: this.range,
      start: this.start,
      inverted: this.inverted
    };
    return ret;
  }

  onInvertedChange(inverted: boolean | string) {  
    this.inverted = inverted ? true : false;
    let ret = this.toOutput();
    this.valueChanged.emit(ret);
  }

  public kanaalSelection: KanaalSelection = {}

  onKanaalSelected(e: KanaalSelection) {
    this.kanaalSelection = e;
  }

  onOK() {
    if (this.kanaalSelection.kanaal != undefined) {
      let ret = this.toOutput();
      ret.start = this.kanaalSelection.kanaal.start;
      this.valueChanged.emit(ret);
    }
    this.showSelector = false;
  }

}
