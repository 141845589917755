{
  "domain": "dev-vortex.eu.auth0.com",
  "clientId": "DOr1w7SSFOwwyQyYz9x55opNZFGCUtTz",
  "authorizationParams": {
    "audience": "https://dev-vortex.eu.auth0.com/api/v2/"
  },
  "apiUri": "http://localhost:3001",
  "appUri": "http://localhost:4200",
  "errorPath": "/error"
}
