import { BusType, HdwRackType } from "./hdw-konfig-types";
import { HwdSysteem, Rack } from "./HdwSysteem";

export class BusManagerBase {
    constructor(systeem: HwdSysteem, busType: BusType) {
        this.systeem = systeem;
        this.bustype = busType;
    }
    protected systeem: HwdSysteem;
    protected bustype: BusType;

    public getRack(rackId: number) {
        let racks = this.getRacks();
        let ret = racks.find(r => r.id == rackId);
        return ret;
    }

    deleteKaart(id: number): void {
        for (let r of this.getRacks()) {
            let kaartIdx = r.kaarten.findIndex(k => k.id == id);
            if (kaartIdx != -1) {
                r.kaarten.splice(kaartIdx, 1);
                return;
            }
        }
    }

    public getRacks() {
        let racks = this.systeem.getRacks(this.bustype);
        return racks;
    }

    public addRack(rackType: HdwRackType): number {
        let id = this.systeem.getNextRackId();
        let rack = new Rack(rackType, id);
        let racks = this.getRacks();
        if (racks != undefined) {
            racks.push(rack);
        }

        return id;
    }
}