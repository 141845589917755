import { Component, computed, input } from '@angular/core';
import { HwdConfigService } from '../../hwd-config.service';
import { Rack } from '../../hdw-config/HdwSysteem';
import { isSysteemKaart, KaartType } from '../../hdw-config/hdw-konfig-types';

@Component({
  selector: 'rack-view',
  templateUrl: './rack-view.component.html',
  styleUrl: './rack-view.component.scss'
})
export class RackViewComponent {

  constructor(public hdwMetaService: HwdConfigService) { }

  public rack = input.required<Rack>()

  public slotKaarten() {
    return this.rack().kaarten.filter(k => k.type.takesRackSpace);
  };

  async onKaartAdded(e: KaartType) {
    let nr = this.rack().getNextNumber();
    // if (isSysteemKaart(e)) {
    //   let nr2 = await this.hdwMetaService.chooseNumber();
    //   if (nr2 == undefined) {
    //     return;
    //   }
    //   nr = nr2;
    // }
    this.hdwMetaService.system.update(system => {
      if ((nr != undefined) && (system != undefined)) {
        system.addKaart(e, nr, this.rack().id);
      }
      return system;
    });
  }

}
