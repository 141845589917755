@let meta = afdModule()?.meta!;  

<kendo-card width="260px" (click)="onClick()" class="clickable" [class.selected]="selected()">
    <kendo-card-header class="header">
        <div class="header-row">
            <p>Titel: {{meta.name}}</p>
            <button kendoButton (click)="removeDialogOpen = true">Del</button>
            <button kendoButton (click)="onEdit()">Edit</button>
        </div>
    </kendo-card-header>
    <kendo-card-body>
        <validation-info [validation]="validation()" />
    </kendo-card-body>
</kendo-card>
<kendo-dialog *ngIf="removeDialogOpen" title="Remove Module" width="300px" (close)="removeDialogOpen = false">
    <p>Are you sure you want to delete {{meta.name}}?</p>
    <kendo-dialog-actions>
        <button kendoButton (click)="removeDialogOpen = false">Cancel</button>
        <button kendoButton (click)="onRemove()" themeColor="primary">
            Remove
        </button>
    </kendo-dialog-actions>
</kendo-dialog>
