@let afdWM = kopConfigService.selectedAfd();
@let afd = kopConfigService.selectedAfd()?.afdeling();
@let isModule = this.kopModulesService.selectedAfdModule() != undefined;

@if ((afd != undefined) && (afdWM != undefined)) {
    <config-editor />

    <kendo-expansionpanel *ngIf="propsService.hasPanelType('AI')" title="AI" (action)="onPanelClick('AI')" [expanded]="true">
        @for (ai of propsService.ais(); track ai.index) {
        <aieditor [initialValue]="ai" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
        }
        <aieditor [isNew]="true" />
    </kendo-expansionpanel>
    <kendo-expansionpanel *ngIf="propsService.hasPanelType('AlarmText') && !isModule" title="AlarmText" (action)="onPanelClick('AlarmText')" [expanded]="false">
        <alarm-texts/>
    </kendo-expansionpanel>
    <kendo-expansionpanel *ngIf="propsService.hasPanelType('Alarms') && !isModule" title="Alarms" (action)="onPanelClick('Alarms')" [expanded]="false">
        @for (al of propsService.alarms(); track al.index) {
            <alarm-editor [alarm]="al" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
        }
        <alarm-editor [isNew]="true" />
    </kendo-expansionpanel>
    <kendo-expansionpanel *ngIf="propsService.hasPanelType('LosDef')" title="LosDef" (action)="onPanelClick('LosDef')" [expanded]="true">
            @for (ld of propsService.losDefs(); track ld.index) {
                <los-def-editor [initialValue]="ld" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
            }        
        <los-def-editor [isNew]="true" />
    </kendo-expansionpanel>
    <kendo-expansionpanel *ngIf="propsService.hasPanelType('BijzPrgs') && !isModule" title="Extra Programma's" (action)="onPanelClick('BijzPrgs')" [expanded]="false">
        @for (bp of propsService.bijzPrgs(); track bp.index) {
            <bijz-prgms-editor [initialValue]="bp" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
        }
        <bijz-prgms-editor [isNew]="true" />
    </kendo-expansionpanel>
    <kendo-expansionpanel *ngIf="propsService.hasPanelType('Koeling')" title="Koeling" (action)="onPanelClick('Koeling')" [expanded]="true">
            @for (ld of propsService.koelings(); track ld.index) {
                <los-def-editor [initialValue]="ld" [ldType]="'koeling'" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over"/>
            }        
        <los-def-editor [isNew]="true" [ldType]="'koeling'" />
    </kendo-expansionpanel>
    <kendo-expansionpanel *ngIf="propsService.hasPanelType('DrogWand')" title="Droogwand" (action)="onPanelClick('DrogWand')" [expanded]="true">
            @for (ld of propsService.drogWands(); track ld.index) {
                <los-def-editor [initialValue]="ld" [ldType]="'droogwand'" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over"/>
            }        
        <los-def-editor [isNew]="true" [ldType]="'droogwand'" />
    </kendo-expansionpanel>
    <kendo-expansionpanel *ngIf="propsService.hasPanelType('Verwarming')" title="Verwarming" (action)="onPanelClick('Verwarming')" [expanded]="true">
            @for (ld of propsService.verwarmings(); track ld.index) {
                <los-def-editor [initialValue]="ld" [ldType]="'verwarming'" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
            }        
        <los-def-editor [isNew]="true" [ldType]="'verwarming'" />
    </kendo-expansionpanel>
    <kendo-expansionpanel *ngIf="propsService.hasPanelType('UrenTeksten') && !isModule" title="UrenTeksten" (action)="onPanelClick('UrenTeksten')" [expanded]="false">
        <uuren-teksten/>
    </kendo-expansionpanel>
    <kendo-expansionpanel *ngIf="propsService.hasPanelType('Uren') && !isModule" title="Uren" (action)="onPanelClick('Uren')" [expanded]="false">
        @for (uk of this.propsService.urenKeys(); track uk) {
            <uren-editor [initialValue]="this.propsService.urenModule()![uk]" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over"/>
        }
        <uren-editor [isNew]="true" />
    </kendo-expansionpanel>
    <kendo-expansionpanel *ngIf="propsService.hasPanelType('Servo')" title="Servo" (action)="onPanelClick('Servo')" [expanded]="true">
        @for (servo of propsService.servos(); track servo.index) {
            <servo-editor [initialValue]="servo" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
        }
        <servo-editor [isNew]="true" />
    </kendo-expansionpanel>
}