import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { TrpcAPIService } from './trpc-api.service';
import { ServerAPI } from 'kopweb-core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [{ provide: 'ServerApi', useClass: TrpcAPIService }]

})
export class AppComponent {
  constructor(@Inject('ServerApi') public electronAPI: ServerAPI) {
  }

  public something = "test";
  title = 'kopweb-browser';
}
