<p>Titel: {{this.kopConfigService.selectedAfd()?.afdeling()?.titel}}</p>
@let extras = this.extraConfigsRO();
<p *ngIf="extras.length > 0">Extra's:</p>
@for (extra of extras; track extra.indexnr) {
<p>{{extra.omschr}}</p>
}
<button kendoButton (click)="onEdit()">Edit</button>
<kendo-dialog *ngIf="isEditing" title="Edit Configuratie" width="300px" (close)="this.isEditing = false">

    <kendo-label text="Titel">
        <kendo-textbox [value]="this.editValue().titel" (valueChange)="onTitleChange($event)"></kendo-textbox>
    </kendo-label>

    @if (extras.length > 0) {
        <fieldset>
            <legend :class="legend">Extra's</legend>
        @let groups = this.extraConfigsKW();
        @for (g of groups; track g[0]) {
            @let group = g[1];
            @if (group.configs.length == 1) {
                    <kendo-checkbox #meta{{g[0]}} 
                        [checkedState]="group.configs[0].waarde == group.selected"
                        (checkedStateChange)="onCheckboxChange($event, g[0])"></kendo-checkbox>
                    <kendo-label [for]="'meta' + g[0]" text="{{group.configs[0].omschr}}"></kendo-label>
            } @else {
                <div class="radio-row">
                @for (c of group.configs; track c.value) {
                    <div>
                    <input type="radio" kendoRadioButton [value]="c.waarde" 
                        [name]="'meta' + g[0]"
                        #meta{{g[0]}}v{{c.value}}
                        [checked]="c.waarde == group.selected" (change)="onRadioChange($event, g[0])"/>
                        <kendo-label [for]="'meta' + g[0] + 'v'+c.value" text="{{c.omschr}}"></kendo-label>
                    </div>
    
                }
                </div>
            }
        }
        </fieldset>
            
    }
    <kendo-dialog-actions>
        <button kendoButton (click)="this.isEditing = false">Cancel</button>
        <button kendoButton (click)="onSave()" themeColor="primary">
            Save
        </button>
    </kendo-dialog-actions>
</kendo-dialog>