import { Injectable } from '@angular/core';
import { analogeMeting, cAfdelingInfo, cAfdelingWM, ConfigProps, Losdef, sBijzPrg, Servo } from '@wasm/KopWeb';
import { JSBridgeService } from './jsbridge.service';
import { wasmVectorToArray } from '../utils/wasmVector';




export type AfdMeta = {
  ais: analogeMeting[];
  servos: Servo[];
  losDefs: Losdef[];
  extraConfigs: ConfigProps[];
  afdelingInfos: cAfdelingInfo[];
  drogWands: Losdef[];
  koelings: Losdef[];
  verwarmings: Losdef[];
  bijzPrgs: sBijzPrg[];
  losDefRaw: Losdef[];
};

@Injectable({
  providedIn: null
})
export class MetaService {

  constructor(private jsBridgeService: JSBridgeService) { }

  private cache: Record<string, AfdMeta> = {};

  afdelingMeta(afd: cAfdelingWM | undefined) {
    if (afd != undefined) {
      let id = afd.afdeling().type.value.toString();
      if (this.cache[id] != undefined) {
        return this.cache[id];
      }
      let jsBridge = this.jsBridgeService.getBridgeSync();
      let allAIS = jsBridge.getAnalogeIngagen(afd.allAnalogeIngagen);
      let allServos = jsBridge.getServos(afd.allServos);
      let allLosDefs = jsBridge.getLosDefinitions(afd.allLosDefIndexes);
      let allDrogWands = jsBridge.getLosDefinitions(afd.allDroogWandIndexes);
      let allKoeling = jsBridge.getLosDefinitions(afd.allKoelingIndexes);
      let allVerwarming = jsBridge.getLosDefinitions(afd.allVerwarmingIndexes);

      let extraConfigs = jsBridge.getExtraConfigs(afd.afdeling().type.value);
      let afdInfo = jsBridge.getAfdelingsInfo();

      let allBijzPrgsIndxes = jsBridge.getBijzPrgsIndexes(afd.afdeling().type.value);
      let allBijzPrgs = jsBridge.getBijzPrgs(allBijzPrgsIndxes);
      let allLD = wasmVectorToArray(allLosDefs).sort((a, b) => a.volgorde - b.volgorde);
      let allDW = wasmVectorToArray(allDrogWands).sort((a, b) => a.volgorde - b.volgorde);
      let allK = wasmVectorToArray(allKoeling).sort((a, b) => a.volgorde - b.volgorde);
      let allV = wasmVectorToArray(allVerwarming).sort((a, b) => a.volgorde - b.volgorde);
      

      let ret =  {
        ais: wasmVectorToArray(allAIS),
        servos: wasmVectorToArray(allServos),
        losDefs: allLD,
        extraConfigs: wasmVectorToArray(extraConfigs),
        afdelingInfos: wasmVectorToArray(afdInfo),
        drogWands: allDW,
        koelings: allK,
        verwarmings: allV,
        bijzPrgs: wasmVectorToArray(allBijzPrgs).sort((a, b) => a.no - b.no),
        losDefRaw: allLD.concat(allDW).concat(allK).concat(allV)
      }
      allLosDefs.delete();
      allDrogWands.delete();
      allKoeling.delete();
      allVerwarming.delete();
      allAIS.delete();
      allServos.delete();
      allBijzPrgs.delete();
      afdInfo.delete();

      this.cache[id] = ret;

      return ret;
    }
    return {
      ais: [],
      servos: [],
      losDefs: [],
      extraConfigs: [],
      afdelingInfos: [],
      drogWands: [],
      koelings: [],
      verwarmings: [],
      bijzPrgs: [],
      losDefRaw: []
    }
  }
}
