import { ApplicationRef, EnvironmentInjector, Injectable, signal } from '@angular/core';
import { HdwMeta } from './hdw-config/hdw-meta';
import { HwdSysteem } from './hdw-config/HdwSysteem';
import { MainboardType } from './hdw-config/hdw-konfig-types';
import { NumberPopupComponent } from './hdw/number-popup/number-popup.component';
import {createCustomElement, NgElement, WithProperties} from '@angular/elements';

@Injectable({
  providedIn: 'root'
})
export class HwdConfigService {

  constructor(
    private injector: EnvironmentInjector,
    private applicationRef: ApplicationRef,
  ) {
    const PopupElement = createCustomElement(NumberPopupComponent, {injector});
    // Register the custom element with the browser.
    customElements.define('popup-element', PopupElement);
  }


  private _meta: HdwMeta = new HdwMeta();

  public meta(): HdwMeta {
    return this._meta;
  }

  public system = signal<HwdSysteem | undefined>(undefined, { equal: (a, b) => false });


  public getMainboards(): MainboardType[] {
    return this._meta.mainBoards;
  }

  public chooseNumber() {
    let p = new Promise<number | undefined>((resolve, reject) => {
      const popupEl: NgElement & WithProperties<NumberPopupComponent> = document.createElement(
        'popup-element',
      ) as any;
      // Listen to the close event
      popupEl.addEventListener('closed', () => {
        document.body.removeChild(popupEl);
        resolve(undefined);
      });
      popupEl.addEventListener('save', (e) => {
        console.log(e);
        document.body.removeChild(popupEl);
        resolve((e as CustomEvent).detail as number);
      });
      // Set the message
      // Add to the DOM
      document.body.appendChild(popupEl);
        
    });

    return p;
  }


}
