import { computed, Inject, Injectable, signal } from '@angular/core';
import { JSBridgeService } from '../core/jsbridge.service';
import { AfdelingWM, AfdModule } from '@etron/typings';
import { cAfdelingWM } from '@wasm/KopWeb';
import { ModulesRepoService } from './modules-repo.service';
import { KopConfigService } from '../core/kop-config.service';
import { ServerAPI } from '../serverapi';
import { HwdConfigService } from '../kanaal/hwd-config.service';
import { HwdSerializable, HwdSysteem } from '../kanaal/hdw-config/HdwSysteem';

@Injectable({
  providedIn: null
})
export class KopModulesService {

  constructor(private jsBridgeService: JSBridgeService, private modulesRepo: ModulesRepoService,
    private kopConfigService: KopConfigService, public hdwConfigService: HwdConfigService, @Inject('ServerApi') private electronApi: ServerAPI) {
      this.jsBridgeService.getBridge().subscribe(bridge => {
        this.load();
      });

      if (window.electronAPI != undefined) {
        window.electronAPI.saveCallBack(() => {
          this.save();
        })          
      }
  }

  public afdelings = signal<Record<string, AfdelingWM>>({}, { equal: (a, b) => false });
  public selectedAfdModule = signal<AfdModule | undefined>(undefined, { equal: (a, b) => false });
  public isEditingModule = signal(false);

  public afdModules = computed(() => {
    let selectedAfd = this.kopConfigService.selectedAfd();
    if (selectedAfd != undefined) {
      let uuid = selectedAfd.afdeling().uuid.toString();
      let afdWM = this.afdelings()[uuid];
      if (afdWM != undefined) {
        return Object.assign([], afdWM.modules) as AfdModule[];
      }
    }
    return [];
  });

  deleteModule(module: AfdModule) {
    let uuid = this.kopConfigService.selectedAfd()?.afdeling().uuid.toString();
    if (uuid != undefined) {
      let afd = this.afdelings()[uuid];
      if (afd != undefined) {
        let idx = afd.modules.findIndex(m => m.meta.key == module.meta.key);
        if (idx >= 0) {
          afd.modules.splice(idx, 1);
          if (this.selectedAfdModule()?.meta.key == module.meta.key) {
            this.selectedAfdModule.set(undefined);
          }
          this.afdelings.update(afds => {
            return afds;
          });
        }
      }
    }
  }

  addModule(moduleKey: string, afd: cAfdelingWM) {
    let module = this.modulesRepo.getModule(moduleKey);
    let uuid = afd.afdeling().uuid.toString();
    if (module != undefined) {
      let newModule = {
        meta: module,
        titel: module.name
      };
      this.afdelings.update(afds => {
        let afdWM = afds[uuid];
        if (afdWM == undefined) {
          afdWM = {
            afdelingUUID: uuid,
            modules: []
          };
          afds[uuid] = afdWM;
        }
        afdWM.modules.push(newModule);
        return afds;
      });
      this.selectedAfdModule.set(newModule);
      return newModule;
    }

    return undefined;
  }

  load() {
    let bytesP = this.electronApi.getFile(`modules/${this.jsBridgeService.getKlantNo()}.modules.json`);
    bytesP.then(bytes => {
      if ((bytes != undefined) && (bytes.length > 0)) {
        let json: any = new TextDecoder().decode(new Uint8Array(bytes));
        let payload: any = JSON.parse(json);
        let afdelings;
        if (payload.hasOwnProperty('modules')) {
          afdelings = payload.modules as Record<string, AfdelingWM>;
          let hwConfig = payload.hardware;
          if (hwConfig != undefined) {
            this.hdwConfigService.system.set(new HwdSysteem(undefined, hwConfig as HwdSerializable));
          }
        } else {
          afdelings = payload as Record<string, AfdelingWM>;
        }
        this.afdelings.set(afdelings);
      }
    });
  }

  save() {
    let afdelings = this.afdelings();
    let hwConfig = this.hdwConfigService.system()?.toSerializable();
    let saveObj = {
      modules: afdelings,
      hardware: hwConfig
    };
    let json = JSON.stringify(saveObj, undefined, 2);
    let klantNo = this.jsBridgeService.getKlantNo();
    let bytes = new TextEncoder().encode(json);
    let p1 = this.electronApi.saveFile(`modules/${klantNo}.modules.json`, bytes);
    let p2 = this.kopConfigService.save();
    // this.selectedAfdModule.set(undefined);

    return Promise.all([p1, p2]);
  }
}
