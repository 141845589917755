import { Component, computed, effect, input, output, signal } from '@angular/core';
import { analogeMeting, cKC_Analog } from '@wasm/KopWeb';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { AnalogIn, ModuleAI, RangedKanaal } from '../PropTypes';
import { wasmVectorToArray } from '../../utils/wasmVector';
import { KopModulesService } from '../../modules/kop-modules.service';
import { KanaalService } from '../../kanaal/kanaal.service';

@Component({
  selector: 'aieditor',
  templateUrl: './aieditor.component.html',
  styleUrl: './aieditor.component.scss'
})
export class AIEditorComponent {


  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService, 
      public kopModulesService: KopModulesService, public kanaalService: KanaalService) {
    effect(() => {
      let ai = this.initialValue();
      if (ai != undefined) {
        this.editValue = ai;
        if (this.editOnly() && this.editValue.index > 0) {
          this.valueChanged.emit(this.editValue);
        }  
      }
    });
  }

  // public ai = input<cKC_Analog>();
  public initialValue = input<ModuleAI>();

  public isNew = input<boolean>(false);
  public editOnly = input<boolean>(false);
  public isEditing = false;

  public valueChanged  = output<ModuleAI>();
  private _metaIdx = signal<number | undefined>(undefined);

  public metaIdx = input<number | undefined>(undefined);

  private newMetaIdx = computed(() => {
    if (this._metaIdx() != undefined) {
      return this._metaIdx();
    }
    return this.metaIdx();
  });

  public onlyDesc = input<boolean>(false);

  public editValue: ModuleAI = {
    index: 0,
    kanaal: 0,
    range: 0,
    typeIndex: 0
  };

  public kanaalType = AnalogIn;

  public kanaal = computed(() => {
    let ai = this.initialValue();
    if (ai != undefined) {
      if (ai.kanaal != undefined && ai.typeIndex != undefined) {
        var typeKanalen = this.jsBridgeService.getAITypes().find(ai.typeIndex).kanalen;
        return this.kanaalService.rangedKanaalLabel({
          ioType: AnalogIn,
          range: typeKanalen,
          start: ai.kanaal
        });
      }
    } else {
      return "";
    }
  });

  alreadySelectedFN() {
    let tmAIs = this.kopConfigService.selectedAfd()?.afdeling().analogs;
    if (tmAIs != undefined) {
      let ais = wasmVectorToArray(tmAIs);
      let ret = (idx: number) => {
        return ais.find(a => a.index == idx) != undefined;
      }
      return ret;
    }
    return (idx: number) => false;
  }

  labelFn() {
    let ret = (meta: any) => (meta as analogeMeting).omschrij.toString();
    return ret;
  }

  public aiOptions = computed(() => {
    if (this.isNew()) {
      let aiMetas = this.kopConfigService.currentMeta().ais;
      let tmAIs = this.kopConfigService.selectedAfd()?.afdeling().analogs;
      if (tmAIs != undefined) {
        let selectedModule = this.kopModulesService.selectedAfdModule();
        let ret = aiMetas.filter(aim => {
          if (selectedModule != undefined) {
            if (selectedModule.meta.allAnalogIngagen == undefined) {
              return false;
            }
            if (selectedModule.meta.allAnalogIngagen.indexOf(aim.index) < 0) {
              return false;
            }
          }
          return true;
        });
        return ret;
      }
    }
    return [];
  });


  public typeDesc = computed(() => {
    let ai = this.initialValue();
    if ((ai != undefined) && (ai.typeIndex != undefined)) {
      var ait = this.jsBridgeService.getAITypes().find(ai.typeIndex);
      if (ait != undefined) {
        return ait.kort;
      }
    }
    return "";
  });


  public meta = computed(() => {
    let ai = this.initialValue();
    if (ai != undefined) {
      let meta = this.kopConfigService.currentMeta();
      return meta.ais.find(m => m.index == ai.index);
    }
    let chosenMeta = this.newMetaIdx();
    if (chosenMeta != undefined) {
      let meta = this.kopConfigService.currentMeta();
      return meta.ais.find(m => m.index == chosenMeta);
    }
  });


  onNew(e: { index: number }) {
    let idx = e.index;
    this._metaIdx.set(idx);
    this.editValue = this.kopConfigService.aiKCtoModule(idx, undefined);
    this.isEditing = true;
  }

  onDelete() {
    let ai = this.initialValue();
    this.kopConfigService.selectedAfd.update(afd => {
      if ((ai != undefined) && (afd != undefined)) {
       this.kopConfigService.eraseAI(afd, ai);
      }
      return afd;
    });
  }

  onEdit() {
    this.isEditing = true;
  }

  onTypeChanged(type: number) {
    let ret = { ... this.editValue };
    let v = this.editValue;
    ret.typeIndex = type;
    var newType = this.jsBridgeService.getAITypes().find(type);
    if (newType != undefined) {
      var isA = v.kanaal & 1;
      if (isA && newType.kanalen > 1) {
        ret.kanaal = v.kanaal - 1;
        ret.range = newType.kanalen;
      } else {
        ret.kanaal = v.kanaal;
        ret.range = newType.kanalen;
      }
    }
    this.editValue = ret;
    this.valueChanged.emit(this.editValue);
  }

  onKanaalChange(kanaal: number) {
    let v = this.editValue;
    let ret = { ...v };
    var isA = v.kanaal & 1;
    var newKanaal = kanaal * 2 + isA;
    ret.kanaal = newKanaal;
    this.editValue = ret;
    this.valueChanged.emit(this.editValue);
  }

  onAKanaalChange(isA: boolean | string) {
    let v = this.editValue;
    let ret = { ...v };
    var baseKanaal = v.kanaal >> 1;
    var newKanaal = baseKanaal * 2 + (isA ? 1 : 0);
    ret.kanaal = newKanaal;
    this.editValue = ret;
    this.valueChanged.emit(this.editValue);
  }

  onKanaalInputChanged(event: RangedKanaal) {
    let v = this.editValue;
    let ret = { ...v };
    ret.kanaal = event.start;
    this.editValue = ret;
    this.valueChanged.emit(this.editValue);
  }

  halfValue(a: number) {
    return a >> 1;
  }

  isA(a: number) {
    return (a & 1) == 1;
  }

  onSave() {
    this.isEditing = false;
    this.kopConfigService.upsertAI(this.editValue);
  }
}
