<div>
    <kendo-label text="Kanaal">

        <kendo-numerictextbox #kanaalTextBox [value]="this.start" [min]="0"
        (valueChange)="onKanaalChange($event)" format="n0" [style]="{ width: '100px' }"></kendo-numerictextbox>
    </kendo-label>
    <button kendoButton (click)="this.showSelector = !this.showSelector">Kies</button>
@if (this.hasAKanaal()) {
    <kendo-checkbox #isACheckbox [checkedState]="this.isA"
        (checkedStateChange)="onAKanaalChange($event)"></kendo-checkbox>
    <kendo-label [for]="isACheckbox" text="A Kanaal">
    </kendo-label>
}
@if (this.hasInverted()) {
    <kendo-checkbox #invertedChk [checkedState]="this.inverted" (checkedStateChange)="onInvertedChange($event)"></kendo-checkbox>
    <kendo-label [for]="invertedChk" text="Inverted">
    </kendo-label>
}


</div>

<kendo-dialog *ngIf="this.showSelector" title="Kanalen" width="1300px" (close)="this.showSelector = false">
    <div>
        <kanaal-selector [kanaalType]="this.ioType" (onKanaalSelected)="onKanaalSelected($event)" [initialSelection]="this.kanaalSelection"
         [range]="this.range" [assign]="this.assign()"></kanaal-selector>
         <!-- <compact-kanaal-selector [kanaalType]="this.ioType" (onKanaalSelected)="onKanaalSelected($event)" [initialSelection]="this.kanaalSelection"
         [range]="this.range" [assign]="this.assign()"></compact-kanaal-selector> -->
         <!-- <detailed-kanaal-selector [kanaalType]="this.ioType" (onKanaalSelected)="onKanaalSelected($event)" [initialSelection]="this.kanaalSelection"
         [range]="this.range" [assign]="this.assign()"></detailed-kanaal-selector> -->

        </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.showSelector = false">Close</button>
        <button kendoButton (click)="this.onOK()" themeColor="primary">Select</button>
      </kendo-dialog-actions>
    </kendo-dialog>