<button kendoButton size="large" style="width: 100%;" (click)="this.isEditing = true">Extra Programma's</button>
<kendo-dialog *ngIf="isEditing" title="Extra Programma's" width="550px" (close)="this.isEditing = false">
    <div class="wrapper">
        @for (bp of propsService.bijzPrgs(); track bp.index) {
            <bijz-prgms-editor [initialValue]="bp" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
        }
        <div class="splitter"></div>
        @for (bp of this.availableBijzPrgms(); track bp.index) {
            <bijz-prgms-editor [initialValue]="bp" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
        }

    </div>
</kendo-dialog>