import { _IOType, IODirection, IOType } from "../../props/PropTypes";

export type BusType = 'fastlink' | 'scx';

export type BaseKaartType = {
    bus: BusType;
    label: string;
    takesRackSpace: boolean;
}
export type SimpleKaartType = BaseKaartType & {
    ioType: _IOType;
    reserved: number;
    available: number;
}

export type CombiKaartType = BaseKaartType & {
    karten: SimpleKaartType[];
}

export type SysteemKaartType = BaseKaartType & {
    isExtension: boolean;
}

export type KaartType = SimpleKaartType | CombiKaartType | SysteemKaartType;

export type HdwRackType = {
    slots: number;
    bus: BusType;
    label: string;
    karten: KaartType[];
}

export type MainboardType = {
    busTypes: BusType[];
    racks: HdwRackType[];
    karten: KaartType[];
    label: string;
}

export function isSimpleKaart (kaart: KaartType): kaart is SimpleKaartType {
    return (kaart as SimpleKaartType).ioType != undefined;
}

export function isCombiKaart (kaart: KaartType): kaart is CombiKaartType {
    return (kaart as CombiKaartType).karten != undefined;
}

export function isSysteemKaart (kaart: KaartType): kaart is SysteemKaartType {
    return (kaart as SysteemKaartType).isExtension != undefined;
    // return !isCombiKaart(kaart) && !isSimleKaart(kaart);
}

