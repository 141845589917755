import { Component, computed, HostListener } from '@angular/core';
import { PropsServiceService } from '../../props-service.service';
import { KopConfigService } from '../../../core/kop-config.service';

@Component({
  selector: 'klassiek-ai',
  templateUrl: './klassiek-ai.component.html',
  styles: `
    .button {
    width: 100%;
  }
  .wrapper {
    overflow-y: auto;
    max-height: 600px;
  }
  .splitter {
    border-bottom: 1px solid black;
    margin: 3px 0px 3px 0px;
    width: 100%;
  }
  `
})
export class KlassiekAiComponent {

  constructor(public propsService: PropsServiceService, public kopConfigService: KopConfigService) { }


  public isEditing: boolean = false;

  public availableAIs = computed(() => {
    let currentAis =  this.propsService.ais();
    let aiMetas = this.kopConfigService.currentMeta().ais;
    let metas =  aiMetas.filter(ai => !currentAis.find(a => a.index == ai.index));
    let ret = metas.map(m => {
      return this.kopConfigService.aiKCtoModule(m.index, undefined);
    });
    return ret;
  });

  @HostListener('window:keydown.alt.a', ['$event'])
  shortcut(event: KeyboardEvent) {
    this.isEditing = true;
  }

}
