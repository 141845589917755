<button #newAnchor kendoButton size="none" [themeColor]="'tertiary'" (click)="onNew()">{{this.isSelectMeta ? 'Close' : 'New' }}</button>
<kendo-popup *ngIf="this.isSelectMeta" title="Los Definite" width="400px" 
[anchor]="newAnchor" 
[popupAlign]="this.popUpAlign"
[margin]="this.popUpMargin"
 #metaPopup>
<div class="selectMetaWrapper">
    @for (ld of this.availableMetas(); track ld.index) {
        <div [class.led-even]="$even" [class.led-odd]="$odd" 
        [class.alreadySelected]="this.alreadySelected()(ld.index)"
        class="mouse-over"
         title="{{ld.index}}"
        (click)="onMetaSelected(ld)"><pre class="pre">{{this.labelCb()(ld)}}</pre></div>
    }
</div>
</kendo-popup>

