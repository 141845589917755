@let ckAfd = afd()?.afdeling();
<div class="card-container">
    <kendo-card width="260px" (click)="onClick()" class="clickable" [class.selected]="selected()">
        <!-- <kendo-card-header class="header"> -->
            <!-- <div class="header-row">
                <span class="afd-titel">{{ckAfd?.titel}}</span>
                <button kendoButton (click)="this.onClick(); this.kopConfigService.isDetail.set(true)">Modules</button>
            </div>
            <div class="header-row">
                <p title="{{typeKey(ckAfd?.type?.value)}}">{{ckAfd?.type | afdtype}}</p>
                <p>Ref: {{(ckAfd!.refNr -1)}}</p>
            </div> -->
        <!-- </kendo-card-header> -->
        <kendo-card-body>
            <div class="header">
                <span class="afd-titel">{{ckAfd?.titel}}</span>
                <span title="{{typeKey(ckAfd?.type?.value)}}">{{ckAfd?.type | afdtype}}</span>
                <span>Ref: {{(ckAfd!.refNr -1)}}</span>
                <div class="header-row">
                    <button kendoButton [themeColor]="'primary'" (click)="this.onClick(); this.kopConfigService.isDetail.set(true)">Modules bewerken</button>
                    <button kendoButton (click)="removeDialogOpen = true" [svgIcon]="trashIcon"></button>
                </div>
            </div>
            @if (this.selected()) {                
                <validation-info [validation]="validation()" />
            }
        </kendo-card-body>
    </kendo-card>
    @if (!this.isValid()) {
        <div class="ribbon">Check errors!</div>
    }
    
</div>

<kendo-dialog *ngIf="removeDialogOpen" title="Remove Afdeling" width="300px" (close)="removeDialogOpen = false">
    <p>Are you sure you want to delete {{ckAfd?.titel}}?</p>
    <kendo-dialog-actions>
        <button kendoButton (click)="removeDialogOpen = false">Cancel</button>
        <button kendoButton (click)="onRemove()" themeColor="primary">
            Remove
        </button>
    </kendo-dialog-actions>
</kendo-dialog>