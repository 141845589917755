<div *ngIf="(auth.isAuthenticated$ | async) === false" class="login">
    <kendo-card width="512px">
        <kendo-card-header>Web Kop Data</kendo-card-header>
        <kendo-card-body>
            <img src="../../../assets/Logo_512p.png" alt="logo" />
            <div class="login-btn">
                <auth-badge></auth-badge>
            </div>
        </kendo-card-body>
    </kendo-card>
</div>
<ng-container *ngIf="(auth.isAuthenticated$ | async) === true">
    <router-outlet></router-outlet>
</ng-container>