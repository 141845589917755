import { Component, computed, input } from '@angular/core';
import { LosDefType, ModuleLosdef } from '../../PropTypes';
import { PropsServiceService } from '../../props-service.service';
import { KopConfigService } from '../../../core/kop-config.service';
import { cKC_LosDef, cKC_LosDefs, Losdef } from '@wasm/KopWeb';
import { wasmVectorToArray } from '../../../utils/wasmVector';

@Component({
  selector: 'klassiek-los-def',
  templateUrl: './klassiek-los-def.component.html',
  styles: `
  .button {
    width: 100%;
  }
  .wrapper {
    overflow-y: scroll;
    max-height: 600px;
  }
  `
})
export class KlassiekLosDefComponent {

  constructor(public propsService: PropsServiceService, public kopConfigService: KopConfigService) { }
  public type = input<LosDefType>('losdef');

  public label = computed(() => {
    let type = this.type();
    switch (type) {
      case 'losdef': return 'Losse Definities';
      case 'koeling': return 'Koeling';
      case 'verwarming': return 'Verwarming';
      case 'droogwand': return 'Droogwand';
      default: return '';
    }
  });

  public filled = computed(() => {
    let type = this.type();
    switch (type) {
      case 'losdef': return this.propsService.losDefs();
      case 'koeling': return this.propsService.koelings();
      case 'verwarming': return this.propsService.verwarmings();
      case 'droogwand': return this.propsService.drogWands();
      default: return [];
    }
  });

  public metas = computed(() => {
    let type = this.type();
    let t: Losdef[] = [];
    switch (type) {
      case 'losdef': return this.kopConfigService.currentMeta().losDefs;
      case 'koeling': return this.kopConfigService.currentMeta().koelings;
      case 'verwarming': return this.kopConfigService.currentMeta().verwarmings;
      case 'droogwand': return this.kopConfigService.currentMeta().drogWands;
    }
    return t;
  });

  public losDefs = computed(() => {
    return this.metas().sort((a, b) => a.volgorde - b.volgorde).map(m => this.findLD(m)); 
  });

  public findLD(meta: Losdef): ModuleLosdef {
    let ret = this.filled().find(ld => ld.index == meta.index);
    if (ret != undefined) {
      return ret;
    }
    return this.kopConfigService.findOrBuildLosDef(meta, this.type());
 }

  public isEditing: boolean = false;

}
