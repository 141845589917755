import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerAPI } from '../serverapi';

@Component({
  selector: 'kop-wrapper',
  templateUrl: './kop-wrapper.component.html',
  styles: ``
})
export class KopWrapperComponent {
  constructor(@Inject('ServerApi') public electronAPI: ServerAPI,  private route:ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.klantNo = params['klantNo'];
    });
  }

  public klantNo = "ZZ0000";
}
