import { Component, EventEmitter, input, Output } from '@angular/core';

@Component({
  selector: 'number-popup',
  templateUrl: './number-popup.component.html',
  styles: ``
})
export class NumberPopupComponent {

  public isEditing = false;

  public title = input<string>("Kies Number");

  public value: number = 1;


  @Output()
  closed = new EventEmitter<void>();

  @Output()
  save = new EventEmitter<number>();

  public close() {
    this.isEditing = false;
    this.closed.emit();
  }

  public doSave() {
    this.isEditing  = false;
    this.save.emit(this.value);
  }
}
