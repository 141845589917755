import { AnalogIn, IOType, RangedKanaal } from "../props/PropTypes";

export function kanaalLabel(kanaal: number, kType: IOType): string {
    let isA = kanaal % 2 & 1;
    if (AnalogIn.equals(kType)) {
      if (kanaal == 1) {
        return "a";
      }
      if (isA) {
        return (kanaal >> 1).toString() + "a";
      } else {
        return (kanaal >> 1).toString();
      }
    } else {
      return kanaal.toString();
    }
  }

  export function rangedKanaalLabel(rangedKanaal: RangedKanaal): string {
    let ret =  kanaalLabel(rangedKanaal.start, rangedKanaal.ioType);
    if (rangedKanaal.range > 1) {
      ret += "\u2192" + kanaalLabel(rangedKanaal.start + rangedKanaal.range - 1, rangedKanaal.ioType);
      return "Kanaalen: " + ret;
    }
    return "Kanaal: " + ret;
  }
