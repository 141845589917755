import { Component, computed } from '@angular/core';
import { cAfdelingInfo, cAfdelingWM, cKopConfig } from '@wasm/KopWeb';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { wasmVectorToArray } from '../../utils/wasmVector';
import { KopModulesService } from '../../modules/kop-modules.service';
import { AfdModule } from '@etron/typings';
import { CompoundValueType } from '../../props/module-props/module-props.component';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import {
  arrowRotateCcwIcon,
  homeIcon,
  SVGIcon,
} from "@progress/kendo-svg-icons";
@Component({
  selector: 'app-afdeling-list',
  templateUrl: './afdeling-list.component.html',
  styles: `
    .list-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      flex-grow: 0;
    }
    .module-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      flex-grow: 1;
      align-content: flex-start;
      background-color: lightgray;
    }
    .dropzone {
      background-color: green;
    }
    .clickable {
      cursor: pointer;
    }
    .new-container {
      display: flex;
    flex-direction: row;
    align-items: start;
    }
  `
})
export class AfdelingListComponent {


  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService, public kopModulesService: KopModulesService) { }

  public selectedAfd: string | undefined;

  onCardClicked($event: cAfdelingWM) {
    //this.selectedAfd = $event.afdeling().uuid.toString();  
    this.kopConfigService.selectedAfd.set($event);
  }

  onModuleClicked($event: AfdModule) {
    this.kopModulesService.selectedAfdModule.set($event);
  }

  public allAfd = computed(() => {
    let tmp = this.jsBridgeService.getBridgeSync().getAfdelingsInfo();
    let ret = wasmVectorToArray(tmp)
      .filter(a => a.valid4version && a.is_afdelin)
      .sort((a, b) => a.type - b.type);
    tmp.delete();
    return ret;
  });

  public breadCrumb = computed(() => {
    let ret: BreadCrumbItem[] = [{
      text: "Home",
      title: "Home",
      svgIcon: homeIcon,
    }];
    let afd = this.kopConfigService.selectedAfd();
    if (afd != undefined) {
      let titel = afd.afdeling().titel.toString();
      ret.push({
        text: titel,
        title: titel
      })
    }
    let module = this.kopModulesService.selectedAfdModule();
    if (module != undefined) {
      let title = module.titel.toString();
      ret.push({
        text: title,
        title: title
      });
    }
    return ret;
  });

  onBreadCrumbClick(e: BreadCrumbItem) {
    if (e.text == "Home") {
      this.kopConfigService.isDetail.set(false);
      this.kopModulesService.selectedAfdModule.set(undefined);
    } else {
      this.kopModulesService.selectedAfdModule.set(undefined);
    }
  }

  onNew(e: any) {
    let selection = e as cAfdelingInfo;
    let afd = this.kopConfigService.addAfd(selection);

  }

  test(a: any) {
    return console.log(a);
  }
  ngOnInit() {
  }

  public isDropValid = false;


  // public isEditingModule = false;
  public moduleKey: string | undefined;

  onModuleEditCancel() {
    this.kopModulesService.isEditingModule.set(false);
    this.moduleKey = undefined;
  }

  onModuleEditApply(e: CompoundValueType) {
    console.log(e);
    let selectedAfd = this.kopConfigService.selectedAfd();
    for (let ld of e.losDefs) {
      this.kopConfigService.upsertLosDef(ld.losDef, 'losdef');
    }
    for (let ld of e.droogWands) {
      this.kopConfigService.upsertLosDef(ld.losDef, 'droogwand');
    }
    for (let ld of e.koelings) {
      this.kopConfigService.upsertLosDef(ld.losDef, 'koeling');
    }
    for (let ld of e.verwarmings) {
      this.kopConfigService.upsertLosDef(ld.losDef, 'verwarming');
    }

    for (let ai of e.ais) {
      if (ai.ai.kanaal > 0) {
        this.kopConfigService.upsertAI(ai.ai);
      }
    }
    for (let servo of e.servos) {
      this.kopConfigService.upsertServo(servo.servo, servo.meta);
    }
    if ((selectedAfd != undefined) && (this.moduleKey != undefined)) {
      this.kopModulesService.addModule(this.moduleKey, selectedAfd);
    }

    this.moduleKey = undefined;
    this.kopModulesService.isEditingModule.set(false);
  }

  onModuleDrop($event: DragEvent) {
    let key = $event.dataTransfer?.getData('module');
    this.moduleKey = key;
    this.kopModulesService.isEditingModule.set(true);
    // console.log($event, key);
    // let selectedAfd = this.kopConfigService.selectedAfd();
    // if ((selectedAfd != undefined) && (key != undefined)) {
    //   this.kopModulesService.addModule(key, selectedAfd);
    // }
    this.isDropValid = false;
  }

  onDragOver($event: DragEvent) {
    this.isDropValid = true;
    $event.preventDefault();
  }

  onDragEnd($event: DragEvent) {
    console.log("onDragEnd");
    this.isDropValid = false;
  }
}
