<button kendoButton size="large" class="button" (click)="this.isEditing = true">{{this.label()}}</button>
<kendo-dialog *ngIf="isEditing" title="{{this.label()}}" width="550px" (close)="this.isEditing = false">
    <div class="wrapper">
        @for (ld of this.losDefs(); track ld.index) {
        <los-def-editor [editOnly]="false" [ldType]="this.type()" [initialValue]="ld" [class.led-even]="$even"
            [class.led-odd]="$odd" />

        }
    </div>

</kendo-dialog>