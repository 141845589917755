@if (isNew()) {
    @if (newBPOptions().length != 0) {
        <div class="row normal-row">
            <meta-select-button 
            [availableMetas]="this.newBPOptions()"
            [alreadySelected]="this.alreadySelectedFN()"
            (onSelect)="onNew($event)"
            [labelCb]="this.labelFn()" />
        </div>                
    }
} @else {
<div class="row normal-row parent-bck clickable" (click)="onEdit()">
    <div [title]="meta()?.index">{{meta()?.omschr}}</div>
</div>
}
<kendo-dialog *ngIf="isEditing" title="Edit Extra Programma" width="600px" (close)="this.isEditing = false">
    <div>
        @for (paramMeta of paramsMeta(); track paramMeta.no) {
        <fieldset :class="cell" *ngIf="this.editValue.params[paramMeta.no] != undefined">
            <legend :class="legend">{{ paramMeta.omschr }} - {{servoKCTypeLabel(editValue.params[paramMeta.no]) }}</legend>
            <kendo-numerictextbox [(value)]="editValue.params[paramMeta.no].waarde" format="n0" [min]="0"
                [style]="{ width: '70px' }"></kendo-numerictextbox>
            <div *ngIf="this.kanaalService.getKCParamType(editValue.params[paramMeta.no]) != undefined">
                <kendo-checkbox #invertedChk
                    [(checkedState)]="editValue.params[paramMeta.no].inverted"></kendo-checkbox>
                <kendo-label [for]="invertedChk" text="Inverted">
                </kendo-label>
            </div>

        </fieldset>
        }
        <div>{{meta()?.functieText}}</div>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.isEditing = false">Cancel</button>
        <button kendoButton (click)="onDelete()" themeColor="primary" *ngIf="!isNew()">
            Delete
        </button>
        <button kendoButton (click)="onSave()" themeColor="primary">
            Save
        </button>
    </kendo-dialog-actions>
</kendo-dialog>
