import { Component, computed, signal } from '@angular/core';
import { KopModulesService } from '../../modules/kop-modules.service';
import { KanaalService } from '../../kanaal/kanaal.service';
import { JSBridgeService } from '../jsbridge.service';


@Component({
  selector: 'app-bar',
  templateUrl: './app-bar.component.html',
  styles: `
  .appbar {
    background-color: var(--dark-green);
  }
  `
})
export class AppBarComponent {

  constructor(public kopModulesService: KopModulesService, public jsBridgeService: JSBridgeService, public kanaalService: KanaalService) { }

  onSave() {
    this.kopModulesService.save();
  }

  public editVrijAlarmen = signal(false);
  public editUren = signal(false);
  public editGlobalConfig = signal(false);
  public editGlobalConfigExtra = signal(false);
  public editModbus = signal(false);

  public configOptions = [{
    text: "Global Config",
    click: () => {
      this.editGlobalConfig.set(true);
    }
  }, {
    text: "Global Extra Config",
    click: () => {
      this.editGlobalConfigExtra.set(true);
    }
  }, {
    text: "Vrije Alarm Teksten",
    click: () => {
      this.editVrijAlarmen.set(true);
    }
  },
  {
    text: "Global Uren & Special Teksten",
    click: () => {
      this.editUren.set(true);
    }
  },
  {
    text: "Modbus Netwerken",
    click: () => {
      this.editModbus.set(true);
    }
  }
];


  public showKanaalOverview = signal(false);
  public showXmlCompare = signal(false);

  public assign = computed(() => {
    let kfg = this.jsBridgeService.getBridgeSync().getModuleConfig();
    let assignement = this.kanaalService.getKonfigKanalen(kfg);
    return assignement;
  });


}
