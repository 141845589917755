import { Component, computed, effect, input, output } from '@angular/core';
import { ModBusNetwork } from '../PropTypes';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { wasmVectorToArray } from '../../utils/wasmVector';
import { cKC_ModBusNetwork } from '@wasm/KopWeb';

@Component({
  selector: 'modbus-network',
  templateUrl: './modbus-network.component.html',
  styles: `
    .mb-grid {
      display: grid;
      grid-template-columns: repeat(5, minmax(100px, 1fr));
      background-color: var(--light-gray);
    }
    .mb-grid > :nth-child(10n+1),
    .mb-grid > :nth-child(10n+2),
    .mb-grid > :nth-child(10n+3),
    .mb-grid > :nth-child(10n+4),
    .mb-grid > :nth-child(10n+5) {
        background-color: var(--light-green);
    }
  `
})
export class ModbusNetworkComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService) {
    effect(() => {
      if (this.isEditing()) {
        this.editValue = undefined;
      }
    });
  }

  public isEditing = input<boolean>(false);
  public onDone = output<boolean>();
  public editValue: ModBusNetwork | undefined;

  public modBusNetworks = computed(() => {
    let kopConfig = this.jsBridgeService.kopConfig();
    if (kopConfig != undefined) {
      return wasmVectorToArray(kopConfig.gModBusNetworks);
    }

  });

  onEdit(ckMB: cKC_ModBusNetwork) {
    this.editValue = {
      idNr: ckMB.idNr,
      name: ckMB.name.toString(),
      ip: ckMB.ip.toString(),
      port: ckMB.port
    }
  }

  onAdd() {
    this.editValue = {
      idNr: 0,
      name: "NEW",
      ip: "192.168.88.30",
      port: 500
    }    
  }

  onDelete(ckMB: cKC_ModBusNetwork) {
    this.jsBridgeService.kopConfig.update(kfg => {
      if (kfg != undefined) {
        let oldVec = this.modBusNetworks()!;
        kfg.gModBusNetworks.clear();
        oldVec.forEach(mb => {
          if (mb.idNr != ckMB.idNr) {
            kfg.gModBusNetworks.push_back(mb);
          }
        });
      }
      return kfg;
    });
  }


  onSave() {
    if (this.editValue != undefined) {
      this.jsBridgeService.kopConfig.update(kfg => {
        if (kfg != undefined) {
          let idx = this.modBusNetworks()!.findIndex(ckMB => ckMB.idNr == this.editValue!.idNr);
          let ckMB: cKC_ModBusNetwork | undefined;
          if (idx == -1) {
            let wasm = this.jsBridgeService.wasmModule!;
            let mxNr = 0;
            this.modBusNetworks()?.forEach(mb => {
              if (mb.idNr > mxNr) {
                mxNr = mb.idNr;
              }
            });
            mxNr++;
            ckMB = new wasm.cKC_ModBusNetwork();
            ckMB.idNr = mxNr;
          } else {
             ckMB = kfg.gModBusNetworks.at(idx);
          }
          if (ckMB != undefined) {
            ckMB.name = this.editValue!.name;
            ckMB.ip = this.editValue!.ip;
            ckMB.port = this.editValue!.port;
          }
          if (idx == -1) {  
            kfg.gModBusNetworks.push_back(ckMB);
          }
        }
        return kfg;
      });
      this.editValue = undefined;
    }
  }

}
