import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'auth-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss'
})
export class BadgeComponent {
  constructor(public auth: AuthService) {}

    loginWithRedirect() {
      this.auth.loginWithRedirect();
    }
  
    logout() {
      this.auth.logout({ logoutParams: { returnTo: window.location.origin } });
    }

}
