import { Component, computed, effect, ElementRef, HostListener, input, output, Renderer2, signal, ViewChild } from '@angular/core';
import { JSBridgeService } from '../../core/jsbridge.service';
import { KopConfigService } from '../../core/kop-config.service';
import { Losdef, MENUItem } from '@wasm/KopWeb';
import { IOBaseType, IODirection, LosDefType, ModuleLosdef, RangedKanaal, RangedKanaalInput } from '../PropTypes';
import { LDMenuService } from '../../core/ldmenu.service';
import { KanaalService } from '../../kanaal/kanaal.service';
import { wasmVectorToArray } from '../../utils/wasmVector';


const IOBaseTypeShort : Partial<Record<IOBaseType, string>> = {
  [IOBaseType.Analog]: 'A',
  [IOBaseType.Digital]: 'D',
  [IOBaseType.ModBus]: 'ModBus',
}

const IODirectionShort : Partial<Record<IODirection, string>> = {
  [IODirection.IN]: 'I',
  [IODirection.OUT]: 'O',
}
@Component({
  selector: 'los-def-editor',
  templateUrl: './los-def-editor.component.html',
  styleUrl: './los-def-editor.component.scss',
  animations: []
})
export class LosDefEditorComponent {
  constructor(private kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService, 
    public menuService: LDMenuService, public kanaalService: KanaalService, public renderer: Renderer2) {
    effect(() => {
      let ld = this.initialValue();
      if (ld != undefined) {
        this.editVal = {...ld}
      }
    });
  }

  public meta = computed(() => {
    let ld = this.initialValue();
    if (ld != undefined) {
      let meta = this.kopConfigService.currentMeta();
      return meta.losDefRaw.find(m => m.index == ld.index);
    }
    let hmeta = this.selectedMeta();
    if (hmeta != undefined) {
      return hmeta;
    }
  });

  public ldType = input<LosDefType>('losdef');

  public valueChanged  = output<ModuleLosdef>();

  public isEditing = false;
  public editOnly = input<boolean>(false);
  public isNew = input<boolean>(false);

  public editVal: ModuleLosdef = {
    index: 0,
    type: 0,
    // waarde: 0,
    inverted: false
  };

  isChecked(item: MENUItem) {
    let waarde = this.editVal.waarde;
    if (waarde != undefined) {
      return (waarde & item.mask) == item.value;
    }
    return false;
  }

  public valueLabel = computed(() => {
    let meta = this.meta();
    let ld = this.initialValue();
    if ((meta != undefined) && (ld != undefined) && (ld.waarde != 0) && (ld.waarde != undefined)) {
        if (meta.menunr > 0) {
          return this.menuService.valueLabel(meta.menunr, ld.waarde);
        } else  {
          return `${ld.waarde}${ld.inverted ? '!' : ''} ${this.typeLabel()}`
        }
    }
    return "";
  });

  onCheckboxChange(e: boolean | string, item: MENUItem) {
    let w = this.editVal.waarde != undefined ? this.editVal.waarde : 0;
    let v = (w & ~item.mask) | ((e == true) ? item.value : 0);
    this.editVal.waarde = v;
    this.valueChanged.emit(this.editVal);
  }

  onKanaalInputChanged(event: RangedKanaalInput) {
    this.editVal.waarde = event.start;
    this.editVal.inverted = event.inverted == true;
    // this.valueChanged.emit(this.editVal);
  }

  public initialValue = input<ModuleLosdef>();

  // private _initialValue = computed(() => {
  //   let inputValue = this.initialValue();
  //   if (inputValue != undefined) {
  //     return {...inputValue};
  //   }
  //   return this.kopConfigService.findOrBuildLosDef(this.meta()!, this.ldType());
  // });

  public ioType = computed(() => {
    let meta = this.meta();
    if (meta != undefined) {
      return this.kanaalService.getIOTypeFromParamType(meta.type)
    }
    return undefined;
  });

  public typeLabel = computed(() => {
    let ioType = this.ioType();
    if (ioType != undefined) {
      return `${IOBaseTypeShort[ioType.type]}${IODirectionShort[ioType.direction]}`;
    }
    return "";
  });


  onSave() {
    this.kopConfigService.upsertLosDef(this.editVal, this.ldType());
    this.isEditing = false;
  }


  @ViewChild("anchor", { read: ElementRef }) public anchor?: ElementRef = undefined;
  @ViewChild("popup", { read: ElementRef }) public popup?: ElementRef = undefined;
  private unlistener?: () => void; 

  public toggleEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.unlistener = this.renderer.listen("document", "click", (event) => {
        if (!this.contains(event.target)) {
          this.toggleEdit();
        }
      })
    } else if (this.unlistener != undefined) {
      this.unlistener();
    }

  }
 
  private contains(target: EventTarget): boolean {
    return (
      (this.anchor && this.anchor.nativeElement.contains(target)) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }


  public availableMetas = computed(() => {
    switch (this.ldType()) {
      case 'losdef':
        return this.kopConfigService.currentMeta().losDefs;
      case 'droogwand':
        return this.kopConfigService.currentMeta().drogWands;
      case 'koeling':
        return this.kopConfigService.currentMeta().koelings;
      case 'verwarming':
        return this.kopConfigService.currentMeta().verwarmings;
    }
  });

  public selectedMeta = signal<Losdef | undefined>(undefined);


  onMetaSelected(ldmb: any) {
    let ldm = ldmb as Losdef;
    let ld = this.kopConfigService.findOrBuildLosDef(ldm, this.ldType());
    this.selectedMeta.set(ldm);
    this.editVal = ld;
    this.isEditing = true;
  }

  alreadySelectedFN() {
    let afd = this.kopConfigService.selectedAfd()?.afdeling();
    let ret = (idx: number) => {
      if (afd != undefined) {
        let v = [];
        switch (this.ldType()) {
          case 'losdef':
            v = wasmVectorToArray(afd.losDefs);
            break;
          case 'droogwand':
            v = wasmVectorToArray(afd.droogWand);
            break;
          case 'koeling':
            v = wasmVectorToArray(afd.koeling);
            break;
          case 'verwarming':
            v = wasmVectorToArray(afd.verwarming);
            break;
        }
        let ret =  v.find((ld) => ld.index == idx && ld.waarde != 0) != undefined;        
        return ret;
      }
      return false;  
    }
    return ret;
  }

  labelFn() {
    let ret = (meta: any) => (meta as Losdef).omschr.toString();
    return ret;
  }

  onDelete() {
    this.kopConfigService.selectedAfd.update(afd => {
      if ((this.editVal != undefined) && (afd != undefined)) {
        this.kopConfigService.eraseLosDef(afd, this.editVal, this.ldType());
      }
      return afd;
    })
  }
}
