import { Component, computed, effect, input, output } from '@angular/core';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { wasmVectorToArray } from '../../utils/wasmVector';

@Component({
  selector: 'global-uren-teksten',
  templateUrl: './global-uren-teksten.component.html',
  styles: `
    .text-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 480px;
    column-gap: 5px;
  }
  `
})
export class GlobalUrenTekstenComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService) {
    for (let i = 0; i < 40; i++) {
      this.range.push(i);
    }
    effect(() => {
      if (this.isEditing()) {
        this.editVal = {};
        let urenTexts = this.urenTexts();
        for (let i of this.range) {
          if (urenTexts[i] != undefined) {
            this.editVal[i] = urenTexts[i];
          } else {
            this.editVal[i] = "";
          }
        }
      }
    });
  }

  public isEditing = input<boolean>(false);
  public onDone = output<boolean>();
  public range: Array<number> = [];
  public editVal: Record<number, string> = {};

  public urenTexts = computed(() => {
    let kopConfig = this.jsBridgeService.kopConfig();
    let ret: Record<number, string> = [];
    if (kopConfig != undefined) {
      let tmATs = kopConfig.gUrenTellerTeksten;
      if (tmATs != undefined) {
        let keys = wasmVectorToArray(tmATs.keys());
        keys.forEach(key => {
          let text = tmATs.get(key);
          if (text != undefined) {
            ret[key] = text?.toString();
          }
        });
      }
    }
    return ret;
  });


  onSave() {
    this.jsBridgeService.kopConfig.update(kfg => {
      if (kfg != undefined) {
        for (let [key, val] of Object.entries(this.editVal)) {
          let iKey = parseInt(key);
          if (val != "") {
            kfg.gUrenTellerTeksten.set(parseInt(key), val);
          } else if (kfg.gAlarmTeksten.get(iKey) != undefined) {
            kfg.gUrenTellerTeksten.erase(iKey);
          }
        }
      }
      return kfg;
    });
    this.onDone.emit(true);
  }

}
