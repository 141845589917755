@if (this.editOnly()) {
<div class="row normal-row parent-bck">
    <pre class="pre" [title]="meta()?.index">{{ meta()?.omschr }} /{{meta()?.menunr}}</pre>
    @if (meta()?.helplong?.toString() != "") {
        <div class="info-help"><info description="{{meta()?.helplong?.toString()}}" /></div>
    }
    @if (meta()?.menunr != 0) {
    <div class="kanaal-group">
        <pre class="pre">{{valueLabel()}}</pre>
        <button #anchor kendoButton (click)="this.toggleEdit()" size="small" [themeColor]="'tertiary'">{{isEditing ? 'Close' : 'Edit'}}</button>
        <!-- @for (item of this.menuService.getMenu(meta()!.menunr); track item.value) {
        <div>
            <kendo-checkbox #meta{{item.value}} [checkedState]="isChecked(item)" (checkedStateChange)="onCheckboxChange($event, item)"></kendo-checkbox>
            <kendo-label [for]="'meta' + item.value" text="{{item.omschr}}"></kendo-label>
        </div>
        } -->
    </div>
    <kendo-popup *ngIf="isEditing" title="{{ meta()?.omschr }}" width="300px"
    [anchor]="anchor.element" #popup>
   <div>
       @for (item of this.menuService.getMenu(meta()!.menunr); track item.value) {
       <div>
           <kendo-checkbox #meta{{item.value}} [checkedState]="isChecked(item)" (checkedStateChange)="onCheckboxChange($event, item)"></kendo-checkbox>
           <kendo-label [for]="'meta' + item.value" text="{{item.omschr}}"></kendo-label>
       </div>
       }
   </div>
    </kendo-popup>

    } @else {
    <div class="kanaal-group">
        <kendo-numerictextbox [(value)]="this.editVal.waarde!"  [style]="{ width: '70px' }" (valueChange)="this.valueChanged.emit(this.editVal)"
            [min]="0" format="n0"></kendo-numerictextbox>
            @if (ioType() != undefined) {
            <kendo-checkbox #invertedChk [(checkedState)]="this.editVal.inverted" 
            style="align-self: center;"
                (checkedStateChange)="this.valueChanged.emit(this.editVal)"></kendo-checkbox>
            <kendo-label [for]="invertedChk" text="Inverted">
            </kendo-label>

            } @else {
            <span></span>
            }
    </div>
    }    
    </div>
} @else { 
    @if (this.isNew()) {
    <div class="row">
        
        <meta-select-button [alreadySelected]="this.alreadySelectedFN()" 
            [labelCb]="this.labelFn()"
            (onSelect)="this.onMetaSelected($event)"
            [availableMetas]="this.availableMetas()"></meta-select-button>
    </div>
    } @else {
    <div class="row condensed parent-bck clickable" (click)="this.isEditing = true">
        <pre class="pre" title="{{meta()?.index}}  {{meta()?.helplong}}">{{ meta()?.omschr }}</pre>
        <div class="kanaal-group">
            <pre class="pre">{{valueLabel()}}</pre>
        </div>
    </div>
    }

    <kendo-dialog *ngIf="isEditing" title="{{ meta()?.omschr }}" width="300px" (close)="this.isEditing = false">
        @if (meta()?.menunr != 0) {
        <div>
            @for (item of this.menuService.getMenu(meta()!.menunr); track item.value) {
            <div>
                <kendo-checkbox #meta{{item.value}} [checkedState]="isChecked(item)" (checkedStateChange)="onCheckboxChange($event, item)"></kendo-checkbox>
                <kendo-label [for]="'meta' + item.value" text="{{item.omschr}}"></kendo-label>
            </div>
            }
        </div>

        } @else {
        <div class="kanaal-container">
            @if (ioType() != undefined) {
                <kanaal-input [initialValue]="{ ioType: ioType()!, range: this.editVal.range! , start: this.editVal.waarde!, inverted: this.editVal.inverted}" (valueChanged)="onKanaalInputChanged($event)" ></kanaal-input>
            } @else {
                <kendo-numerictextbox [(value)]="this.editVal.waarde!"  [style]="{ width: '70px' }"
                [min]="0" format="n0"></kendo-numerictextbox>
    
            }
        </div>

        }
        <kendo-dialog-actions>
            <button kendoButton (click)="this.isEditing = false">Cancel</button>
            <button kendoButton (click)="onDelete()" themeColor="primary" *ngIf="!isNew()">
                Delete
            </button>
            <button kendoButton (click)="onSave()" themeColor="primary">
                Save
            </button>
        </kendo-dialog-actions>

    </kendo-dialog>
}