import { Injectable } from '@angular/core';
import { KopConfigService } from '../core/kop-config.service';
import { cAfdelingWM, cKC_Analog, cKC_LosDef, cKC_Servo } from '@wasm/KopWeb';
import { AfdelingWM, AfdModule, AnyIndex, RequiredModule, RequiredModuleProps, Validation } from '@etron/typings';
import { wasmVectorToArray } from '../utils/wasmVector';
import { of } from 'rxjs';
import { KopModulesService } from './kop-modules.service';
import { AnyModule } from '@etron/typings';
import { CompoundValueType } from '../props/module-props/module-props.component';
import { ModuleAI, ModuleLosdef, ModuleServo } from '../props/PropTypes';



export type AfdObj = cKC_Analog | cKC_LosDef | cKC_Servo | ModuleAI | ModuleLosdef | ModuleServo;

export type ElementType = 'analog' | 'losdef' | 'servo';

export type Element = {
  idx: number;
  element: ElementType;
}

export type ElementValidation = {
  isValid: boolean;
  errMsg: string;
}

@Injectable({
  providedIn: null
})
export class ValidationService {

  constructor(public kopConfigService: KopConfigService, private kopModulesService: KopModulesService) { }


  anyIndex2array(idx: AnyIndex) {
    if (idx instanceof Array) {
      return idx;
    } else {
      return [idx];
    }
  }

  anyModule2array(module: AnyModule) {
    if (module instanceof Array) {
      return module;
    } else {
      return [module];
    }
  }

  isAI(v: AfdObj): v is cKC_Analog {
    return 'kanaal' in v;
  }

  isLosDef(v: AfdObj): v is cKC_LosDef {
    return 'waarde' in v;
  }

  isServo(v: AfdObj): v is cKC_Servo {
    return 'uitgang' in v;
  }


  isSet(v: AfdObj) {
    if (this.isAI(v)) {
      if (v.kanaal > 0) {
        return true;
      }
    } else if (this.isLosDef(v)) {
      if (v.waarde > 0) {
        return true;
      }
    } else if (this.isServo(v)) {
      if (v.uitgang > 0) {
        return true;
      }
    }
    return false;
  }

  test(idx: AnyIndex, provider: Array<AfdObj>) {
    let idxes = this.anyIndex2array(idx);
    for (let ai of provider) {
      if (idxes.includes(ai.index) && this.isSet(ai)) {
        return true;
      }
    }

    return false;
  }


  testModule(m: AnyModule, afd: AfdelingWM) {
    let modules = this.anyModule2array(m);

    for (let module of modules) {
      if (afd.modules.findIndex(m => m.meta.key == module) >= 0) {
        return true;
      }
    }

    return false;
  }

  validateRequiredProps2(afd: CompoundValueType, validation: Validation): Validation {
    let ret: Validation = {
    };
    let requiredProps = validation.requiredProps;


    let propsValidation: RequiredModuleProps = {
      analogeIngangen: [],
      losdef: [],
      servos: []
    };
    if (requiredProps != undefined) {
      if (requiredProps.analogeIngangen != undefined) {
        for (let rai of requiredProps.analogeIngangen) {
          let moduleAis = afd.ais.map(ai => ai.ai);
          if (!this.test(rai, moduleAis)) {
            propsValidation.analogeIngangen!.push(rai);
          }
        }
      }

      if (requiredProps.losdef != undefined) {
        let lds = afd.losDefs.map(ld => ld.losDef);;
        let dwd = afd.droogWands.map(ld => ld.losDef);;
        let verwarmings = afd.verwarmings.map(ld => ld.losDef);;
        let koelings = afd.koelings.map(ld => ld.losDef);;

        let allLds = lds.concat(dwd).concat(verwarmings).concat(koelings);
        for (let rld of requiredProps.losdef) {
          if (!this.test(rld, allLds)) {
            propsValidation.losdef!.push(rld);
          }
        }
      }

      if (requiredProps.servos != undefined) {
        for (let rsa of requiredProps.servos) {
          let moduleServos = afd.servos.map(s => s.servo);
          if (!this.test(rsa, moduleServos)) {
            propsValidation.servos!.push(rsa);
          }
        }
      }
    }

    ret.requiredProps = propsValidation;

    return ret;
  }

  validateRequiredProps(afd: cAfdelingWM, validation: Validation): Validation {
    let requiredProps = validation.requiredProps;


    let propsValidation: RequiredModuleProps = {
      analogeIngangen: [],
      losdef: [],
      servos: []
    };
    if (requiredProps != undefined) {
      if (requiredProps.analogeIngangen != undefined) {
        for (let rai of requiredProps.analogeIngangen) {
          if (!this.test(rai, wasmVectorToArray(afd.afdeling().analogs))) {
            propsValidation.analogeIngangen!.push(rai);
          }
        }
      }

      if (requiredProps.losdef != undefined) {
        let lds = wasmVectorToArray(afd.afdeling().losDefs);
        let dwd = wasmVectorToArray(afd.afdeling().droogWand);
        let verwarmings = wasmVectorToArray(afd.afdeling().verwarming);
        let koelings = wasmVectorToArray(afd.afdeling().koeling);

        let allLds = lds.concat(dwd).concat(verwarmings).concat(koelings);
        for (let rld of requiredProps.losdef) {
          if (!this.test(rld, allLds)) {
            propsValidation.losdef!.push(rld);
          }
        }
      }

      if (requiredProps.servos != undefined) {
        for (let rsa of requiredProps.servos) {
          if (!this.test(rsa, wasmVectorToArray(afd.afdeling().servos))) {
            propsValidation.servos!.push(rsa);
          }
        }
      }
    }

    let uuid = afd.afdeling().uuid.toString();
    let afdWM = this.kopModulesService.afdelings()[uuid];
    let requiredModules = validation.requiredModules;

    let ret: Validation = {
      requiredProps: propsValidation
    };

    if (requiredModules != undefined) {
      if (afdWM != undefined) {
        let modulesValidation = this.validateRquiredModules(afdWM, requiredModules);
        ret.requiredModules = modulesValidation.requiredModules;
      } else {
        ret.requiredModules = requiredModules;
      }

    }


    return ret;
  }

  validateRquiredModules(afd: AfdelingWM, requiredModules: RequiredModule): Validation {
    let ret: RequiredModule = {
      modules: []
    }
    for (let rm of requiredModules.modules) {
      if (!this.testModule(rm, afd)) {
        ret.modules!.push(rm);
      }
    }
    return {
      requiredModules: ret
    };
  }

  checkElementValidation(idx: number, arr: Array<AnyIndex> | undefined) {
    if (arr == undefined) {
      return undefined;
    }
    for (let i = 0; i < arr.length; i++) {
      let ai = arr[i];
      let idxes = this.anyIndex2array(ai);
      if (idxes.includes(idx)) {
        return ai;
      }
    }
    return undefined;
  }
  isOf(idx: AnyIndex): idx is Array<number> {
    if (idx instanceof Array) {
      return true;
    } else {
      return false;
    }
  }
  isOfM(idx: AnyModule): idx is Array<string> {
    if (idx instanceof Array) {
      return true;
    } else {
      return false;
    }
  }

  metaLD(idx: number) {
    let meta = this.kopConfigService.currentMeta().losDefs.find(ld => ld.index == idx);
    if (meta != undefined) {
      return meta;
    }
    meta = this.kopConfigService.currentMeta().drogWands.find(ld => ld.index == idx);
    if (meta != undefined) {
      return meta;
    }
    meta = this.kopConfigService.currentMeta().koelings.find(ld => ld.index == idx);
    if (meta != undefined) {
      return meta;
    }
    meta = this.kopConfigService.currentMeta().verwarmings.find(ld => ld.index == idx);
    return meta;
  }

  getOmschr(a: number, type: ElementType) {
    let ret = "";
    switch (type) {
      case 'analog':
        let m = this.kopConfigService.currentMeta().ais.find(ai => ai.index == a);
        ret += m?.omschrij;
        break;
      case 'losdef':
        ret += this.metaLD(a)?.omschr;
        break;
      case 'servo':
        let ms = this.kopConfigService.currentMeta().servos.find(ai => ai.index == a);
        ret += ms?.omschr;
        break;
    }
    return ret;

  }

  buildErrorMsg(arr: AnyIndex, type: ElementType) {
    if (this.isOf(arr)) {
      let ret = "Missing one of: \n";
      for (let a of arr) {
        ret += this.getOmschr(a, type) + "\n";
       
      }
      return ret;
    } else {
      let ret = "Missing: \n";
      ret  += this.getOmschr(arr, type) + "\n";
      return ret;
    }
  }
  getElementValidation(validation: Validation | undefined, element: Element): ElementValidation {
    let ret: ElementValidation = {
      isValid: true,
      errMsg: ''
    }
    if (validation == undefined) {
      return ret;
    }
    switch (element.element) {
      case 'analog':
        let v = this.checkElementValidation(element.idx, validation.requiredProps?.analogeIngangen);
        if (v != undefined) {
          ret.isValid = false;
          ret.errMsg = this.buildErrorMsg(v, element.element);
        }
      case 'losdef':
        let v2 = this.checkElementValidation(element.idx, validation.requiredProps?.losdef);
        if (v2 != undefined) {
          ret.isValid = false;
          ret.errMsg = this.buildErrorMsg(v2, element.element);
        }
      case 'servo':
        let v3 = this.checkElementValidation(element.idx, validation.requiredProps?.servos);
        if (v3 != undefined) {
          ret.isValid = false;
          ret.errMsg = this.buildErrorMsg(v3, element.element);
        }
    }

    return ret;
  }
}
