<div [class]="'kanaal-cell'" (click)="this.click.emit()" [class.vrij]="this.cell().length == 0 && !this.isSelected()"
    [class.one-assign]="this.cell().length == 1 && !this.isSelected()"
    [class.multi-assign]="this.cell().length > 1 && !this.isSelected()" [class.kanaal-selected]="this.isSelected()">
    <div class="kanaal-label">{{this.label()}}</div>
    @if (this.cell().length > 0) {
    <div class="d-assignement-wrapper" [class.hovered]="this.isSelected()" #dwrapper >
        <kendo-badge-container>
            @for(assign of this.cell(); track $index) {
            <div>{{assign.assignement.omschrijving}} [{{assign.assignement.afdeling}}]</div>
            }
            @if (this.isClipped && !this.isSelected()) {
            <kendo-badge themeColor="inverse" [align]="{vertical: 'top', horizontal: 'end'}"
                style="right: 10px; top: 0.8rem;">
                <kendo-svg-icon [size]="'xsmall'" [icon]="this.icons.arrowOverflowDownIcon"></kendo-svg-icon>
            </kendo-badge>

            }
        </kendo-badge-container>
    </div>
    } @else {
    -
    }
</div>