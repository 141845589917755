import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'auth-content',
  templateUrl: './auth-content.component.html',
  styles: `.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .login-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    
  }
  `
})
export class AuthContentComponent {
  constructor(public auth: AuthService) {}


}
