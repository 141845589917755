import { Component } from '@angular/core';

@Component({
  selector: 'lib-kopweb-core',
  template: `
    <p>
      kopweb-core works!3
    </p>
  `,
  styles: ``
})
export class KopwebCoreComponent {

}
