import { Component } from '@angular/core';
import { TrpcAPIService } from '../../trpc-api.service';
import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from '@server/src';

type RouterOutput = inferRouterOutputs<AppRouter>;
type KlantenList = RouterOutput['listKlanten'];
@Component({
  selector: 'klant-list',
  templateUrl: './klant-list.component.html',
  styles: `
  .kendo-grid-custom {
    max-height: 85vh;
  }
  .kop-link {
    color: #3f51b5;
  }
  `,
  
})
export class KlantListComponent {

  constructor(public apiService: TrpcAPIService) {
    this.queryKlanten();
  }

  public klanten: KlantenList = [];


  async queryKlanten() {
    let q = await this.apiService.client.listKlanten.query();
    
    this.klanten = q.map(x => ({
      ...x,
      FULLNAAM: x.VOORNAAM + ' ' + x.ACHTERNAAM
    }));
  }
}
