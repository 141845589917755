import { Component, effect, ElementRef, input, output, ViewChild } from '@angular/core';
import { JSBridgeService } from '../../core/jsbridge.service';
import { DiffEditorModel } from 'ngx-monaco-editor-v2';

@Component({
  selector: 'xmlcompare',
  templateUrl: './xmlcompare.component.html',
  styleUrl: './xmlcompare.component.css'
})
export class XMLCompareComponent {

  constructor(public jsBridgeService: JSBridgeService ) { 
    effect(() => {
      if (this.isEditing()) {
        this.diffXML();        
      }
    })
  }

  public isEditing = input<boolean>(false);
  public onDone = output<boolean>();


  @ViewChild('monaco') monaco: ElementRef | undefined;


  public options = {
  };
  public originalModel: DiffEditorModel = {
    code: 'heLLo world!',
    language: 'text/xml'
  };

  public modifiedModel: DiffEditorModel = {
    code: 'hello orlando!',
    language: 'text/xml'
  };

  private diffXML() {
    let oldXMl = this.jsBridgeService.getOLDXML();
    let newXML = this.jsBridgeService.getNewXML();
    this.originalModel.code = oldXMl;
    this.modifiedModel.code = newXML;    
  }

}
