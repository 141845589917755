import { Component } from '@angular/core';
import { KanaalSelectorBase } from '../kanaal-selector/KanaalSelectorBase';

@Component({
  selector: 'detailed-kanaal-selector',
  templateUrl: './detailed-selector.component.html',
  styleUrl: './detailed-selector.component.scss'
})
export class DetailedSelectorComponent extends KanaalSelectorBase {


  public rowSize() {
    return 16;
  }

  public onDetailClick(x: number, y: number) {
    let asgn = this.kanaalMap()[x][y];

    let kanaal = x * this.rowSize() + y + 1;
    if (this.isValidSelection(kanaal)) {
      this.selection.set({
        kanaal: {
          start: kanaal,
          ioType: this.kanaalType()!,
          range: this.range()

        },
        assignement: asgn
      });
      this.onKanaalSelected.emit(this.selection());
    }
  }

}
