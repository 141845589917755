import { Component, computed, effect, input, output } from '@angular/core';
import { KopConfigService } from '../../core/kop-config.service';
import { JSBridgeService } from '../../core/jsbridge.service';
import { GlobalConfig } from '../PropTypes';
import { eTE_Instalatie } from '@wasm/KopWeb';
import { wasmVectorToArray } from '../../utils/wasmVector';

@Component({
  selector: 'global-config',
  templateUrl: './global-config.component.html',
  styles: `
  .config-cols {
    display: flex;
    flex-direction: row;
  }
  .one-col {
    display: flex;
    flex-direction: column;
  }
  `
})
export class GlobalConfigComponent {

  constructor(public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService) {
    effect(() => {
      if (this.isEditing()) {
        let globalConfig = this.jsBridgeService.kopConfig()?.global;
        let extraCfg = this.jsBridgeService.getBridgeSync().getGlobalExtraConfig();
        if (globalConfig != undefined) {
          this.editValue = {
            baudrate: globalConfig.baudrate,
            visuGrafieken: globalConfig.visuGrafieken,
            ctdoos: globalConfig.ctdoos,
            pralarm: globalConfig.pralarm,
            nkb: globalConfig.nkb,
            kristal: globalConfig.kristal.value,
            srtPrn: globalConfig.srtPrn.value,
            overzPCLCD: globalConfig.overzPCLCD,
            weermast: globalConfig.weermast,
            nieuwVideo: globalConfig.nieuwVideo,
            compress: globalConfig.compress,
            station: globalConfig.station,
            cyclPeak: globalConfig.cyclPeak,
            luchtdruk: globalConfig.luchtdruk,
            updatenr: globalConfig.updatenr,

            peakShaveInZones: globalConfig.peakShaveInZones,
            noVerhoogKetelSp: globalConfig.noVerhoogKetelSp,
            windVaanRefSpan7V: globalConfig.windVaanRefSpan7V,
            sc9x0_FastLink: globalConfig.sc9x0_FastLink,
            gasMeting2Apart: globalConfig.gasMeting2Apart,
            digWindsnelhOpDI1: globalConfig.digWindsnelhOpDI1,
            branderOpMinTemp: globalConfig.branderOpMinTemp,
            dauwpuntBegrenzing: globalConfig.dauwpuntBegrenzing,
            noBedieningen: globalConfig.noBedieningen,
            comTxt: globalConfig.comTxt.toString(),
            gekoppeldKlantno: globalConfig.gekoppeldKlantno.toString(),

            compType: globalConfig.compType.value,
            totener: globalConfig.totener.value,
            nszone: wasmVectorToArray(extraCfg.nszone)
          }
        }

      }
    });
  }

  public isEditing = input<boolean>(false);
  public onDone = output<boolean>();
  public editValue: GlobalConfig | undefined;

  public baudRateOptions = [1200, 2400, 4800, 9600, 19200, 115200];
  public kristalOptions = [{index: 0, label:'1Mhz'}, {index: 1, label:'2Mhz'}];
  public printerOptions = [{index: 0, label:'Itoh'}, {index: 1, label:'Epson'}];
  public computerTypeOptions = [{index: 0, label:'Standaard'}, {index: 1, label:'SC800/850'}];

  public totenerOptions = [{index: 0, label:'Uit'}, {index: 1, label:'Aan 0..10V'}, {index: 1, label:'Aan 10..0V'}];

  private findTotener(v: number): eTE_Instalatie {
    let wasm = this.jsBridgeService.wasmModule!;
    for (let [_, te] of Object.entries(wasm.eTE_Instalatie)) {
      if (te.value == v) {
        return te;
      }
    }
    return wasm.eTE_Instalatie.eTE_Uit;
  }

  onSave() {
    console.log(this.editValue);
    let wasm = this.jsBridgeService.wasmModule!;
    this.onDone.emit(true);
    this.jsBridgeService.kopConfig.update(kfg => {
      if (kfg != undefined) {
        kfg.global.baudrate = this.editValue!.baudrate;
        kfg.global.visuGrafieken = this.editValue!.visuGrafieken;
        kfg.global.ctdoos = this.editValue!.ctdoos;
        kfg.global.pralarm = this.editValue!.pralarm;
        kfg.global.nkb = this.editValue!.nkb;
        kfg.global.kristal = this.editValue!.kristal == 1 ? wasm.eKristal.eKristal2Mhz : wasm.eKristal.eKristal1Mhz;
        kfg.global.srtPrn = this.editValue!.srtPrn ==1 ? wasm.eSoortPrinter.eSP_Epson : wasm.eSoortPrinter.eSP_Itoh;
        kfg.global.overzPCLCD = this.editValue!.overzPCLCD;
        kfg.global.weermast = this.editValue!.weermast;
        kfg.global.nieuwVideo = this.editValue!.nieuwVideo;
        kfg.global.compress = this.editValue!.compress;
        kfg.global.station = this.editValue!.station;
        kfg.global.cyclPeak = this.editValue!.cyclPeak;
        kfg.global.luchtdruk = this.editValue!.luchtdruk;
        kfg.global.updatenr = this.editValue!.updatenr;
        kfg.global.peakShaveInZones = this.editValue!.peakShaveInZones;
        kfg.global.noVerhoogKetelSp = this.editValue!.noVerhoogKetelSp;
        kfg.global.windVaanRefSpan7V = this.editValue!.windVaanRefSpan7V;
        kfg.global.sc9x0_FastLink = this.editValue!.sc9x0_FastLink;
        kfg.global.gasMeting2Apart = this.editValue!.gasMeting2Apart;
        kfg.global.digWindsnelhOpDI1 = this.editValue!.digWindsnelhOpDI1;
        kfg.global.branderOpMinTemp = this.editValue!.branderOpMinTemp;
        kfg.global.dauwpuntBegrenzing = this.editValue!.dauwpuntBegrenzing;
        kfg.global.noBedieningen = this.editValue!.noBedieningen;
        kfg.global.comTxt = this.editValue!.comTxt;
        kfg.global.gekoppeldKlantno = this.editValue!.gekoppeldKlantno;

        kfg.global.compType = this.editValue!.compType == 1 ? wasm.eComputerType.eCT_SC800_850 : wasm.eComputerType.eCT_Standaard;
        kfg.global.totener = this.findTotener(this.editValue!.totener);

        let xtraCfg = this.jsBridgeService.getBridgeSync().getGlobalExtraConfig();
        for (let i = 0; i < xtraCfg.nszone.size(); i++) {
          xtraCfg.nszone.set(i, this.editValue!.nszone[i]);
          console.log(xtraCfg.nszone.get(i), this.editValue!.nszone[i]);
        }
        
        this.jsBridgeService.getBridgeSync().setGlobalExtraConfig(xtraCfg);
      }
      return kfg;
    });
  }
}
