import { Component, computed } from '@angular/core';
import { PropsServiceService } from '../../props-service.service';
import { KopConfigService } from '../../../core/kop-config.service';
import { wasmMapToRecord } from '../../../utils/wasmVector';
import { cKC_Uren } from '@wasm/KopWeb';
import { ModuleUren } from '../../PropTypes';
import { JSBridgeService } from '../../../core/jsbridge.service';

@Component({
  selector: 'klassiek-uren',
  templateUrl: './klassiek-uren.component.html',
  styles: `
  .wrapper {
    overflow-y: auto;
    max-height: 600px;
  }
  .splitter {
    border-bottom: 1px solid black;
    margin: 3px 0px 3px 0px;
    width: 100%;
  }
  `
})
export class KlassiekUrenComponent {

  constructor(public propsService: PropsServiceService, public kopConfigService: KopConfigService, public jsBridgeService: JSBridgeService) { }


  public isEditing: boolean = false;

  public availableUrenKeys = computed(() => {
    let tmUrenW = this.kopConfigService.selectedAfd()?.afdeling().urens;
    if (tmUrenW != undefined) {
      let currentUren = wasmMapToRecord(tmUrenW, e => e as cKC_Uren);
      console.log(currentUren);
      let ret: ModuleUren[] = [];
      let wasm = this.jsBridgeService.wasmModule!;
      for (let i = 0; i < 4; i++) {
        if (currentUren[i] == undefined) {
          ret.push({
            blank: false,
            inverted: false,
            kanaal: 0,
            key: i,
            no: 0,
            type: wasm.eUrenTellerType.eUT_Tekst
          });
        }
      }
      return ret;
    }
    return [];
  });
}
