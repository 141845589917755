<kendo-appbar position="top" class="appbar">
    <kendo-appbar-section>
        <button kendoButton (click)="this.onSave()" [themeColor]="'light'" [fillMode]="'link'">Save</button>
        <button kendoButton (click)="this.showXmlCompare.set(true)"  [themeColor]="'light'" [fillMode]="'link'">Compare</button>

        <kendo-dropdownbutton [data]="this.configOptions" [themeColor]="'light'" [fillMode]="'link'">Config</kendo-dropdownbutton>
        <button kendoButton (click)="this.showKanaalOverview.set(true)" [themeColor]="'light'" [fillMode]="'link'">Kanalen</button>
        <system-def></system-def>
    </kendo-appbar-section>
    <kendo-appbar-spacer>

    </kendo-appbar-spacer>
    <kendo-appbar-section>
        <ng-content></ng-content>
    </kendo-appbar-section>
</kendo-appbar>
<global-alarm-texts [isEditing]="this.editVrijAlarmen()" (onDone)="this.editVrijAlarmen.set(false)" />
<global-uren-teksten [isEditing]="this.editUren()" (onDone)="this.editUren.set(false)" />
<global-config [isEditing]="this.editGlobalConfig()" (onDone)="this.editGlobalConfig.set(false)" />
<global-config-extra [isEditing]="this.editGlobalConfigExtra()" (onDone)="this.editGlobalConfigExtra.set(false)" />
<modbus-network [isEditing]="this.editModbus()" (onDone)="this.editModbus.set(false)" />
<kanaal-overview-dialog [isShowing]="this.showKanaalOverview()" (onDone)="this.showKanaalOverview.set(false)" [assign]="this.assign()" />
<xmlcompare [isEditing]="this.showXmlCompare()" (onDone)="this.showXmlCompare.set(false)"></xmlcompare>
