<kendo-dialog *ngIf="isEditing()" title="Uren Teller & Speciale Teksten" width="900px" (close)="this.onDone.emit(false)">
    <div class="text-wrapper">
        @for (key of this.range; track key) {
            <kendo-label text="{{key+1}}">
                <kendo-textbox [(value)]="this.editVal[key]" ></kendo-textbox>
            </kendo-label>
        }        
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.onDone.emit(false)">Cancel</button>
        <button kendoButton (click)="onSave()" themeColor="primary">
            Save
        </button>
    </kendo-dialog-actions>
</kendo-dialog>

