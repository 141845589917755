import { Injectable } from '@angular/core';
import { JSBridgeService } from './jsbridge.service';
import { wasmVectorToArray } from '../utils/wasmVector';

@Injectable({
  providedIn: null
})
export class LDMenuService {

  constructor(private jsBridgeService: JSBridgeService) {

  }

  getMenu(menunr: number) {
    let items = this.jsBridgeService.getBridgeSync().getMenuItems(menunr);
    return wasmVectorToArray(items);
  }

  valueLabel(menunr: number | undefined, waarde: number | undefined) {
    if ((menunr == undefined) || (waarde == undefined)) {
      return "";
    }
    let items = this.getMenu(menunr);
    if (waarde != 0) {
      let used = items.filter(item => {
        let a = waarde & item.mask;
        let ret = (a == item.value);
        return ret;

      });
      return used.map(item => item.kort).join(",");
    }
    return "";
  }

}
