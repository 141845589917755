@if (this.editValue != undefined) {
<kendo-dialog *ngIf="isEditing()" title="Global Configuration" width="800px" (close)="this.onDone.emit(false)">
    <div class="config-cols">
        <div class="one-col">
            <div>
                <kendo-checkbox #visuSercoVisie [(checkedState)]="this.editValue.visuSercoVisie"></kendo-checkbox>
                <kendo-label text="SercoVisie" [for]="visuSercoVisie">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #visuGrafieken [(checkedState)]="this.editValue.visuGrafieken"></kendo-checkbox>
                <kendo-label text="Grafieken" [for]="visuGrafieken">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #visuExportModule [(checkedState)]="this.editValue.visuExportModule"></kendo-checkbox>
                <kendo-label text="Export Module" [for]="visuExportModule">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #visuSercoView [(checkedState)]="this.editValue.visuSercoView"></kendo-checkbox>
                <kendo-label text="SercoView" [for]="visuSercoView">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #visuSMSModule [(checkedState)]="this.editValue.visuSMSModule"></kendo-checkbox>
                <kendo-label text="SMS Module" [for]="visuSMSModule">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #visuPeriodeOverzicht
                    [(checkedState)]="this.editValue.visuPeriodeOverzicht"></kendo-checkbox>
                <kendo-label text="Periode Overzicht" [for]="visuPeriodeOverzicht">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #visuSnelleGrafieken
                    [(checkedState)]="this.editValue.visuSnelleGrafieken"></kendo-checkbox>
                <kendo-label text="Snelle Grafieken" [for]="visuSnelleGrafieken">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #visuSercoDec [(checkedState)]="this.editValue.visuSercoDec"></kendo-checkbox>
                <kendo-label text="Serco Dec" [for]="visuSercoDec">
                </kendo-label>
            </div>
        </div>
        <div class="one-col">
            <div>
                <kendo-checkbox #klimMeerDaagseTempInt
                    [(checkedState)]="this.editValue.klimMeerDaagseTempInt"></kendo-checkbox>
                <kendo-label text="Meerdaagse T. Int." [for]="klimMeerDaagseTempInt">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimMTIWeerServer [(checkedState)]="this.editValue.klimMTIWeerServer"></kendo-checkbox>
                <kendo-label text="MTI Weerserver" [for]="klimMTIWeerServer">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimGasPeakShaven [(checkedState)]="this.editValue.klimGasPeakShaven"></kendo-checkbox>
                <kendo-label text="Gas Peak-Shaven" [for]="klimGasPeakShaven">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimNuonC2 [(checkedState)]="this.editValue.klimNuonC2"></kendo-checkbox>
                <kendo-label text="Nuon C^2" [for]="klimNuonC2">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimSercoLink [(checkedState)]="this.editValue.klimSercoLink"></kendo-checkbox>
                <kendo-label text="SercoLink" [for]="klimSercoLink">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimUitgebrCO2Prg [(checkedState)]="this.editValue.klimUitgebrCO2Prg"></kendo-checkbox>
                <kendo-label text="Uitgebr. CO2 Prg/" [for]="klimUitgebrCO2Prg">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimGewasTempRegelPrg
                    [(checkedState)]="this.editValue.klimGewasTempRegelPrg"></kendo-checkbox>
                <kendo-label text="Gewastemp Regelprg" [for]="klimGewasTempRegelPrg">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimSerCoolVKassen
                    [(checkedState)]="this.editValue.klimSerCoolVKassen"></kendo-checkbox>
                <kendo-label text="SercoCool v. Kassen" [for]="klimSerCoolVKassen">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimUitgebrSchermPrg
                    [(checkedState)]="this.editValue.klimUitgebrSchermPrg"></kendo-checkbox>
                <kendo-label text="Uitgebr. schermprg" [for]="klimUitgebrSchermPrg">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimKoppelingSDF [(checkedState)]="this.editValue.klimKoppelingSDF"></kendo-checkbox>
                <kendo-label text="Koppeling SDF" [for]="klimKoppelingSDF">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimRecepten [(checkedState)]="this.editValue.klimRecepten"></kendo-checkbox>
                <kendo-label text="Recepten (v/a V22)" [for]="klimRecepten">
                </kendo-label>
            </div>
            <div>
                <kendo-checkbox #klimFlexRecepten [(checkedState)]="this.editValue.klimFlexRecepten"></kendo-checkbox>
                <kendo-label text="Flex Recepten (v/a V27)" [for]="klimFlexRecepten">
                </kendo-label>
            </div>
        </div>
    </div>
    <div class="config-cols">
        <div class="one-col">
            <kendo-label text="Geografische breedte">
                <kendo-numerictextbox [(value)]="this.editValue.geoBreedte" [min]="0" format="n4"
                    [style]="{ width: '100px' }"></kendo-numerictextbox>
            </kendo-label>
            <kendo-label text="Geografische lengte">
                <kendo-numerictextbox [(value)]="this.editValue.geoLengte" [min]="0" format="n4"
                    [style]="{ width: '100px' }"></kendo-numerictextbox>
            </kendo-label>
            <kendo-label text="Tijdzone">
                <kendo-numerictextbox [(value)]="this.editValue.geoTijdzone" [min]="-12" [max]="12" format="n0"
                    [style]="{ width: '100px' }"></kendo-numerictextbox>
            </kendo-label>

        </div>
        <div class="one-col">
            <kendo-label text="RackXtender">
                <kendo-numerictextbox [(value)]="this.editValue.rackxtender" [min]="0" [max]="65535" format="n0"
                    [style]="{ width: '100px' }"></kendo-numerictextbox>
            </kendo-label>
            <kendo-label text="FastLinkXtender">
                <kendo-numerictextbox [(value)]="this.editValue.fastlinkxtender" [min]="0" [max]="65535" format="n0"
                    [style]="{ width: '100px' }"></kendo-numerictextbox>
            </kendo-label>
            <kendo-label text="SercoLink station ID">
                <kendo-numerictextbox [(value)]="this.editValue.sercoLinkStationId" [min]="0" [max]="65535" format="n0"
                    [style]="{ width: '100px' }"></kendo-numerictextbox>
            </kendo-label>
            I
        </div>
    </div>
    <div class="one-col">
        <div>
            <kendo-checkbox #noEuroSummerTime [(checkedState)]="this.editValue.noEuroSummerTime"></kendo-checkbox>
            <kendo-label text="Geen Europese Zomertijd" [for]="noEuroSummerTime">
            </kendo-label>
        </div>
        <div>
            <kendo-checkbox #printInstChangeAbroad [(checkedState)]="this.editValue.printInstChangeAbroad"></kendo-checkbox>
            <kendo-label text="Printen instellinswijzigingen buitenland" [for]="printInstChangeAbroad">
            </kendo-label>
        </div>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.onDone.emit(false)">Cancel</button>
        <button kendoButton (click)="onSave()" themeColor="primary">
            Save
        </button>
    </kendo-dialog-actions>
</kendo-dialog>
}