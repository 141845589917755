@if (editOnly()) {
    <div class="row  normal-row row-header led-odd">{{meta()?.omschrij}}</div>
    <div class="row  normal-row">
        <kendo-label text="Type" style="display: flex;">
            <kendo-dropdownlist [data]="this.jsBridgeService.getAITypes().findByGroup(meta()!.typeGroep)"
                [valuePrimitive]="true" [value]="this.editValue.typeIndex" [valueField]="'index'"
                (valueChange)="onTypeChanged($event)" [textField]="'omschrij'"></kendo-dropdownlist>
        </kendo-label>
        <div>
            <!-- <kendo-label text="Kanaal">

                <kendo-numerictextbox #kanaalTextBox [value]="halfValue(this.editValue.kanaal)" [min]="0"
                (valueChange)="onKanaalChange($event)" format="n0" [style]="{ width: '100px' }"></kendo-numerictextbox>
            </kendo-label>
        @if (this.editValue.range == 1) {
            <kendo-checkbox #isACheckbox [checkedState]="isA(this.editValue.kanaal)"
                (checkedStateChange)="onAKanaalChange($event)"></kendo-checkbox>
            <kendo-label [for]="isACheckbox" text="A Kanaal">
            </kendo-label>
        } -->
            <kanaal-input [initialValue]="{ ioType: this.kanaalType, range: this.editValue.range, start: this.editValue.kanaal}" (valueChanged)="onKanaalInputChanged($event)" ></kanaal-input>
        </div>
     
    </div>
    
} @else if (isNew()) {
    @if (this.aiOptions().length != 0) {
        <div class="row">
            <meta-select-button 
                [availableMetas]="this.aiOptions()"
                [alreadySelected]="this.alreadySelectedFN()"
                (onSelect)="onNew($event)"
                [labelCb]="this.labelFn()" />
        </div>                
    }
} @else {
<div class="row condensed parent-bck clickable" (click)="onEdit()">
    <div [title]="meta()?.index">{{meta()?.omschrij}} 
        @if (!this.onlyDesc()) {
            : {{kanaal()}} : {{typeDesc()}}

        }

    </div>
</div>
}
<kendo-dialog *ngIf="this.isEditing" title="Edit AI" width="300px" (close)="this.isEditing = false">
    <div>{{meta()?.omschrij}} : {{kanaal()}} : {{typeDesc()}}</div>
    <div class="row">
        <kendo-label text="Type">
            <kendo-dropdownlist [data]="this.jsBridgeService.getAITypes().findByGroup(meta()!.typeGroep)"
                [valuePrimitive]="true" [value]="this.editValue.typeIndex" [valueField]="'index'"
                (valueChange)="onTypeChanged($event)" [textField]="'omschrij'"></kendo-dropdownlist>
        </kendo-label>
    </div>
    <!-- <div class="row">
        <kendo-label text="Kanaal">

            <kendo-numerictextbox #kanaalTextBox [value]="halfValue(this.editValue.kanaal)" [min]="0"
                (valueChange)="onKanaalChange($event)" format="n0" [style]="{ width: '100px' }"></kendo-numerictextbox>
        </kendo-label>
        @if (this.editValue.range == 1) {
        <div>
            <kendo-checkbox #isACheckbox [checkedState]="isA(this.editValue.kanaal)"
                (checkedStateChange)="onAKanaalChange($event)"></kendo-checkbox>
            <kendo-label [for]="isACheckbox" text="A Kanaal">
            </kendo-label>
        </div>
        }
    </div> -->
    <kanaal-input [initialValue]="{ ioType: this.kanaalType, range: this.editValue.range, start: this.editValue.kanaal}" (valueChanged)="onKanaalInputChanged($event)" ></kanaal-input>
    <kendo-dialog-actions>
        <button kendoButton (click)="this.isEditing = false">Cancel</button>
        <button kendoButton (click)="onDelete()" themeColor="primary" *ngIf="!isNew()">
            Delete
        </button>
        <button kendoButton (click)="onSave()" themeColor="primary">
            Save
        </button>
    </kendo-dialog-actions>
</kendo-dialog>