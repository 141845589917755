import { Component, computed, input, output } from '@angular/core';
import { AfdModule, Validation } from '@etron/typings';
import { KopModulesService } from '../kop-modules.service';
import { KopConfigService } from '../../core/kop-config.service';
import { ValidationService } from '../validation.service';

@Component({
  selector: 'module-card',
  templateUrl: './module-card.component.html',
  styleUrl: './module-card.component.scss'
})
export class ModuleCardComponent {

  constructor(public kopModulesService: KopModulesService, public kopConfigService: KopConfigService, public validationService: ValidationService) { }

  public afdModule = input<AfdModule>();
  public selected = computed(() => {
    let selectedModule = this.kopModulesService.selectedAfdModule();
    if (selectedModule != undefined) {
      return selectedModule.meta.key == this.afdModule()?.meta.key;
    } else {
      return false;
    }
  });
  public removeDialogOpen = false;
  onCardClicked = output<AfdModule>();

  onClick() {
    let t = this.afdModule();
    if (t != undefined) {
      this.onCardClicked.emit(t);
    }
  }

  onEdit() {
    this.kopModulesService.isEditingModule.set(true);
  }

  onRemove() {
    let t = this.afdModule();
    if (t != undefined) {
      this.kopModulesService.deleteModule(t);
      this.removeDialogOpen = false;
    }
  }

  public validation = computed(() => {
    let module = this.afdModule();
    let afd = this.kopConfigService.selectedAfd();
    if ((module != undefined) && (afd != undefined)) {
      if (module.meta.validation != undefined) {
        let validation = this.validationService.validateRequiredProps(afd, module.meta.validation);
        return validation;
      }
    }

    return;
  });

}
