<div class="kanaal-wrapper">
    @let prs = this.presentation();
    @if (prs == "detail") {
         <detailed-kanaal-selector [kanaalType]="this.kanaalType()" (onKanaalSelected)="this.onSelectionChanged($event)"
         [initialSelection]="this.currentSelection()" [range]="this.range()"
         [assign]="this.assign()"></detailed-kanaal-selector>
 
    } @else if (prs == "compact") {
    <compact-kanaal-selector [kanaalType]="this.kanaalType()" (onKanaalSelected)="this.onSelectionChanged($event)"
        [initialSelection]="this.currentSelection()" [range]="this.range()"
        [assign]="this.assign()"></compact-kanaal-selector>


    } @else {
        <hdwv-kanaal-selector [kanaalType]="this.kanaalType()" (onKanaalSelected)="this.onSelectionChanged($event)"
        [initialSelection]="this.currentSelection()" [range]="this.range()"
        [assign]="this.assign()"></hdwv-kanaal-selector>

    }
    <div class="sidewrapper">
        <!-- <kendo-switch size="small" class="chooser" onLabel="Detailed" offLabel="Compact"
            [(ngModel)]="this.isDetail"></kendo-switch> -->
            <kendo-label text="Presentation">
                <kendo-dropdownlist [data]="this.presentations" [(value)]="this.presentation"></kendo-dropdownlist>
              </kendo-label>
        <div *ngIf="(prs != 'detail') && this.selection().kanaal != undefined" class="assignement-wrapper">
            <div>{{this.selectionLabel()}}</div>
            @for (assign of this.selection().assignement; track $index) {
            <div class="assignement">
                <div>{{assign.assignement.afdeling}} / {{assign.assignement.afdRefNr}}</div>
                <div>{{assign.assignement.omschrijving}}</div>
                <div>{{this.rangedKanaalLabel(assign.rangedKnaal)}}</div>
            </div>
            }
        </div>

    </div>
</div>