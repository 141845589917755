import { Component } from '@angular/core';

@Component({
  selector: 'app-browser-kop-wrapper',
  templateUrl: './browser-kop-wrapper.component.html',
  styles: ``
})
export class BrowserKopWrapperComponent {

}
