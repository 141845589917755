import { Component, computed, input } from '@angular/core';
import { IOType, RangedKanaal } from '../../../props/PropTypes';
import { IOProvider } from '../../hdw-config/HdwSysteem';
import { rangedKanaalLabel } from '../../kanaal.utils';

@Component({
  selector: 'ioview',
  templateUrl: './ioview.component.html',
  styleUrl: './ioview.component.scss'
})
export class IOViewComponent {

  public ios = input.required<RangedKanaal[]>()


  public rangedKanaalLabel(rangedKanaal: RangedKanaal): string {
    let longLabel =  rangedKanaalLabel(rangedKanaal);
    return longLabel.split(": ")[1];
  }

}
