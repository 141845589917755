<button kendoButton size="large" style="width: 100%;" (click)="this.isEditing = true">Alarmen</button>
<kendo-dialog *ngIf="isEditing" title="Alarmen" width="550px" (close)="this.isEditing = false">
    <div class="wrapper">
        @for (al of propsService.alarms(); track al.index) {
            <alarm-editor [alarm]="al" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" />
        }
        <div class="splitter"></div>
        @for (al of this.availableAlarms(); track al.index) {
            <alarm-editor [initialValue]="al" [class.led-even]="$even" [class.led-odd]="$odd" class="mouse-over" [onlyDesc]="true" />
        }
    </div>

</kendo-dialog>
