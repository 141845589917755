import { computed, Inject, Injectable, signal } from '@angular/core';
import { AfdelingValidation, AfdModuleMeta, AfdelingType, AfdSlotMeta, ModuleMetaFile } from '@etron/typings';
import { JSBridgeService } from '../core/jsbridge.service';
// import modulesMeta from './modules-meta.json';
// import cellTempMeta from "./cell-temp-meta.json";
import { eAfdelingType } from '@wasm/KopWeb';
import { ServerAPI } from '../serverapi';

@Injectable({
  providedIn: null
})
export class ModulesRepoService {

  constructor(public jsBridgeService: JSBridgeService,@Inject('ServerApi') private electronApi: ServerAPI) {

    this.repo = [];
    // modulesMeta as Array<AfdModuleMeta>;
    // this.repo = this.repo.concat(cellTempMeta as Array<AfdModuleMeta>);
  
    this.electronApi.getConfigs().then(configs => {
      let fileMeta = configs as ModuleMetaFile
      console.log(fileMeta);
      for (let s of fileMeta.slotsMeta!) {
        this.addSlot(s);
      }
      for (let c of fileMeta.modulesMeta!) {
        this.addModule(c);
      }
      for (let v of fileMeta.afdelingValidation!) {
        this.addAfdValidation(v);
      }
      this.loadDescriptionFiles();
    });

  }

  private repo: Array<AfdModuleMeta> = [];

  private descriptionFiles: Array<string> = [];


  private afdValidation: Array<AfdelingValidation> = [];
  private slots: Array<AfdSlotMeta> = [];
  public loaded = signal(false);

  updateSlot(module: AfdModuleMeta) {
    let newSlotKey: string | undefined = undefined;
    if (module.slot != undefined) {
      let slot = this.slots.find(slot => slot.key == module.slot);
      if (slot != undefined) {
        if (slot.modules.indexOf(module.key) < 0) {
          slot.modules.push(module.key);
        }
      } else {
        newSlotKey = module.slot;
      }
    } else {
      let slot = this.getSlotForModule(module);
      if (slot == undefined) {
        newSlotKey = module.key;
      }
    }

    if (newSlotKey != undefined) {
      this.addSlot({
        supportedAfdelingTypes: module.supportedAfdelingTypes,
        name: module.name,
        key: newSlotKey,
        modules: [module.key],
      });
    }

  }

  addModule(module: AfdModuleMeta) {
    this.repo.push(module);
    this.updateSlot(module);
    if (module.descriptionFile != undefined) {
      if (this.descriptionFiles.indexOf(module.descriptionFile) < 0) {
        this.descriptionFiles.push(module.descriptionFile);
      }
    }
  }

  private async loadDescriptionFiles() {
    while (this.descriptionFiles.length > 0) {
      let file = this.descriptionFiles.pop();
      if (file != undefined) {
        let asset = await this.electronApi.getAsset(file);
        if (asset != undefined) {
          let text = new TextDecoder("utf-8").decode(asset);
          this.parseDescription(text);
        }
      }
  
    }
    this.loaded.set(true);
  }

  private lineMatch = /<a\s+name="([^"]+)">/g;

  private updateUnitDescription(key: string, description: string) {
    let module = this.repo.find(module => module.key == key);
    if (module != undefined) {
      module.description = description;
    }
    let slot = this.slots.find(slot => slot.key == key);
    if (slot != undefined) {
      slot.description = description;
    }

  }

  private parseDescription(text: string) {

    let desc = '';
    let currentKey: string | undefined = undefined;
    for (const line of text.split(/[\r\n]+/)) {
      let match = this.lineMatch.exec(line);
      if (match != null) {
        if (currentKey != undefined) {
          this.updateUnitDescription(currentKey, desc);
        }
        currentKey = match[1];
        desc = '';
      }
      desc = desc + line + '\n';
    }
    if (currentKey != undefined) {
      this.updateUnitDescription(currentKey, desc);
    }
  }

  addAfdValidation(v: AfdelingValidation) {
    this.afdValidation.push(v);
  }

  addSlot(s: AfdSlotMeta) {
    this.slots.push(s);
    if (s.descriptionFile != undefined) {
      if (this.descriptionFiles.indexOf(s.descriptionFile) < 0) {
        this.descriptionFiles.push(s.descriptionFile);
      }
    }
  }

  getValidation(afdType: eAfdelingType): AfdelingValidation | undefined {
    let type = this.typeMap()[afdType.value];
    return this.afdValidation.find(v => v.afdelingType == type);
  }

  public typeMap = computed(() => {
    let wasmModule = this.jsBridgeService.wasmModule;
    if (wasmModule == undefined) return {};
    let t = Object.entries(wasmModule.eAfdelingType).map(([key, value]) => {
      return [value.value, key];
    });
    let map = Object.fromEntries(t);
    return map;
  });

  getModules(afdType: eAfdelingType): Array<AfdModuleMeta> {
    let key = this.typeMap()[afdType.value];
    return this.repo.filter(module => module.supportedAfdelingTypes.indexOf(key) >= 0);
  }

  getModulesByKey(keys: Array<string>): Array<AfdModuleMeta> {
    return this.repo.filter(module => keys.indexOf(module.key) >= 0);
  }

  getSlots(afdType: eAfdelingType): Array<AfdSlotMeta> {
    let key = this.typeMap()[afdType.value];
    return this.slots.filter(slot => slot.supportedAfdelingTypes.indexOf(key) >= 0);
  }

  getSlotForModule(module: AfdModuleMeta): AfdSlotMeta | undefined {
    return this.slots.find(slot => slot.modules.indexOf(module.key) >= 0);
  }

  getModule(key: string): AfdModuleMeta | undefined {
    return this.repo.find(module => module.key == key);
  }

  getDescription(module: AfdModuleMeta) {
    if (module.description) return module.description;
    return "Geen beschrijving";
  }
}
