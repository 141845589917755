<button kendoButton (click)="this.isEditing = true">Hdw Konfig</button>
@if (this.isEditing) {
<kendo-dialog title="Hardware Konfigurator" width="1500px" (close)="this.isEditing = false">
    @let _system = this.hdwMetaService.system();
    @if (_system == undefined) {
    <kendo-dropdownbutton [data]="this.hdwMetaService.meta().mainBoards" [textField]="'label'"
        (itemClick)="this.onMainboard($event)">
        Mainboard
    </kendo-dropdownbutton>
    } @else {
    <div class="system">
        <div>
            <button kendoButton (click)="this.onMainboard(undefined)">Reset</button>
        </div>

        <div class="mainboard">
            <div>Mainboard: {{_system.mainboard.label}}</div>
            <div>
                <ioview [ios]="_system.getIOs()"></ioview>
            </div>
        </div>
        @let busTypes = _system.getBusTypes();
        @for (busType of busTypes; track busType) {
            @let racks = _system.getRacks(busType);
            @for (rack of racks; track $index) {
            <rack-view [rack]="rack"></rack-view>
            }
        }
        <div>

            <button kendoButton (click)="this.addRack()">Add Rack</button>
        </div>
    </div>
    }

    <kendo-dialog-actions>
        <button kendoButton (click)="this.isEditing = false">Close</button>
    </kendo-dialog-actions>
</kendo-dialog>
}