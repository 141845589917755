import { AnalogIn, AnalogOut, DigitalIn, DigitalOut, IOType } from "../../props/PropTypes";
import { BusManagerBase } from "./BusManagerBase";
import { HdwRackType, isSimpleKaart, KaartType } from "./hdw-konfig-types";
import { BusManager, HwdSysteem, Kaart } from "./HdwSysteem";

export class SCXBusManager extends BusManagerBase implements BusManager{
    constructor(systeem: HwdSysteem) {
        super(systeem, "scx");
    }
    deleteKaart(id: number): void {
        super.deleteKaart(id);
        this.assignOffsets();
    }
    
    addRack(rackType: HdwRackType): number {
        let id = super.addRack(rackType);
        this.assignOffsets();
        return id;
    }

    private getMaxDipSwitch(ioType: IOType) {
        let racks = this.systeem.getRacks("scx");
        let max = 0;
        for (let rack of racks) {
            for (let kaart of rack.kaarten) {
                if (isSimpleKaart(kaart.type)) {
                    if (new IOType(kaart.type.ioType).equals(ioType) && kaart.dipSwitch > max) {
                        max = kaart.dipSwitch;
                    }
                }
            }
        }
        return max;

    }
    addKaart(kaartType: KaartType, number: number, rackId: number): number {
        let rack = this.getRack(rackId);
        if (rack != undefined) {
            let id = this.systeem.getNextKaartId();
            let kaart;
            if (isSimpleKaart(kaartType)) {
                let dipSwitch = this.getMaxDipSwitch(new IOType(kaartType.ioType)) + 1;
                kaart = new Kaart(kaartType, number, id, dipSwitch);
                
            } else {
                kaart = new Kaart(kaartType, number, id);
            }
            rack.addKaart(kaart);
            this.assignOffsets();
            return id;
        }
        return -1;
    }

    public assignOffsets() {
        let racks = this.systeem.getRacks("scx");
        for (let rack of racks) {
            for (let kaart of rack.kaarten) {
                if (isSimpleKaart(kaart.type)) {
                    kaart.setOffset(kaart.type.available * (kaart.dipSwitch - 1), new IOType(kaart.type.ioType));
                }
            }
        }
    }
}