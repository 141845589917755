<kendo-breadcrumb [items]="this.breadCrumb()" (itemClick)="this.onBreadCrumbClick($event)"></kendo-breadcrumb>

@if (kopConfigService.isDetail()) {
<div class="module-container" dropzone="true" (dragleave)="isDropValid = false" (drop)="onModuleDrop($event)"
    (dragover)="onDragOver($event)" [class.dropzone]="isDropValid">
    @for (module of this.kopModulesService.afdModules(); track module.meta.key) {
    <module-card [afdModule]="module" (onCardClicked)="onModuleClicked($event)"></module-card>
    }

</div>
<module-props [isEditing]="this.kopModulesService.isEditingModule()" [moduleKey]="this.moduleKey"
    (cancel)="onModuleEditCancel()" (apply)="onModuleEditApply($event)" />
} @else {
<div class="list-container">
    @for (afd of kopConfigService.afdelings(); track afd.afdeling().refNr) {
    <afd-card [afd]="afd" [selected]="afd.afdeling().refNr == kopConfigService.selectedAfd()?.afdeling()?.refNr"
        (onCardClicked)="onCardClicked($event)"></afd-card>
    }
    <div class="new-container">
        <kendo-dropdownbutton [data]="allAfd()" textField="naam" (itemClick)="onNew($event)" [themeColor]="'primary'">New</kendo-dropdownbutton>
    </div>
</div>

}