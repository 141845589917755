import { Component, Inject, input, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServerAPI } from '../../serverapi';
import { IOType, RangedKanaalAssign } from '../../props/PropTypes';


@Component({
  selector: 'app-kanaal-wrapper',
  templateUrl: './kanaal-wrapper.component.html',
  styles: ``
})
export class KanaalWrapperComponent {
  constructor(@Inject('ServerApi') public electronAPI: ServerAPI,  private route:ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.klantNo = params['klantNo'];
    });
    this.refresh();
    window.addEventListener("storage", () => {
      // When local storage changes, dump the list to
      // the console.
      this.refresh();
    });
  }

  private refresh() {
    let assignStr = window.localStorage.getItem("kanaalAssign");
    if (assignStr != null) {
      let assign: RangedKanaalAssign[] = JSON.parse(assignStr);
      for (let a of assign) {
        a.rangedKnaal.ioType = new IOType(a.rangedKnaal.ioType);
      }
      this.assign.set(assign);
    }
  }

  public klantNo = "ZZ0000";

  public assign =  signal<RangedKanaalAssign[]>([]);

}
