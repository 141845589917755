import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { Alarm, analogeMeting, cAfdelingInfo, cAfdelingWM, cKC_Afdeling, cKC_Alarm, cKC_Analog, cKC_BijzPrg, cKC_LosDef, cKC_LosDefs, cKC_Params, cKC_Servo, cKopConfig, cKopConfigModules, eKanaalType, JSBridge, KCParams, Losdef, sBijzPrg, Servo } from '@wasm/KopWeb';
import { JSBridgeService } from './jsbridge.service';
import { wasmMapToRecord, wasmVectorToArray } from '../utils/wasmVector';
import { AnalogIn, AnalogOut, DigitalIn, DigitalOut, IOBaseType, IODirection, IOType, LosDefType, ModBusOut, ModuleAI, ModuleLosdef, ModuleServo, ModuleParam, ModuleBijzPrg } from '../props/PropTypes';
import { KanaalService } from '../kanaal/kanaal.service';
import { MetaService } from './meta.service';

@Injectable({
  providedIn: null
})
export class KopConfigService {

  constructor(private jsBridgeService: JSBridgeService, private kanaalService: KanaalService, private metaService: MetaService) {
    jsBridgeService.getBridge().subscribe(bridge => {
      this.jsBridge = bridge;
      let cfg = this.jsBridge.getModuleConfig();
      this.config = cfg;
      let afd = wasmVectorToArray(cfg.afdelings);
      this.afdelings.set(afd);
    });

    effect(async () => {
      this.selectedAfd();
      let bridge = this.jsBridgeService.getBridgeSync();
      if (bridge != undefined) {
        setTimeout(() => {
          let kfg = this.jsBridgeService.getBridgeSync().getModuleConfig();
          let assignement = this.kanaalService.getKonfigKanalen(kfg);
          localStorage.setItem('kanaalAssign', JSON.stringify(assignement));

        }, 10);
      }
    })
  }

  private jsBridge: JSBridge | undefined;

  private config: cKopConfigModules | undefined;

  public afdelings = signal<Array<cAfdelingWM>>([], { equal: (a, b) => false });
  public selectedAfd = signal<cAfdelingWM | undefined>(undefined, { equal: (a, b) => false });
  public isDetail = signal<boolean>(false);

  public currentMeta = computed(() => {
    let afd = this.selectedAfd();
    return this.metaService.afdelingMeta(afd);
  });



  addAfd(afd: cAfdelingInfo) {

    this.config?.addAfd(afd.type);
    this.afdelings.update(afds => {
      if (this.config != undefined) {
        let afd = wasmVectorToArray(this.config.afdelings);
        return afd;
      }
      return afds;
    });
  }

  removeAfd(afd: cAfdelingWM) {
    this.config?.removeAfd(afd.afdeling().refNr);
    this.afdelings.update(afds => {
      if (this.config != undefined) {
        let afd = wasmVectorToArray(this.config.afdelings);
        return afd;
      }
      return afds;
    });
    this.selectedAfd.set(undefined);
  }

  save() {
    let p = this.jsBridgeService.save();
    return p;
  }

  getAfdLosDefVector(afd: cAfdelingWM, ldType: LosDefType = 'losdef'): cKC_LosDefs | undefined {
    let ldsW: cKC_LosDefs | undefined = undefined;
    switch (ldType) {
      case 'losdef':
        ldsW = afd.afdeling().losDefs;
        break;
      case 'droogwand':
        ldsW = afd.afdeling().droogWand;
        break;
      case 'koeling':
        ldsW = afd.afdeling().koeling;
        break;
      case 'verwarming':
        ldsW = afd.afdeling().verwarming;
        break;
    }

    return ldsW;

  }


  findKCLosDef(meta: Losdef | number, ldType: LosDefType = 'losdef'): cKC_LosDef | undefined {
    if (typeof meta == 'number') {
      meta = this.currentMeta().losDefRaw.find(s => s.index == meta)!;
    }
    let afd = this.selectedAfd()!;
    let ld = undefined;
    let ldsW = this.getAfdLosDefVector(afd, ldType);
    if (ldsW != undefined) {
      let lds = wasmVectorToArray(ldsW);
      let idx = lds.findIndex(ld => ld.index == meta.index);
      if (idx >= 0) {
        ld = ldsW.at(idx);
      }
    }

    return ld;

  }

  findLDRange(meta: Losdef, ldType: LosDefType = 'losdef'): number {
    if (meta.koppelindx > 0) {
      let ld = this.findKCLosDef(meta.koppelindx, ldType);
      if (ld != undefined) {
        return ld.waarde;
      }
    }
    return meta.range;
  }
  findOrBuildLosDef(meta: Losdef | number, ldType: LosDefType = 'losdef'): ModuleLosdef {
    if (typeof meta == 'number') {
      meta = this.currentMeta().losDefRaw.find(s => s.index == meta)!;
    }

    let ld = this.findKCLosDef(meta, ldType);
    if (ld == undefined) {
      return {
        index: meta.index,
        type: meta.type,
        waarde: undefined,
        inverted: false,
        range: this.findLDRange(meta, ldType)
      }
    }
    let type = this.kanaalService.getIOTypeFromParamType(ld.type);
    let inverted = false;
    if (type != undefined) {
      inverted = this.kanaalService.getInvTab(ld.waarde, type);
    }
    return {
      index: ld.index,
      type: ld.type,
      waarde: ld.waarde,
      inverted: inverted,
      range: ld.range
    }
  }

  eraseAI(afd: cAfdelingWM | undefined, aim: ModuleAI) {
    if (afd != undefined) {
      let aiW = afd.afdeling().analogs;
      let old = wasmVectorToArray(aiW);
      aiW.clear();
      for (let ai of old) {
        if (ai.index != aim.index) {
          aiW.push_back(ai);
        }
      }
    }
  }


  eraseServo(afd: cAfdelingWM | undefined, servo: ModuleServo) {
    if (afd != undefined) {
      let servoW = afd.afdeling().servos;
      let old = wasmVectorToArray(servoW);
      servoW.clear();
      for (let s of old) {
        if (s.index != servo.index) {
          servoW.push_back(s);
        }
      }
    }
  }

  eraseLosDef(afd: cAfdelingWM | undefined, ldm: ModuleLosdef, ldType: LosDefType = 'losdef') {
    if (afd != undefined) {
      let ldsW = this.getAfdLosDefVector(afd, ldType);
      if (ldsW != undefined) {
        let old = wasmVectorToArray(ldsW);
        ldsW.clear();
        for (let ld of old) {
          if (ld.index != ldm.index) {
            ldsW.push_back(ld);
          }
        }
      }
    }
  }

  upsertLosDef(ldm: ModuleLosdef, ldType: LosDefType = 'losdef') {
    this.selectedAfd.update(afd => {

      if (ldm.waarde == undefined || ldm.waarde == 0) {
        this.eraseLosDef(afd, ldm, ldType);
        return afd;
      }
      let ld = this.findKCLosDef(ldm.index, ldType);
      let editVal = ldm;
      let toInsert = false;
      if (ld == undefined) {
        let wasm = this.jsBridgeService.wasmModule;
        if (wasm != undefined) {
          ld = new wasm.cKC_LosDef();
          ld.index = editVal.index;
          ld.type = editVal.type;
          toInsert = true;
        }
      }
      if (ld != undefined) {
        if (editVal.waarde != undefined) {
          ld.waarde = editVal.waarde;
          if (editVal.range != undefined) {
            ld.range = editVal.range;
          }
          let kanaalType = this.kanaalService.getIOTypeFromParamType(ld.type);
          if (kanaalType != undefined) {
            this.kanaalService.setInvTab(editVal.waarde, kanaalType, editVal.inverted == true);
          }
          //Range LosDef
          if (ld.type & 0x40) {
            let koppelMetas = this.currentMeta().losDefRaw.filter(s => s.koppelindx == ld.index)!;
            for (let kp of koppelMetas) {
              let ld = this.findKCLosDef(kp.index, ldType);
              if (ld != undefined) {
                ld.range = editVal.waarde;
              }
            }
          }
        }
      }

      if (toInsert && ld != undefined) {
        switch (ldType) {
          case 'losdef':
            afd?.afdeling().losDefs.push_back(ld);
            break;
          case 'droogwand':
            afd?.afdeling().droogWand.push_back(ld);
            break;
          case 'koeling':
            afd?.afdeling().koeling.push_back(ld);
            break;
          case 'verwarming':
            afd?.afdeling().verwarming.push_back(ld);
            break;
        }

      }
      return afd;
    });
  }
  aiKCtoModule(meta: analogeMeting | number, ai: cKC_Analog | undefined): ModuleAI {
    if (typeof meta == 'number') {
      meta = this.currentMeta().ais.find(s => s.index == meta)!;
    }
    if (ai == undefined) {
      let groups = this.jsBridgeService.getAITypes().findByGroup(meta.typeGroep);
      let typeIndex = 0;
      if (groups != undefined && groups.length > 0) {
        typeIndex = groups[0].index;
      } else {
        console.log('AI met meta niet gevonden', meta);
      }
      let ret = {
        index: meta.index,
        kanaal: 0,
        range: meta.kanalen,
        typeIndex: typeIndex
      }
      return ret;

    }
    return {
      index: ai.index,
      kanaal: ai.kanaal,
      range: ai.range,
      typeIndex: ai.typeIndex
    };
  }

  kcParamsoModule(params: cKC_Params): Record<number, ModuleParam> {
    var paramKeys = wasmVectorToArray(params.keys());
    var moduleParams: Record<number, ModuleParam> = [];
    for (var pNo of paramKeys) {
      var param = params.get(pNo);
      if (param != undefined) {
        let rk = this.kanaalService.getKCParamType({
          waarde: param.waarde,
          range: param.range,
          type: param.type
        });
        let inverted = false;
        if (rk != undefined) {
          inverted = this.kanaalService.getInvTab(rk.start, rk.ioType);
        }
        moduleParams[pNo] = {
          waarde: param.waarde,
          range: param.range,
          type: param.type,
          inverted
        };
      }
    }

    return moduleParams;
  }

  number2timerange(tijd: number) {
    let h = Math.floor(tijd / 60);
    let m = tijd % 60;
    return '' + h.toString().padStart(2, '0') + m.toString().padStart(2, '0');

  }

  timerange2number(tijd: string) {
    console.log(tijd);
    let pt = tijd.padStart(4, '0');
    let h = parseInt(pt.substring(0, 2));
    let m = parseInt(pt.substring(2, 4));
    return h * 60 + m;
  }
  servoKCtoModule(meta: Servo | number, servo: cKC_Servo | undefined): ModuleServo {
    if (servo == undefined) {
      return this.buildDefaultServo(meta);
    }

    var servoParams = this.kcParamsoModule(servo.params);

    let kanaalType = this.kanaalService.getServoKanaalType({ soort: servo.soort.value, uitgang: servo.uitgang });
    let inverted = false;
    if (kanaalType != undefined) {
      inverted = this.kanaalService.getInvTab(servo.uitgang, kanaalType.ioType);
    }
    let ret: ModuleServo = {
      index: servo.index,
      looptijd: this.number2timerange(servo.looptijd),
      modbusNetworkId: servo.modbusNetworkId,
      uitgang: servo.uitgang,
      soort: servo.soort.value,
      stationnr: servo.stationnr,
      inverted: inverted,
      params: servoParams
    };

    return ret;

  }

  bijzPrgmKCtoModule(meta: sBijzPrg | number, bijz: cKC_BijzPrg | undefined): ModuleBijzPrg {
    if (bijz == undefined) {
      return this.buildDefaultBijzPrgm(meta);
    }
    var bijzParams = this.kcParamsoModule(bijz.params);

    let ret = {
      index: bijz.index,
      params: bijzParams
    }

    return ret;
  }

  buildDefaultBijzPrgm(meta: sBijzPrg | number): ModuleBijzPrg {
    if (typeof meta == 'number') {
      meta = this.currentMeta().bijzPrgs.find(s => s.index == meta)!;
    }
    let params = this.buildDefaultCKParams(meta.param);
    let ret = {
      index: meta.index,
      params: params
    };

    return ret;

  }

  buildDefaultCKParams(metaParams: KCParams): Record<number, ModuleParam> {
    let params = Object.fromEntries(
      Object.entries(wasmVectorToArray(metaParams)).map(([k, v]) => {
        let msp: ModuleParam = {
          inverted: false,
          range: v.range,
          type: v.type,
          waarde: 0
        };
        return [k, msp];
      })
    );
    return params;
  }

  buildDefaultServo(meta: Servo | number): ModuleServo {
    if (typeof meta == 'number') {
      meta = this.currentMeta().servos.find(s => s.index == meta)!;
    }
    let params = this.buildDefaultCKParams(meta.param);
    let ret = {
      index: meta.index,
      uitgang: 0,
      inverted: false,
      looptijd: '00:00',
      modbusNetworkId: 0,
      stationnr: 0,
      soort: 0,
      params: params
    };

    return ret;

  }


  upsertAI(aim: ModuleAI) {
    let editValue = aim;
    this.selectedAfd.update((afd) => {
      if (afd != undefined) {
        var ais = wasmVectorToArray(afd.afdeling().analogs);
        let aiIdx = ais.findIndex(s => s.index == aim.index);
        let wasm = this.jsBridgeService.wasmModule!;
        let ai: cKC_Analog | undefined = undefined;
        if (aiIdx < 0) {
          ai = new wasm.cKC_Analog();
          ai.index = editValue.index;
        } else {
          ai = afd.afdeling().analogs.at(aiIdx);
        }
        ai.kanaal = editValue.kanaal;
        ai.typeIndex = editValue.typeIndex;
        ai.range = editValue.range;
        if (aiIdx < 0) {
          afd.afdeling().analogs.push_back(ai);
        }
      }
      return afd;
    });

  }

  upsertKCParams(ckPrams: cKC_Params, moduleParams: Record<number, ModuleParam>) {
    var paramKeys = wasmVectorToArray(ckPrams.keys());
    for (var pNo of paramKeys) {
      var kcParam = ckPrams.at(pNo);
      var newParam = moduleParams[pNo];
      if ((kcParam != undefined) && (newParam != undefined)) {
        kcParam.waarde = newParam.waarde;
        let kanaalType = this.kanaalService.getKCParamType(newParam);
        if (kanaalType != undefined) {
          this.kanaalService.setInvTab(newParam.waarde, kanaalType.ioType, newParam.inverted);
        }
      }
    }

  }

  buildKCParams(metaParam: KCParams): cKC_Params {
    let wasm = this.jsBridgeService.wasmModule!;
    let ret = new wasm.cKC_Params();
    for (let i = 0; i < metaParam.size(); i++) {
      let mp = metaParam.get(i)!;
      let key = mp.no;
      let p = new wasm.cKC_Param();
      p.range = mp.range;
      p.type = mp.type;
      // p.waarde  = 0;
      ret.set(key, p);
    }

    return ret;

  }
  upsertBijzPrgm(bijz: ModuleBijzPrg, meta: sBijzPrg) {
    let editValue = bijz;
    this.selectedAfd.update((afd) => {
      if (afd != undefined) {
        let wasm = this.jsBridgeService.wasmModule!;
        var bijzPrgs = wasmVectorToArray(afd.afdeling().bijzPrgs);
        let bpIdx = bijzPrgs.findIndex(s => s.index == editValue.index);
        let bp: cKC_BijzPrg | undefined = undefined;
        if (bpIdx < 0) {
          bp = new wasm.cKC_BijzPrg();
          bp.index = editValue.index;
          bp.params = this.buildKCParams(meta.param);
          afd.afdeling().bijzPrgs.push_back(bp);
          bpIdx = afd.afdeling().bijzPrgs.size() - 1;
        }
        bp = afd.afdeling().bijzPrgs.at(bpIdx);
        if (bp != undefined) {
          this.upsertKCParams(bp.params, editValue.params);
        }
      }
      return afd;
    });
  }
  upsertServo(sm: ModuleServo, meta: Servo) {
    let editValue = sm;
    this.selectedAfd.update((afd) => {
      if (afd != undefined) {
        var servos = wasmVectorToArray(afd.afdeling().servos);
        let servoIdx = servos.findIndex(s => s.index == editValue.index);
        let servo: cKC_Servo | undefined = undefined;
        let wasm = this.jsBridgeService.wasmModule!;
        let soortMap = Object.fromEntries(Object.entries(wasm.eServoSoort).map((v) => {
          return [v[1].value, v[1]];
        }));
        if (servoIdx < 0) {
          servo = new wasm.cKC_Servo();
          servo.index = editValue.index;
          servo.params = this.buildKCParams(meta.param);

        } else {
          servo = afd.afdeling().servos.at(servoIdx);
        }
        if (servo != undefined) {
          servo.uitgang = editValue.uitgang;
          servo.stationnr = editValue.stationnr;
          servo.modbusNetworkId = editValue.modbusNetworkId;
          servo.soort = soortMap[editValue.soort];
          servo.looptijd = this.timerange2number(editValue.looptijd);
          let kanaalType = this.kanaalService.getServoKanaalType(editValue);
          if (kanaalType != undefined) {
            this.kanaalService.setInvTab(editValue.uitgang, kanaalType.ioType,
              editValue.inverted);
          }
          this.upsertKCParams(servo.params, editValue.params);
        }
        if (servoIdx < 0 && servo != undefined) {
          afd.afdeling().servos.push_back(servo);
        }

      }
      return afd;
    });

  }

  getTextForAlarm(meta: Alarm | undefined) {
    if (meta == undefined) return "";
    let kopConfig = this.jsBridgeService.kopConfig();
    if (kopConfig != undefined) {
      let globalAlarmTeksten = wasmMapToRecord<string>(kopConfig.gAlarmTeksten, (e: any) => e.toString());
      let afdTeksten = wasmMapToRecord(this.selectedAfd()?.afdeling()?.alarmTexts, (e: any) => e.toString());
      Object.entries(afdTeksten).forEach(([key, val]) => {
        globalAlarmTeksten[parseInt(key) + 20] = val;
      });
      let vrijText = globalAlarmTeksten[meta.vrijetext - 1] ?? '';
      return `${vrijText} - V(${meta.vrijetext})`;
    }
    return "";
  }


  getAlarmsMeta() {
    let afd = this.selectedAfd()?.afdeling()!
    let jsBridge = this.jsBridgeService.getBridgeSync();
    let alarmsMeta = wasmVectorToArray(jsBridge.getAlarms(afd.type.value))!;
    return alarmsMeta;
  }

  getAlarmMeta(a: { index: number } | undefined) {
    if (a == undefined) return undefined;
    let meta = this.getAlarmsMeta().find(al => al.index == a.index);
    return meta;

  }

  kcAlarm2Model(alarm: cKC_Alarm) {
    let inv = this.kanaalService.getInvTab(alarm.kanaal, DigitalIn);
    let ret = {
      index: alarm.index,
      kanaal: alarm.kanaal,
      typeIndex: alarm.typeIndex,
      inverted: inv
    }
    return ret;
  }
}
