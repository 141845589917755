import { Component, signal } from '@angular/core';
import { HwdSysteem } from '../../hdw-config/HdwSysteem';
import { HwdConfigService } from '../../hwd-config.service';
import { MainboardType } from '../../hdw-config/hdw-konfig-types';

@Component({
  selector: 'system-def',
  templateUrl: './system-def.component.html',
  styleUrl: './system-def.component.scss'
})
export class SystemDefComponent {

  constructor(public hdwMetaService: HwdConfigService) { }

  public isEditing = false;

  public addRack() {
    this.hdwMetaService.system.update(system => {
      if (system != undefined) {
        let meta = this.hdwMetaService.meta();
        let rack = meta.getRackByBus(system.getBusTypes()[0])[0];
        system.addRack(rack);
      }
      return system;
    });
  }

  public onMainboard(e: MainboardType | undefined) {
    if (e == undefined) {
      this.hdwMetaService.system.set(undefined);
      return;
    }
    this.hdwMetaService.system.set(new HwdSysteem(e));
  }


  public test() {
    console.log("test");
  }
}
