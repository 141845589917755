<div class="rack-label">{{this.rack().type.label}} {{this.rack().id}}</div>
@let karten = this.slotKaarten();
<div class="karten">
    @for (k of karten; track $index) {
    <kart-view [kaartId]="k.id"></kart-view>
    }
    <div>
        @let nextNummer = this.rack().getNextNumber();
        @if (nextNummer >= 0) {
        @let kartTypes = this.hdwMetaService.meta().getKaartenByBus(this.rack().type.bus);
        <div>
            <kendo-dropdownbutton [data]="kartTypes" [textField]="'label'" [themeColor]="'tertiary'"
                (itemClick)="this.onKaartAdded($event)">
                Kaart {{nextNummer}}
            </kendo-dropdownbutton>
        </div>
        }
    </div>
</div>